/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends " $fragmentName" | "__typename"
              ? T[P]
              : never;
      };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    /**
     * The `Date` scalar type represents a Date
     * value as specified by
     * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
     */
    Date: { input: string; output: string };
    /**
     * The `DateTime` scalar type represents a DateTime
     * value as specified by
     * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
     */
    DateTime: { input: string; output: string };
    EmailAddress: { input: string; output: string };
    /**
     * Allows use of a JSON String for input / output from the GraphQL schema.
     *
     * Use of this type is *not recommended* as you lose the benefits of having a defined, static
     * schema (one of the key benefits of GraphQL).
     */
    JSONString: { input: string; output: string };
    PhoneNumber: { input: string; output: string };
    /**
     * The `Time` scalar type represents a Time value as
     * specified by
     * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
     */
    Time: { input: string; output: string };
};

export type AcceptedDocument = {
    invoiceCode: Scalars["String"]["output"];
    uid: Scalars["ID"]["output"];
};

export type AccessError = {
    message: Scalars["String"]["output"];
};

export type ActivateLightEventsRequest = {
    groupIds: Array<Scalars["ID"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type ActivateLightEventsResponse =
    | AccessError
    | LightEventsActivated
    | NotFoundError;

export type ActivatePromoCodeRequest = {
    code: Scalars["ID"]["input"];
    endDt: Scalars["DateTime"]["input"];
    startDt: Scalars["DateTime"]["input"];
};

export type ActivatePromoCodeResponse = NotFoundError | PromoCodeActivated;

export type AddAssociatedUserRequest = {
    invoiceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
    userId: Scalars["ID"]["input"];
};

export type AddAssociatedUserResponse =
    | AccessError
    | AssociatedUserAdded
    | NotFoundError;

export type AddBookingGuestRequest = {
    bookingId: Scalars["ID"]["input"];
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    guestName: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    tenantId: Scalars["ID"]["input"];
    userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AddBookingGuestResponse =
    | AccessError
    | BookingGuestAdded
    | NotFoundError;

export type AddBookingGuestToBookingsRequest = {
    bookingIds: Array<Scalars["ID"]["input"]>;
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    guestName: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    tenantId: Scalars["ID"]["input"];
    userId: Scalars["ID"]["input"];
};

export type AddBookingGuestToBookingsResponse =
    | AccessError
    | BookingGuestAddedToBookings
    | NotFoundError;

export type AddMemberToOrganisationRequest = {
    capabilities: Array<Scalars["String"]["input"]>;
    memberEmail: Scalars["EmailAddress"]["input"];
    metadata: Scalars["JSONString"]["input"];
    organisationId: Scalars["ID"]["input"];
    role?: InputMaybe<MemberRole>;
};

export type AddMemberToOrganisationResponse =
    | AccessError
    | MemberAlreadyAddedError
    | NotFoundError
    | OrganisationMemberAdded;

export type AddMemberToOrganisationWithoutFirebaseRequest = {
    capabilities: Array<Scalars["String"]["input"]>;
    memberName: Scalars["String"]["input"];
    memberUserId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
    organisationId: Scalars["ID"]["input"];
};

export type AddMemberToOrganisationWithoutFirebaseResponse =
    | AccessError
    | MemberAlreadyAddedError
    | NotFoundError
    | OrganisationMemberAdded;

export type AddOn = {
    created: Scalars["DateTime"]["output"];
    isInStock: Scalars["Boolean"]["output"];
    maxQuantity?: Maybe<Scalars["Int"]["output"]>;
    name: Scalars["String"]["output"];
    price: Scalars["Int"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type AddOnAlreadyArchived = {
    message: Scalars["String"]["output"];
};

export type AddOnArchived = {
    addOnId: Scalars["ID"]["output"];
};

export type AddOnCreated = {
    addOnId: Scalars["ID"]["output"];
    maxQuantity?: Maybe<Scalars["Int"]["output"]>;
    name: Scalars["String"]["output"];
    price: Scalars["Int"]["output"];
};

export type AddOnIsInStockSet = {
    addOnId: Scalars["ID"]["output"];
    isInStock: Scalars["Boolean"]["output"];
};

export type AddOnModified = {
    addOnId: Scalars["ID"]["output"];
    maxQuantity?: Maybe<Scalars["Int"]["output"]>;
    name: Scalars["String"]["output"];
    price: Scalars["Int"]["output"];
};

export type AddPaymentMethodRequest = {
    availability: Scalars["Boolean"]["input"];
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type AddPaymentMethodResponse = AccessError | PaymentMethodAdded;

export type AddServiceResourceRequest = {
    optionKey?: InputMaybe<Scalars["String"]["input"]>;
    resourceId: Scalars["ID"]["input"];
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type AddServiceResourceResponse =
    | AccessError
    | NotFoundError
    | ServiceResourceAdded
    | UnableToAddServiceResource;

export type Address = {
    addressLine1: Scalars["String"]["input"];
    addressLine2?: InputMaybe<Scalars["String"]["input"]>;
    addressLine3?: InputMaybe<Scalars["String"]["input"]>;
    city: Scalars["String"]["input"];
    postcode: Scalars["String"]["input"];
    state: Scalars["String"]["input"];
};

export type AllowDatesRule = Rule & {
    created: Scalars["DateTime"]["output"];
    dates: Array<Scalars["Date"]["output"]>;
    uid: Scalars["ID"]["output"];
};

export type AllowFromDatetimeRule = Rule & {
    created: Scalars["DateTime"]["output"];
    effectiveFrom: Scalars["DateTime"]["output"];
    uid: Scalars["ID"]["output"];
};

export type AllowMaximumAdvancedBookingTimeRule = Rule & {
    created: Scalars["DateTime"]["output"];
    maxAdvancedBookingDays: Scalars["Int"]["output"];
    uid: Scalars["ID"]["output"];
};

export type AllowMinimumAdvancedBookingTimeRule = Rule & {
    created: Scalars["DateTime"]["output"];
    minAdvancedBookingMinutes: Scalars["Int"]["output"];
    uid: Scalars["ID"]["output"];
};

export type AllowToDatetimeRule = Rule & {
    created: Scalars["DateTime"]["output"];
    effectiveTo: Scalars["DateTime"]["output"];
    uid: Scalars["ID"]["output"];
};

/** An enumeration. */
export type Amenities =
    | "AED"
    | "AIRCON"
    | "CARPARK"
    | "CARWASH"
    | "CHANGINGROOM"
    | "DRINKS"
    | "EVCHARGER"
    | "FOOD"
    | "HOSTEL"
    | "NONSMOKING"
    | "PERFORMANCETRAININGCENTRE"
    | "PETFRIENDLY"
    | "PRIVATEBATHROOM"
    | "REHABILITATIONCENTRE"
    | "SHOP"
    | "SHOWER"
    | "SURAU"
    | "TOILETRIES"
    | "TOILETS"
    | "TV"
    | "TWOSINGLEBEDS"
    | "WIFI";

export type ApproveOrganisationRequest = {
    organisationId: Scalars["ID"]["input"];
};

export type ApproveOrganisationResponse =
    | AccessError
    | NotFoundError
    | OrganisationApproved;

export type ApprovedOrganisationsFilter = {
    byCourtsitePartner?: InputMaybe<Scalars["Boolean"]["input"]>;
    byLocations?: InputMaybe<Array<LocationFilter>>;
    byMaxDistance?: InputMaybe<Scalars["Int"]["input"]>;
    byServices?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type ArchiveAddOnRequest = {
    addOnId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ArchiveAddOnResponse =
    | AccessError
    | AddOnAlreadyArchived
    | AddOnArchived
    | NotFoundError;

export type ArchiveCustomerRequest = {
    customerId?: InputMaybe<Scalars["ID"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type ArchiveCustomerResponse =
    | AccessError
    | CustomerAlreadyArchived
    | CustomerArchived
    | NotFoundError;

export type ArchiveEventsRequest = {
    groupId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ArchiveEventsResponse = AccessError | EventsArchived;

export type ArchiveGameRequest = {
    uid: Scalars["ID"]["input"];
};

export type ArchiveGameResponse = AccessError | GameArchived | NotFoundError;

export type ArchiveLocationRequest = {
    locationId: Scalars["ID"]["input"];
};

export type ArchiveLocationResponse =
    | AccessError
    | LocationArchived
    | NotFoundError;

export type ArchiveMemberRequest = {
    memberId: Scalars["ID"]["input"];
    organisationId: Scalars["ID"]["input"];
};

export type ArchiveMemberResponse =
    | AccessError
    | CannotModifySelfError
    | MemberAlreadyArchivedError
    | MemberArchived
    | NotFoundError;

export type ArchivePaymentMethodRequest = {
    paymentMethodId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ArchivePaymentMethodResponse =
    | AccessError
    | NotFoundError
    | PaymentMethodAlreadyArchived
    | PaymentMethodArchived;

export type ArchivePromoCodeRequestV2 = {
    promoCodeId: Scalars["String"]["input"];
    tenantId: Scalars["String"]["input"];
};

export type ArchivePromoCodeResponseV2 =
    | AccessError
    | NotFoundError
    | PromoCodeArchivedV2;

export type ArchiveResourceLinksRequest = {
    resourceLinkIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
    tenantId: Scalars["ID"]["input"];
};

export type ArchiveResourceLinksResponse =
    | AccessError
    | NotFoundError
    | ResourceLinksAlreadyArchived
    | ResourceLinksArchived;

export type ArchiveResourceRequest = {
    resourceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ArchiveResourceResponse =
    | AccessError
    | ResourceArchived
    | UnableToArchiveResource;

export type ArchiveResourceTypeRequest = {
    resourceTypeId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ArchiveResourceTypeResponse =
    | AccessError
    | NotFoundError
    | ResourceTypeArchived
    | UnableToArchiveResourceType;

export type ArchiveScheduleRequest = {
    scheduleId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ArchiveScheduleResponse =
    | AccessError
    | NotFoundError
    | ScheduleArchived;

export type ArchiveServiceRequest = {
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ArchiveServiceResourceRequest = {
    resourceId: Scalars["ID"]["input"];
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ArchiveServiceResourceResponse =
    | AccessError
    | NotFoundError
    | ServiceResourceArchived;

export type ArchiveServiceResponse =
    | AccessError
    | NotFoundError
    | ServiceAlreadyArchived
    | ServiceArchived;

export type ArchiveSettlementRequest = {
    settlementId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ArchiveSettlementResponse =
    | AccessError
    | NotFoundError
    | SettlementArchived;

export type ArchiveTagRequest = {
    tagId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ArchiveTagResponse = AccessError | NotFoundError | TagArchived;

export type ArchiveTriggerRequest = {
    tenantId: Scalars["ID"]["input"];
    triggerId: Scalars["ID"]["input"];
};

export type ArchiveTriggerResponse =
    | AccessError
    | NotFoundError
    | TriggerArchived;

export type AssignServiceToCategoryRequest = {
    categoryId: Scalars["ID"]["input"];
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type AssignServiceToCategoryResponse =
    | AccessError
    | NotFoundError
    | ServiceCategoryAssigned;

export type AssociatedUserAdded = {
    invoiceId: Scalars["ID"]["output"];
};

export type BillplzSessionInitiated = {
    externalId: Scalars["ID"]["output"];
    paymentSessionCreated: Scalars["DateTime"]["output"];
    paymentSessionId: Scalars["ID"]["output"];
    paymentUrl: Scalars["String"]["output"];
};

export type BookedSlot = {
    addons?: Maybe<Array<Maybe<BookingAddOn>>>;
    bookingId: Scalars["ID"]["output"];
    bookingSource?: Maybe<BookingSource>;
    endDt: Scalars["DateTime"]["output"];
    metadata: Scalars["JSONString"]["output"];
    recurringId?: Maybe<Scalars["ID"]["output"]>;
    selectedResourceIds: Array<Scalars["ID"]["output"]>;
    serviceId: Scalars["ID"]["output"];
    startDt: Scalars["DateTime"]["output"];
};

export type Booking = {
    activities?: Maybe<Array<Maybe<BookingActivity>>>;
    addons?: Maybe<Array<Maybe<BookingAddOn>>>;
    bookingSource?: Maybe<BookingSource>;
    cancelled?: Maybe<Scalars["DateTime"]["output"]>;
    cancelledReason?: Maybe<CancelledReason>;
    confirmed?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    endDt: Scalars["DateTime"]["output"];
    guest?: Maybe<BookingGuest>;
    guests?: Maybe<Array<Maybe<BookingGuest>>>;
    metadata: Scalars["JSONString"]["output"];
    recurringId?: Maybe<Scalars["ID"]["output"]>;
    referenceId: Scalars["String"]["output"];
    resources: Array<BookingResource>;
    service?: Maybe<Service>;
    serviceId?: Maybe<Scalars["ID"]["output"]>;
    startDt: Scalars["DateTime"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
    updated?: Maybe<Scalars["DateTime"]["output"]>;
};

export type BookingActivity = {
    occuredAt: Scalars["DateTime"]["output"];
    performedBy: Scalars["String"]["output"];
};

export type BookingAddOn = {
    addonId: Scalars["ID"]["output"];
    created: Scalars["DateTime"]["output"];
    perUnitPrice: Scalars["Int"]["output"];
    quantity: Scalars["Int"]["output"];
    uid: Scalars["ID"]["output"];
};

export type BookingAddOnRequest = {
    addonId: Scalars["ID"]["input"];
    perUnitPrice: Scalars["Int"]["input"];
    quantity: Scalars["Int"]["input"];
};

export type BookingAlreadyCancelledError = {
    message: Scalars["String"]["output"];
};

export type BookingAlreadyConfirmedError = {
    message: Scalars["String"]["output"];
};

export type BookingAlreadyUnconfirmedError = {
    message: Scalars["String"]["output"];
};

export type BookingCancelled = {
    bookingCancelled: Scalars["DateTime"]["output"];
    bookingId: Scalars["ID"]["output"];
};

export type BookingCancelledActivity = BookingActivity & {
    cancelledReason?: Maybe<CancelledReason>;
    newEndDt?: Maybe<Scalars["DateTime"]["output"]>;
    newResourceIds?: Maybe<Array<Scalars["ID"]["output"]>>;
    newStartDt?: Maybe<Scalars["DateTime"]["output"]>;
    occuredAt: Scalars["DateTime"]["output"];
    oldEndDt?: Maybe<Scalars["DateTime"]["output"]>;
    oldResourceIds?: Maybe<Array<Scalars["ID"]["output"]>>;
    oldStartDt?: Maybe<Scalars["DateTime"]["output"]>;
    performedBy: Scalars["String"]["output"];
};

export type BookingCancelledReasonModfied = {
    bookings: Array<Booking>;
};

export type BookingConfirmed = {
    bookingId: Scalars["ID"]["output"];
};

export type BookingConfirmedActivity = BookingActivity & {
    occuredAt: Scalars["DateTime"]["output"];
    performedBy: Scalars["String"]["output"];
};

export type BookingConnection = {
    edges: Array<BookingEdge>;
    pageInfo: PageInfo;
};

export type BookingCreatedActivity = BookingActivity & {
    endDt: Scalars["DateTime"]["output"];
    occuredAt: Scalars["DateTime"]["output"];
    performedBy: Scalars["String"]["output"];
    recurringId?: Maybe<Scalars["ID"]["output"]>;
    serviceId: Scalars["ID"]["output"];
    startDt: Scalars["DateTime"]["output"];
};

export type BookingEdge = {
    cursor: Scalars["String"]["output"];
    node: Booking;
};

export type BookingFilter = {
    byCancelReason?: InputMaybe<CancelledReason>;
    byName?: InputMaybe<Scalars["String"]["input"]>;
    byPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
    byRecurringId?: InputMaybe<Scalars["ID"]["input"]>;
    byReferenceId?: InputMaybe<Scalars["ID"]["input"]>;
    byResourceId?: InputMaybe<Scalars["ID"]["input"]>;
    byServiceId?: InputMaybe<Scalars["ID"]["input"]>;
    byStartDate?: InputMaybe<DateRangeFilter>;
    byStatus?: InputMaybe<BookingStatus>;
};

export type BookingFilterCount = {
    bookingCount: Scalars["Int"]["output"];
    categoryId: Scalars["ID"]["output"];
};

export type BookingGroupModification = {
    bookingModifications: Array<BookingModification>;
    recurringSeriesModification?: InputMaybe<RecurringSeriesModification>;
};

export type BookingGuest = {
    created: Scalars["DateTime"]["output"];
    email?: Maybe<Scalars["EmailAddress"]["output"]>;
    name: Scalars["String"]["output"];
    phoneNumber?: Maybe<Scalars["PhoneNumber"]["output"]>;
    uid: Scalars["ID"]["output"];
    userId?: Maybe<Scalars["ID"]["output"]>;
};

export type BookingGuestAdded = {
    bookingId: Scalars["ID"]["output"];
};

export type BookingGuestAddedToBookings = {
    bookingIds: Array<Scalars["ID"]["output"]>;
};

export type BookingGuestChangedActivity = BookingActivity & {
    email?: Maybe<Scalars["String"]["output"]>;
    name: Scalars["String"]["output"];
    occuredAt: Scalars["DateTime"]["output"];
    oldEmail?: Maybe<Scalars["String"]["output"]>;
    oldName: Scalars["String"]["output"];
    oldPhoneNumber?: Maybe<Scalars["String"]["output"]>;
    performedBy: Scalars["String"]["output"];
    phoneNumber?: Maybe<Scalars["String"]["output"]>;
};

export type BookingGuestModification = {
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    guestId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
};

export type BookingGuestModified = {
    guestId: Scalars["ID"]["output"];
};

export type BookingGuestRemoved = {
    bookingIds: Array<Scalars["ID"]["output"]>;
};

export type BookingGuestRequest = {
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    name: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type BookingMetadataChangedActivity = BookingActivity & {
    changedMetadata: Scalars["JSONString"]["output"];
    occuredAt: Scalars["DateTime"]["output"];
    oldMetadata?: Maybe<Scalars["JSONString"]["output"]>;
    performedBy: Scalars["String"]["output"];
};

export type BookingMetadataUpdated = {
    bookingId: Scalars["ID"]["output"];
    metadata: Scalars["JSONString"]["output"];
};

export type BookingModification = {
    bookingId: Scalars["ID"]["input"];
    endDt: Scalars["DateTime"]["input"];
    guestChanges: Array<BookingGuestModification>;
    metadata: Scalars["JSONString"]["input"];
    resourceChanges: Array<BookingResourceChangeInput>;
    startDt: Scalars["DateTime"]["input"];
};

export type BookingNotCancelledError = {
    message: Scalars["String"]["output"];
};

export type BookingReferenceIdUpdated = {
    bookingId: Scalars["ID"]["output"];
    referenceId: Scalars["String"]["output"];
};

export type BookingReferenceIdChangedActivity = BookingActivity & {
    occuredAt: Scalars["DateTime"]["output"];
    performedBy: Scalars["String"]["output"];
    referenceId: Scalars["ID"]["output"];
};

export type BookingRescheduled = {
    bookingId: Scalars["ID"]["output"];
};

export type BookingRescheduledActivity = BookingActivity & {
    endDt: Scalars["DateTime"]["output"];
    newResourceIds?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
    occuredAt: Scalars["DateTime"]["output"];
    oldEndDt?: Maybe<Scalars["DateTime"]["output"]>;
    oldResourceIds?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
    oldStartDt?: Maybe<Scalars["DateTime"]["output"]>;
    performedBy: Scalars["String"]["output"];
    startDt: Scalars["DateTime"]["output"];
};

export type BookingResource = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    resource: Resource;
    resourceId: Scalars["ID"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type BookingResourceChangeInput = {
    newResourceId: Scalars["ID"]["input"];
    targetResourceId: Scalars["ID"]["input"];
};

export type BookingSelectorRangeInput = {
    end: Scalars["Int"]["input"];
    start: Scalars["Int"]["input"];
};

export type BookingSlotRequest = {
    addons?: InputMaybe<Array<InputMaybe<BookingAddOnRequest>>>;
    endDt: Scalars["DateTime"]["input"];
    metadata: Scalars["JSONString"]["input"];
    selectedResourceIds: Array<Scalars["ID"]["input"]>;
    serviceId?: InputMaybe<Scalars["ID"]["input"]>;
    startDt: Scalars["DateTime"]["input"];
};

/** An enumeration. */
export type BookingSource = "ADMIN" | "WEB";

export type BookingStatistics = {
    bookingsHours: Scalars["Int"]["output"];
    bookingsMade: Scalars["Int"]["output"];
};

export type BookingStatus = {
    cancelled?: InputMaybe<Scalars["Boolean"]["input"]>;
    confirmed?: InputMaybe<Scalars["Boolean"]["input"]>;
    expired?: InputMaybe<Scalars["Boolean"]["input"]>;
    rescheduled?: InputMaybe<Scalars["Boolean"]["input"]>;
    unavailable?: InputMaybe<Scalars["Boolean"]["input"]>;
    unconfirmed?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BookingSummary = {
    cancelled?: Maybe<Scalars["DateTime"]["output"]>;
    confirmed?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    endDt: Scalars["DateTime"]["output"];
    metadata: Scalars["JSONString"]["output"];
    referenceId: Scalars["String"]["output"];
    resources: Array<ResourceBookingSummary>;
    service: ServiceBookingSummary;
    startDt: Scalars["DateTime"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type BookingUnavailable = {
    reason: ServiceUnavailabilityReason;
};

export type BookingUnconfirmed = {
    bookingId: Scalars["ID"]["output"];
};

export type BookingUnconfirmedActivity = BookingActivity & {
    occuredAt: Scalars["DateTime"]["output"];
    performedBy: Scalars["String"]["output"];
};

export type BookingsCancelled = {
    bookings: Array<Booking>;
};

export type BookingsConfirmed = {
    bookings: Array<Booking>;
};

export type BookingsMade = {
    bookedSlots: Array<BookedSlot>;
    bookingIds: Array<Scalars["ID"]["output"]>;
    referenceId: Scalars["ID"]["output"];
};

export type BookingsModified = {
    bookings: Array<Booking>;
};

export type BookingsReferenceIdUpdated = {
    bookings: Array<Booking>;
};

export type BookingsRevived = {
    bookings: Array<Booking>;
};

export type BookingsUnavailable = {
    reason: ServiceUnavailabilityReason;
    unavailableSlots: Array<UnavailableBookingSlot>;
};

export type CancelBookingRequest = {
    bookingId: Scalars["ID"]["input"];
    cancelReason?: InputMaybe<CancelledReason>;
    newEndDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    newResourceIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    newStartDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    oldEndDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    oldResourceIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    oldStartDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type CancelBookingResponse =
    | AccessError
    | BookingAlreadyCancelledError
    | BookingCancelled
    | NotFoundError;

export type CancelBookingWorkflowBookingNotCancellable = {
    message: Scalars["String"]["output"];
};

export type CancelBookingWorkflowCompleted = {
    state: CancelBookingWorkflowState;
};

export type CancelBookingWorkflowState = {
    bookingId: Scalars["ID"]["output"];
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    id: Scalars["ID"]["output"];
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
};

export type CancelBookingsRequest = {
    bookingIds: Array<Scalars["ID"]["input"]>;
    cancelReason?: InputMaybe<CancelledReason>;
    newEndDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    newResourceIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    newStartDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    oldEndDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    oldResourceIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    oldStartDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type CancelBookingsResponse =
    | AccessError
    | BookingAlreadyCancelledError
    | BookingsCancelled
    | NotFoundError;

export type CancelBookingsState = {
    bookingIds: Array<Scalars["String"]["output"]>;
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    id: Scalars["ID"]["output"];
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
    userId: Scalars["ID"]["output"];
};

export type CancelBookingsWorkflowBookingsNotCancellable = {
    message: Scalars["String"]["output"];
};

export type CancelBookingsWorkflowCompleted = {
    state: CancelBookingsState;
};

export type CancelCapturePaymentWorkflowRequest = {
    bookingIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    workflowId: Scalars["ID"]["input"];
};

export type CancelCapturePaymentWorkflowResponse =
    | CapturePaymentCancelled
    | WorkflowNotFound
    | WorkflowNotReady;

export type CancelCourtsiteCheckoutRequest = {
    checkoutId: Scalars["ID"]["input"];
};

export type CancelCourtsiteCheckoutResponse =
    | CourtsiteCheckoutNotCancellable
    | WorkflowCancelled
    | WorkflowNotFound;

export type CancelInvoiceRequest = {
    invoiceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CancelInvoiceResponse =
    | AccessError
    | InvoiceAlreadyCancelledError
    | InvoiceCancelled
    | NotFoundError;

export type CancelKonsolCheckoutRequest = {
    checkoutId: Scalars["ID"]["input"];
};

export type CancelKonsolCheckoutResponse =
    | KonsolCheckoutNotCancellable
    | WorkflowCancelled
    | WorkflowNotFound;

export type CancelKonsolPayInvoiceRequest = {
    workflowId: Scalars["ID"]["input"];
};

export type CancelKonsolPayInvoiceResponse =
    | KonsolPayInvoiceNotCancellable
    | WorkflowCancelled
    | WorkflowNotFound;

export type CancelPaymentRequest = {
    invoiceId: Scalars["ID"]["input"];
    paymentId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CancelPaymentResponse =
    | AccessError
    | NotFoundError
    | PaymentAlreadyCancelled
    | PaymentCancelled;

export type CancelPaymentSessionRequest = {
    paymentSessionId: Scalars["ID"]["input"];
    reason: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CancelPaymentSessionResponse =
    | AccessError
    | NotFoundError
    | PaymentSessionCancelled
    | PaymentSessionNotCancellable;

export type CancelPaymentWorkflowCompleted = {
    state: CancelPaymentWorkflowState;
};

export type CancelPaymentWorkflowPaymentNotCancellable = {
    message: Scalars["String"]["output"];
};

export type CancelPaymentWorkflowState = {
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    id: Scalars["ID"]["output"];
    invoiceId: Scalars["ID"]["output"];
    paymentId: Scalars["ID"]["output"];
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
};

/** An enumeration. */
export type CancelledReason =
    | "CANCELLED"
    | "EXPIRED"
    | "RESCHEDULED"
    | "UNAVAILABLE";

export type CannotModifySelfError = {
    message: Scalars["String"]["output"];
};

export type CapturePaymentCancelled = {
    state: CapturePaymentState;
};

export type CapturePaymentConfirmed = {
    state: CapturePaymentState;
};

export type CapturePaymentForceRetried = {
    state: CapturePaymentState;
};

export type CapturePaymentProviderDetailsProvided = {
    state: CapturePaymentState;
};

export type CapturePaymentState = {
    amountToCapture?: Maybe<Scalars["Int"]["output"]>;
    attempts: Scalars["Int"]["output"];
    billplzPaymentUrl?: Maybe<Scalars["String"]["output"]>;
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    expired?: Maybe<Scalars["Time"]["output"]>;
    id: Scalars["ID"]["output"];
    invoiceId: Scalars["ID"]["output"];
    ipay88BackendUrl?: Maybe<Scalars["String"]["output"]>;
    ipay88RequestSignature?: Maybe<Scalars["String"]["output"]>;
    ipay88ResponseUrl?: Maybe<Scalars["String"]["output"]>;
    isCancellable: Scalars["Boolean"]["output"];
    kiplepayCallbackUrl?: Maybe<Scalars["String"]["output"]>;
    kiplepayHashValue?: Maybe<Scalars["String"]["output"]>;
    kiplepayMerchantId?: Maybe<Scalars["String"]["output"]>;
    kiplepayReferenceNo?: Maybe<Scalars["String"]["output"]>;
    kiplepayReturnUrl?: Maybe<Scalars["String"]["output"]>;
    mastercardSessionId?: Maybe<Scalars["String"]["output"]>;
    paymentSessionCreated?: Maybe<Scalars["Time"]["output"]>;
    paymentSessionId?: Maybe<Scalars["ID"]["output"]>;
    razorpayOrderId?: Maybe<Scalars["String"]["output"]>;
    razorpayResponseUrl?: Maybe<Scalars["String"]["output"]>;
    runId: Scalars["ID"]["output"];
    spayCallbackUrl?: Maybe<Scalars["String"]["output"]>;
    spayEncryptedPayload?: Maybe<Scalars["String"]["output"]>;
    spayOrderNo?: Maybe<Scalars["String"]["output"]>;
    spayQrCode?: Maybe<Scalars["String"]["output"]>;
    spayReturnUrl?: Maybe<Scalars["String"]["output"]>;
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
};

export type CartItems = {
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type Category = {
    created: Scalars["DateTime"]["output"];
    metadata: Scalars["JSONString"]["output"];
    name: Scalars["String"]["output"];
    onlineServices?: Maybe<Array<Maybe<Service>>>;
    uid: Scalars["ID"]["output"];
    updated?: Maybe<Scalars["DateTime"]["output"]>;
};

export type CategoryOnlineServicesArgs = {
    tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CategoryCreated = {
    categoryId: Scalars["ID"]["output"];
};

export type CategoryFilter = {
    serviceMode?: InputMaybe<Scalars["String"]["input"]>;
    serviceVisibility?: InputMaybe<ServiceVisibility>;
};

export type CategoryMetadataUpdated = {
    categoryId: Scalars["ID"]["output"];
};

export type CategoryRenamed = {
    categoryId: Scalars["ID"]["output"];
};

export type ChangeResourceTypeRequest = {
    resourceId: Scalars["ID"]["input"];
    resourceTypeId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ChangeResourceTypeResponse =
    | AccessError
    | NotFoundError
    | ResourceTypeChanged
    | UnableToChangeResourceType;

export type CheckPaymentSessionRequest = {
    lastChancePoll?: InputMaybe<Scalars["Boolean"]["input"]>;
    paymentSessionId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CheckPaymentSessionResponse = {
    paymentSession: PaymentSession;
};

export type CheckoutDiscount = {
    discountType: Scalars["String"]["output"];
    discountedAmount: Scalars["Int"]["output"];
    initialAmount: Scalars["Int"]["output"];
    items: Array<CheckoutDiscountItemBreakdown>;
    total: Scalars["Int"]["output"];
};

export type CheckoutDiscountItem = {
    itemTypeId: Scalars["ID"]["input"];
    perUnitPrice: Scalars["Int"]["input"];
    quantity: Scalars["Int"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CheckoutDiscountItemBreakdown = {
    discountAmount: Scalars["Int"]["output"];
    itemTypeId: Scalars["ID"]["output"];
    perUnitPrice: Scalars["Int"]["output"];
    quantity: Scalars["Int"]["output"];
    subtotal: Scalars["Int"]["output"];
    tenantId: Scalars["ID"]["output"];
    total: Scalars["Int"]["output"];
};

export type Command = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    end: Scalars["DateTime"]["output"];
    light: Light;
    metadata: Scalars["JSONString"]["output"];
    start: Scalars["DateTime"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type ConfigurationChangeSortOrderEnum = "BY_CREATED_ON_DATE_DESC";

export type ConfirmBookingRequest = {
    bookingId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ConfirmBookingResponse =
    | AccessError
    | BookingAlreadyCancelledError
    | BookingConfirmed
    | NotFoundError;

export type ConfirmBookingsRequest = {
    bookingIds: Array<Scalars["ID"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type ConfirmBookingsResponse =
    | AccessError
    | BookingAlreadyCancelledError
    | BookingsConfirmed
    | NotFoundError;

export type ConfirmCapturePaymentRequest = {
    queryParams: Scalars["JSONString"]["input"];
    workflowId: Scalars["ID"]["input"];
};

export type ConfirmCapturePaymentResponse =
    | CapturePaymentConfirmed
    | WorkflowCancelled
    | WorkflowNotFound
    | WorkflowNotReady;

export type ConfirmPaymentSessionRequest = {
    paymentSessionId: Scalars["ID"]["input"];
    queryParams: Scalars["JSONString"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ConfirmPaymentSessionResponse =
    | AccessError
    | InternalError
    | NotFoundError
    | PaymentSessionConfirmed
    | PaymentSessionUnconfirmed;

export type Coordinates = {
    latitude: Scalars["Float"]["output"];
    longitude: Scalars["Float"]["output"];
};

export type CourtsiteBookingSlotInput = {
    endDt: Scalars["Time"]["input"];
    metadata: Scalars["JSONString"]["input"];
    selectedResourceIds: Array<Scalars["ID"]["input"]>;
    serviceId: Scalars["ID"]["input"];
    startDt: Scalars["Time"]["input"];
};

export type CourtsiteCheckoutAddOn = {
    addOnId: Scalars["ID"]["output"];
    claimDt: Scalars["Time"]["output"];
    name: Scalars["String"]["output"];
    price: Scalars["Int"]["output"];
    quantity: Scalars["Int"]["output"];
    tenantId: Scalars["ID"]["output"];
};

export type CourtsiteCheckoutAddOnInput = {
    addOnId: Scalars["ID"]["input"];
    claimDt: Scalars["Time"]["input"];
    quantity: Scalars["Int"]["input"];
};

export type CourtsiteCheckoutBooking = {
    deposit: Scalars["Int"]["output"];
    endDt: Scalars["Time"]["output"];
    price: Scalars["Int"]["output"];
    remainder: Scalars["Int"]["output"];
    resourceIds: Array<Scalars["String"]["output"]>;
    serviceId: Scalars["String"]["output"];
    startDt: Scalars["Time"]["output"];
    uid: Scalars["String"]["output"];
};

export type CourtsiteCheckoutBookingsUnavailable = {
    message: Scalars["String"]["output"];
};

export type CourtsiteCheckoutCustomEventLogged = {
    state: CourtsiteCheckoutState;
};

export type CourtsiteCheckoutExpired = {
    message: Scalars["String"]["output"];
};

export type CourtsiteCheckoutGuestInput = {
    email: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type CourtsiteCheckoutNotCancellable = {
    message: Scalars["String"]["output"];
};

export type CourtsiteCheckoutPaymentDetailsProvided = {
    state: CourtsiteCheckoutState;
};

export type CourtsiteCheckoutSlotAddOnInput = {
    addonID: Scalars["String"]["input"];
    perUnitPrice: Scalars["Int"]["input"];
    quantity: Scalars["Int"]["input"];
};

export type CourtsiteCheckoutSlotInput = {
    addons?: InputMaybe<Array<InputMaybe<CourtsiteCheckoutSlotAddOnInput>>>;
    endDt: Scalars["Time"]["input"];
    selectedResourceIds: Array<Scalars["ID"]["input"]>;
    serviceId: Scalars["ID"]["input"];
    startDt: Scalars["Time"]["input"];
};

export type CourtsiteCheckoutStarted = {
    state: CourtsiteCheckoutState;
};

export type CourtsiteCheckoutState = {
    addOns?: Maybe<Array<CourtsiteCheckoutAddOn>>;
    bookingIds?: Maybe<Array<Scalars["ID"]["output"]>>;
    bookingsMade: Array<CourtsiteCheckoutBooking>;
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    capturePaymentState?: Maybe<CapturePaymentState>;
    capturePaymentWorkflowId?: Maybe<Scalars["ID"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    customEvents: Array<Scalars["String"]["output"]>;
    discountedAmount?: Maybe<Scalars["Int"]["output"]>;
    expired?: Maybe<Scalars["Time"]["output"]>;
    hasDeposit: Scalars["Boolean"]["output"];
    id: Scalars["ID"]["output"];
    invoiceId?: Maybe<Scalars["ID"]["output"]>;
    invoiceIds: Array<Scalars["ID"]["output"]>;
    paymentAmount: Scalars["Int"]["output"];
    promocode?: Maybe<Scalars["ID"]["output"]>;
    referenceId?: Maybe<Scalars["String"]["output"]>;
    remainingPaymentAmount: Scalars["Int"]["output"];
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
    userId: Scalars["ID"]["output"];
};

export type CourtsiteGameWorkflowStarted = {
    state: CourtsiteGameWorkflowState;
};

export type CourtsiteGameWorkflowState = {
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    gameId?: Maybe<Scalars["ID"]["output"]>;
    id: Scalars["ID"]["output"];
    lastEventId?: Maybe<Scalars["ID"]["output"]>;
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    userId: Scalars["ID"]["output"];
};

export type CourtsitePartnersFilter = {
    byOrganisationIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type CourtsiteRescheduleBookingsConflictingWorkflow = {
    message: Scalars["String"]["output"];
};

export type CourtsiteRescheduleBookingsUnavailable = {
    message: Scalars["String"]["output"];
};

export type CourtsiteRescheduleExpired = {
    message: Scalars["String"]["output"];
};

export type CourtsiteReschedulePaymentDetailsProvided = {
    state: CourtsiteRescheduleState;
};

export type CourtsiteRescheduleStarted = {
    state: CourtsiteRescheduleState;
};

export type CourtsiteRescheduleState = {
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    capturePaymentState?: Maybe<CapturePaymentState>;
    capturePaymentWorkflowId?: Maybe<Scalars["ID"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    expired?: Maybe<Scalars["Time"]["output"]>;
    id: Scalars["ID"]["output"];
    newBookingId?: Maybe<Scalars["ID"]["output"]>;
    newInvoiceId?: Maybe<Scalars["ID"]["output"]>;
    oldBookingId: Scalars["ID"]["output"];
    priceDifference?: Maybe<Scalars["Int"]["output"]>;
    redirectUrl?: Maybe<Scalars["String"]["output"]>;
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
};

export type CourtsiteSearchRequest = {
    latitude?: InputMaybe<Scalars["Float"]["input"]>;
    longitude?: InputMaybe<Scalars["Float"]["input"]>;
    maxDistance?: InputMaybe<Scalars["Int"]["input"]>;
    services?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type CreateAddOnRequest = {
    maxQuantity?: InputMaybe<Scalars["Int"]["input"]>;
    name: Scalars["String"]["input"];
    price: Scalars["Int"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreateAddOnResponse = AccessError | AddOnCreated;

export type CreateCategoryRequest = {
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
};

export type CreateCategoryResponse = AccessError | CategoryCreated;

export type CreateCustomerRequest = {
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    metadata?: InputMaybe<Scalars["JSONString"]["input"]>;
    name: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    tags?: InputMaybe<Array<CustomerTagInput>>;
    tenantId: Scalars["ID"]["input"];
};

export type CreateCustomerResponse =
    | AccessError
    | CustomerCreated
    | InternalError;

export type CreateCustomersRequest = {
    customers: Array<CreateCustomerRequest>;
    tenantId: Scalars["ID"]["input"];
};

export type CreateCustomersResponse = {
    results: Array<CreateCustomerResponse>;
};

export type CreateDailyServiceRequest = {
    categoryId?: InputMaybe<Scalars["ID"]["input"]>;
    endTime: Scalars["Time"]["input"];
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    resources: Array<ServiceResourceInput>;
    startTime: Scalars["Time"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreateDailyServiceResponse =
    | AccessError
    | DailyServiceCreated
    | NotFoundError;

export type CreateGrpCashSalesTxDetail = {
    account: Scalars["String"]["input"];
    drTermEndDate: Scalars["Date"]["input"];
    drTermStartDate: Scalars["Date"]["input"];
    qty: Scalars["Float"]["input"];
    subaccount: Scalars["String"]["input"];
    transactionDescription: Scalars["String"]["input"];
    unitPrice: Scalars["Int"]["input"];
};

export type CreateGameRequest = {
    categoryId: Scalars["ID"]["input"];
    description: Scalars["String"]["input"];
    endDt: Scalars["DateTime"]["input"];
    location: LocationInput;
    maxPlayers: Scalars["Int"]["input"];
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    organizerDetails: GamePlayerInput;
    private?: InputMaybe<Scalars["Boolean"]["input"]>;
    reservedPlayers?: InputMaybe<Scalars["Int"]["input"]>;
    startDt: Scalars["DateTime"]["input"];
};

export type CreateGameResponse = AccessError | GameCreated | InternalError;

export type CreateInvoiceItemRelatedEntityInput = {
    relatedEntityId: Scalars["String"]["input"];
    relatedEntityType: InvoiceItemRelatedEntityType;
};

export type CreateInvoiceItemRequest = {
    description: Scalars["String"]["input"];
    metadata: Scalars["JSONString"]["input"];
    perUnitPrice: Scalars["Int"]["input"];
    quantity: Scalars["Int"]["input"];
    relatedEntity?: InputMaybe<CreateInvoiceItemRelatedEntityInput>;
};

export type CreateInvoiceRequest = {
    expiresOn?: InputMaybe<Scalars["DateTime"]["input"]>;
    invoiceeEmail?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    invoiceeId?: InputMaybe<Scalars["ID"]["input"]>;
    invoiceeName: Scalars["String"]["input"];
    invoiceePhoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    isCourtsiteOwned?: InputMaybe<Scalars["Boolean"]["input"]>;
    items: Array<CreateInvoiceItemRequest>;
    metadata: Scalars["JSONString"]["input"];
    settlementRate?: InputMaybe<Scalars["Float"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type CreateInvoiceResponse =
    | AccessError
    | InternalError
    | InvoiceCreated;

export type CreateLightRequest = {
    boardId: Scalars["ID"]["input"];
    disableToggling: Scalars["Boolean"]["input"];
    isUserActivated: Scalars["Boolean"]["input"];
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    relayId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreateLightResponse = AccessError | LightCreated;

export type CreateLocationRequest = {
    city?: InputMaybe<Scalars["String"]["input"]>;
    latitude: Scalars["Float"]["input"];
    longitude: Scalars["Float"]["input"];
    name: Scalars["String"]["input"];
};

export type CreateLocationResponse = AccessError | LocationCreated;

export type CreateOrganisationRequest = {
    latitude: Scalars["Float"]["input"];
    longitude: Scalars["Float"]["input"];
    metadata: Scalars["JSONString"]["input"];
    organisationName: Scalars["String"]["input"];
    ownerName: Scalars["String"]["input"];
    services: Array<Scalars["String"]["input"]>;
};

export type CreateOrganisationResponse = AccessError | OrganisationCreated;

export type CreateOverrideRequest = {
    end: Scalars["DateTime"]["input"];
    lightId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
    start: Scalars["DateTime"]["input"];
    state: LightState;
    tenantId: Scalars["ID"]["input"];
};

export type CreateOverrideResponse =
    | AccessError
    | InternalError
    | NotFoundError
    | OverrideCreated;

export type CreatePsmgrpCashSalesTxRequest = {
    date: Scalars["Date"]["input"];
    description: Scalars["String"]["input"];
    details: Array<CreateGrpCashSalesTxDetail>;
    paymentRef: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreatePsmgrpCashSalesTxResponse =
    | AccessError
    | InternalError
    | PsmgrpCashSalesTxCreated;

export type CreatePriceRuleDurationRequest = {
    deposit?: InputMaybe<Scalars["Int"]["input"]>;
    depositType?: InputMaybe<DepositType>;
    end: DayTimeInput;
    rates: Array<PriceRateInput>;
    start: DayTimeInput;
};

export type CreatePriceRulePerDayRequest = {
    deposit?: InputMaybe<Scalars["Int"]["input"]>;
    depositType?: InputMaybe<DepositType>;
    perDayRate: Scalars["Int"]["input"];
    weekday: Scalars["Int"]["input"];
};

export type CreatePriceRulePerUnitRequest = {
    deposit?: InputMaybe<Scalars["Int"]["input"]>;
    depositType?: InputMaybe<DepositType>;
    end: DayTimeInput;
    perUnitRate: Scalars["Int"]["input"];
    start: DayTimeInput;
};

export type CreatePriceRulesetsForServicesRequest = {
    appliesTo: Array<Scalars["ID"]["input"]>;
    requests: Array<CreatePriceRulesetsRequestRuleset>;
    tenantId: Scalars["ID"]["input"];
};

export type CreatePriceRulesetsRequestRuleset = {
    effectiveFrom: Scalars["DateTime"]["input"];
    perDayRules?: InputMaybe<Array<CreatePriceRulePerDayRequest>>;
    perUnitRules?: InputMaybe<Array<CreatePriceRulePerUnitRequest>>;
    rules?: Array<CreatePriceRuleDurationRequest>;
};

export type CreatePromoCodeRequest = {
    code: Scalars["ID"]["input"];
    discount: Scalars["Int"]["input"];
    endDt: Scalars["DateTime"]["input"];
    itemTypeIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
    metadata: Scalars["JSONString"]["input"];
    startDt: Scalars["DateTime"]["input"];
    tenantIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
    type: DiscountType;
};

export type CreatePromoCodeRequestV2 = {
    applicableServiceIds: Array<Scalars["String"]["input"]>;
    bookingEnd?: InputMaybe<Scalars["DateTime"]["input"]>;
    bookingStart?: InputMaybe<Scalars["DateTime"]["input"]>;
    code: Scalars["String"]["input"];
    discountAmount: Scalars["Int"]["input"];
    discountType: DiscountTypeV2;
    endDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    name: Scalars["String"]["input"];
    redemptionLimit: Scalars["Int"]["input"];
    startDt: Scalars["DateTime"]["input"];
    tenantId: Scalars["String"]["input"];
};

export type CreatePromoCodeResponse = PromoCodeAlreadyExists | PromoCodeCreated;

export type CreatePromoCodeResponseV2 =
    | AccessError
    | DuplicatePromoCodeError
    | PromoCodeCreatedV2;

export type CreateResourceLinkInput = {
    metadata: Scalars["JSONString"]["input"];
    resourceId1: Scalars["ID"]["input"];
    resourceId2: Scalars["ID"]["input"];
};

export type CreateResourceLinksRequest = {
    links: Array<CreateResourceLinkInput>;
    tenantId: Scalars["ID"]["input"];
};

export type CreateResourceLinksResponse =
    | AccessError
    | NotFoundError
    | ResourceLinksAlreadyExist
    | ResourceLinksCreated;

export type CreateResourceRequest = {
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    resourceTypeId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreateResourceResponse =
    | AccessError
    | NotFoundError
    | ResourceCreated;

export type CreateResourceTypeRequest = {
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreateResourceTypeResponse = AccessError | ResourceTypeCreated;

export type CreateRulesetsForServicesResponse =
    | AccessError
    | MissingPriceRules
    | MissingService
    | PriceRulesetsForServicesCreated;

export type CreateScheduleRequest = {
    daytimeSlots: Array<DayTimeSlotInput>;
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    rules: Array<RuleInput>;
    tenantId: Scalars["ID"]["input"];
};

export type CreateScheduleResponse =
    | AccessError
    | NotFoundError
    | ScheduleCreated
    | UnhandledRuleTypeError;

export type CreateServiceRequest = {
    categoryId?: InputMaybe<Scalars["ID"]["input"]>;
    metadata: Scalars["JSONString"]["input"];
    minRescheduleNotice?: InputMaybe<Scalars["Int"]["input"]>;
    name: Scalars["String"]["input"];
    resources: Array<ServiceResourceInput>;
    tenantId: Scalars["ID"]["input"];
};

export type CreateServiceResponse =
    | AccessError
    | NotFoundError
    | ServiceCreated;

export type CreateSettlementConfigurationRequest = {
    commissionPercentage: Scalars["Float"]["input"];
    metadata: Scalars["JSONString"]["input"];
    monthlyFee: Scalars["Int"]["input"];
    settlementRange: SettlementRangeConfigurationInput;
    slug: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreateSettlementConfigurationResponse =
    | AccessError
    | NotFoundError
    | SettlementConfigurationCreated;

export type CreateSettlementConfigurationWithApprovalRequest = {
    commissionPercentage: Scalars["Float"]["input"];
    metadata: Scalars["JSONString"]["input"];
    monthlyFee: Scalars["Int"]["input"];
    settlementRange: SettlementRangeConfigurationInput;
    slug: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreateSettlementConfigurationWithApprovalResponse =
    | AccessError
    | NotFoundError
    | SettlementConfigurationWithApprovalCreated;

export type CreateSettlementRequest = {
    amount: Scalars["Int"]["input"];
    endDate: Scalars["DateTime"]["input"];
    isAutoGen?: InputMaybe<Scalars["Boolean"]["input"]>;
    isResellerSettlement?: InputMaybe<Scalars["Boolean"]["input"]>;
    metadata: Scalars["JSONString"]["input"];
    reportUrl: Scalars["String"]["input"];
    startDate: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreateSettlementResponse = AccessError | SettlementCreated;

export type CreateTagRequest = {
    name: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreateTagResponse = AccessError | TagCreated;

export type CreateTriggerRequest = {
    lightId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
    resourceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreateTriggerResponse =
    | AccessError
    | InternalError
    | NotFoundError
    | TriggerCreated;

export type CreateUserProfileManualRequest = {
    dateOfBirth?: InputMaybe<Scalars["Date"]["input"]>;
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    firebaseId: Scalars["ID"]["input"];
    gender?: InputMaybe<Gender>;
    name: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
};

export type CreateUserProfileManualResponse =
    | AccessError
    | InvalidValue
    | UserAlreadyExists
    | UserProfileCreated;

export type CreateUserProfileRequest = {
    dateOfBirth?: InputMaybe<Scalars["Date"]["input"]>;
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    gender?: InputMaybe<Gender>;
    name: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
};

export type CreateUserProfileResponse =
    | AccessError
    | InvalidValue
    | UserAlreadyExists
    | UserProfileCreated;

export type Customer = {
    created: Scalars["DateTime"]["output"];
    customerTags: Array<CustomerTag>;
    email?: Maybe<Scalars["EmailAddress"]["output"]>;
    metadata?: Maybe<Scalars["JSONString"]["output"]>;
    name: Scalars["String"]["output"];
    phoneNumber?: Maybe<Scalars["PhoneNumber"]["output"]>;
    serviceIds?: Maybe<Array<Scalars["ID"]["output"]>>;
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type CustomerAlreadyArchived = {
    message: Scalars["String"]["output"];
};

export type CustomerArchived = {
    customerId: Scalars["String"]["output"];
};

export type CustomerCreated = {
    customerId: Scalars["String"]["output"];
};

export type CustomerFilter = {
    byCustomerEmail?: InputMaybe<Scalars["String"]["input"]>;
    byCustomerName?: InputMaybe<Scalars["String"]["input"]>;
    byCustomerPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
    byTagIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CustomerInfoEdited = {
    customerId: Scalars["String"]["output"];
};

export type CustomerMetadataUpdated = {
    customerId: Scalars["ID"]["output"];
    metadata?: Maybe<Scalars["JSONString"]["output"]>;
};

export type CustomerTag = {
    created: Scalars["DateTime"]["output"];
    expiresOn?: Maybe<Scalars["DateTime"]["output"]>;
    tag: Tag;
    tagId: Scalars["ID"]["output"];
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type CustomerTagInput = {
    expiresOn?: InputMaybe<Scalars["DateTime"]["input"]>;
    tagId: Scalars["ID"]["input"];
};

export type DailyServiceCreated = {
    serviceId: Scalars["ID"]["output"];
};

export type DailyServiceTimesSet = {
    endTime: Scalars["Time"]["output"];
    serviceId: Scalars["ID"]["output"];
    startTime: Scalars["Time"]["output"];
};

export type DateRangeFilter = {
    endDt: Scalars["DateTime"]["input"];
    startDt: Scalars["DateTime"]["input"];
};

export type DatetimeRange = {
    end: Scalars["DateTime"]["output"];
    start: Scalars["DateTime"]["output"];
};

export type DayTime = {
    hour: Scalars["Int"]["output"];
    hourOffset: Scalars["Int"]["output"];
    isoWeekday: Scalars["Int"]["output"];
    minute: Scalars["Int"]["output"];
    /** @deprecated Moving to isoWeekday */
    weekday: Scalars["Int"]["output"];
};

export type DayTimeInput = {
    hour: Scalars["Int"]["input"];
    hourOffset?: InputMaybe<Scalars["Int"]["input"]>;
    isoWeekday?: InputMaybe<Scalars["Int"]["input"]>;
    minute?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DayTimeSlot = {
    durationMinutes: Scalars["Int"]["output"];
    startMinuteOfWeek: Scalars["Int"]["output"];
};

export type DayTimeSlotInput = {
    durationMinutes: Scalars["Int"]["input"];
    startMinuteOfWeek: Scalars["Int"]["input"];
};

export type DeactivateLightEventsRequest = {
    groupIds: Array<Scalars["ID"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type DeactivateLightEventsResponse =
    | AccessError
    | LightEventsDeactivated
    | NotFoundError;

/** An enumeration. */
export type DepositType = "FLAT" | "PERCENTAGE";

/** An enumeration. */
export type DiscountType = "FLAT" | "PERCENT";

export type DiscountTypeV2 = "FLAT" | "NONE" | "PERCENT";

export type DuplicatePromoCodeError = {
    code: Scalars["String"]["output"];
    message: Scalars["String"]["output"];
};

export type DurationOption = {
    end: DayTime;
    start: DayTime;
};

export type DurationOptionInput = {
    end: DayTimeInput;
    start: DayTimeInput;
};

export type EditCustomerInfoRequest = {
    customerId?: InputMaybe<Scalars["ID"]["input"]>;
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    metadata?: InputMaybe<Scalars["JSONString"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    tags?: InputMaybe<Array<CustomerTagInput>>;
    tenantId: Scalars["ID"]["input"];
};

export type EditCustomerInfoResponse =
    | AccessError
    | CustomerInfoEdited
    | NotFoundError;

export type EditGameOrganizerRequest = {
    gameId: Scalars["ID"]["input"];
    organizerDetails: GameOrganizerInput;
};

export type EditGameOrganizerResponse =
    | AccessError
    | GameOrganizerEdited
    | NotFoundError;

export type EditGameRequest = {
    description: Scalars["String"]["input"];
    endDt: Scalars["DateTime"]["input"];
    location?: InputMaybe<LocationInput>;
    maxPlayers: Scalars["Int"]["input"];
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    private?: InputMaybe<Scalars["Boolean"]["input"]>;
    reservedPlayers?: InputMaybe<Scalars["Int"]["input"]>;
    startDt: Scalars["DateTime"]["input"];
    uid: Scalars["ID"]["input"];
};

export type EditGameResponse = AccessError | GameEdited | NotFoundError;

export type EditLocationRequest = {
    city?: InputMaybe<Scalars["String"]["input"]>;
    latitude: Scalars["Float"]["input"];
    locationId: Scalars["ID"]["input"];
    longitude: Scalars["Float"]["input"];
    name: Scalars["String"]["input"];
};

export type EditLocationResponse = AccessError | LocationEdited | NotFoundError;

export type EditPaymentMethodAvailabilityRequest = {
    availability: Scalars["Boolean"]["input"];
    paymentMethodId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type EditPaymentMethodAvailabilityResponse =
    | AccessError
    | NotFoundError
    | PaymentMethodAvailabilityEdited;

export type EditPaymentMethodMetadataRequest = {
    metadata: Scalars["JSONString"]["input"];
    paymentMethodId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type EditPaymentMethodMetadataResponse =
    | AccessError
    | NotFoundError
    | PaymentMethodMetadataEdited;

export type EditPaymentMethodNameRequest = {
    name: Scalars["String"]["input"];
    paymentMethodId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type EditPaymentMethodNameResponse =
    | AccessError
    | NotFoundError
    | PaymentMethodNameEdited;

export type EditPromoCodeRequestV2 = {
    applicableServiceIds: Array<Scalars["String"]["input"]>;
    bookingEnd?: InputMaybe<Scalars["DateTime"]["input"]>;
    bookingStart?: InputMaybe<Scalars["DateTime"]["input"]>;
    code: Scalars["String"]["input"];
    discountAmount: Scalars["Int"]["input"];
    discountType: DiscountTypeV2;
    endDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    name: Scalars["String"]["input"];
    promoCodeId: Scalars["ID"]["input"];
    redemptionLimit: Scalars["Int"]["input"];
    startDt: Scalars["DateTime"]["input"];
    tenantId: Scalars["String"]["input"];
};

export type EditPromoCodeResponseV2 =
    | AccessError
    | DuplicatePromoCodeError
    | NotFoundError
    | PromoCodeEditedV2;

export type EditSettlementConfigurationRequest = {
    commissionPercentage: Scalars["Float"]["input"];
    configurationId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
    monthlyFee: Scalars["Int"]["input"];
    settlementRange: SettlementRangeConfigurationInput;
    slug: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type EditSettlementConfigurationResponse =
    | AccessError
    | NotFoundError
    | SettlementConfigurationEdited;

export type EditSettlementConfigurationWithApprovalRequest = {
    commissionPercentage: Scalars["Float"]["input"];
    configurationId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
    monthlyFee: Scalars["Int"]["input"];
    settlementRange: SettlementRangeConfigurationInput;
    slug: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type EditSettlementConfigurationWithApprovalResponse =
    | AccessError
    | NotFoundError
    | SettlementConfigurationWithApprovalEdited;

export type EditTagRequest = {
    name: Scalars["String"]["input"];
    tagId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type EditTagResponse = AccessError | NotFoundError | TagEdited;

export type Error = {
    code: Scalars["String"]["output"];
    message: Scalars["String"]["output"];
};

export type Event = Command & {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    end: Scalars["DateTime"]["output"];
    groupId: Scalars["ID"]["output"];
    light: Light;
    metadata: Scalars["JSONString"]["output"];
    resourceId: Scalars["ID"]["output"];
    start: Scalars["DateTime"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type EventInput = {
    end: Scalars["DateTime"]["input"];
    groupId: Scalars["ID"]["input"];
    resourceId: Scalars["ID"]["input"];
    start: Scalars["DateTime"]["input"];
};

export type EventsArchived = {
    events: Array<Event>;
};

export type EventsSynced = {
    events: Array<Event>;
};

export type FederationWorkaround = {
    id: Scalars["ID"]["output"];
};

export type FixedDurationOptionsUpdated = {
    serviceId: Scalars["ID"]["output"];
};

export type ForceRetryCapturePaymentRequest = {
    paymentProvider?: InputMaybe<PaymentProvider>;
    redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
    workflowId: Scalars["ID"]["input"];
};

export type ForceRetryCapturePaymentResponse =
    | CapturePaymentForceRetried
    | WorkflowNotFound
    | WorkflowNotReady;

export type Game = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    categoryId: Scalars["ID"]["output"];
    created: Scalars["DateTime"]["output"];
    description: Scalars["String"]["output"];
    endDt: Scalars["DateTime"]["output"];
    events: Array<GameEvent>;
    isPrivate: Scalars["Boolean"]["output"];
    location: GameLocation;
    maxPlayers: Scalars["Int"]["output"];
    metadata: Scalars["JSONString"]["output"];
    name: Scalars["String"]["output"];
    organizer: Organizer;
    players?: Maybe<Array<GamePlayer>>;
    reservedPlayers?: Maybe<Scalars["Int"]["output"]>;
    startDt: Scalars["DateTime"]["output"];
    uid: Scalars["ID"]["output"];
};

export type GameArchived = {
    game: Game;
};

export type GameArchivedEvent = GameEvent & {
    created: Scalars["DateTime"]["output"];
    uid: Scalars["ID"]["output"];
};

export type GameConnection = {
    edges: Array<GameEdge>;
    pageInfo: PageInfo;
};

export type GameCreated = {
    game: Game;
};

export type GameCreatedEvent = GameEvent & {
    categoryId: Scalars["ID"]["output"];
    created: Scalars["DateTime"]["output"];
    description: Scalars["String"]["output"];
    endDt: Scalars["DateTime"]["output"];
    gameId: Scalars["ID"]["output"];
    gameMetadata: Scalars["JSONString"]["output"];
    isPrivate?: Maybe<Scalars["Boolean"]["output"]>;
    location: GameLocation;
    maxPlayers: Scalars["Int"]["output"];
    name: Scalars["String"]["output"];
    organizer: Organizer;
    reservedPlayers?: Maybe<Scalars["Int"]["output"]>;
    startDt: Scalars["DateTime"]["output"];
    uid: Scalars["ID"]["output"];
};

export type GameDetailsSetEvent = GameEvent & {
    created: Scalars["DateTime"]["output"];
    description: Scalars["String"]["output"];
    endDt: Scalars["DateTime"]["output"];
    gameMetadata: Scalars["JSONString"]["output"];
    isPrivate?: Maybe<Scalars["Boolean"]["output"]>;
    location: GameLocation;
    maxPlayers: Scalars["Int"]["output"];
    name: Scalars["String"]["output"];
    reservedPlayers?: Maybe<Scalars["Int"]["output"]>;
    startDt: Scalars["DateTime"]["output"];
    uid: Scalars["ID"]["output"];
};

export type GameEdge = {
    cursor: Scalars["String"]["output"];
    node: Game;
};

export type GameEdited = {
    game: Game;
};

export type GameEvent = {
    created: Scalars["DateTime"]["output"];
    uid: Scalars["ID"]["output"];
};

export type GameFilter = {
    categoryId?: InputMaybe<Scalars["ID"]["input"]>;
    includeArchivedGames?: InputMaybe<Scalars["Boolean"]["input"]>;
    onlyInclude?: InputMaybe<GameMember>;
    startDateAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type GameFilterCount = {
    categoryId: Scalars["ID"]["output"];
    gameCount: Scalars["Int"]["output"];
};

export type GameJoined = {
    game: Game;
};

export type GameLeft = {
    game: Game;
};

export type GameLocation = {
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
};

/** An enumeration. */
export type GameMember = "ORGANIZER" | "PLAYER";

export type GameOrganizerDetailsSetEvent = GameEvent & {
    created: Scalars["DateTime"]["output"];
    organizer: Organizer;
    uid: Scalars["ID"]["output"];
};

export type GameOrganizerEdited = {
    game: Game;
};

export type GameOrganizerInput = {
    displayPicture?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
};

export type GamePlayer = {
    displayPicture?: Maybe<Scalars["String"]["output"]>;
    email?: Maybe<Scalars["EmailAddress"]["output"]>;
    joined: Scalars["DateTime"]["output"];
    name: Scalars["String"]["output"];
    phoneNumber?: Maybe<Scalars["PhoneNumber"]["output"]>;
    playerId: Scalars["ID"]["output"];
};

export type GamePlayerInput = {
    displayPicture?: InputMaybe<Scalars["String"]["input"]>;
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    name: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
};

export type GameStatistics = {
    gamesMade: Scalars["Int"]["output"];
};

/** An enumeration. */
export type Gender = "FEMALE" | "MALE";

export type GeneratePsmGrpCashSaleTxCompleted = {
    state: GeneratePsmGrpCashSaleTxState;
};

export type GeneratePsmGrpCashSaleTxState = {
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    id: Scalars["ID"]["output"];
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
};

export type GenerateSettlementDownloadLinkRequest = {
    filename?: InputMaybe<Scalars["String"]["input"]>;
    settlementId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type GenerateSettlementDownloadLinkResponse =
    | AccessError
    | NotFoundError
    | SettlementDownloadLinkGenerated;

export type GenerateSettlementReportCompleted = {
    state: GenerateSettlementReportState;
};

export type GenerateSettlementReportRequest = {
    endDt: Scalars["Time"]["input"];
    includeSubscription: Scalars["Boolean"]["input"];
    settlementConfigurationId: Scalars["ID"]["input"];
    startDt: Scalars["Time"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type GenerateSettlementReportResponse =
    | GenerateSettlementReportCompleted
    | WorkflowCancelled
    | WorkflowNotReady;

export type GenerateSettlementReportState = {
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    id: Scalars["ID"]["output"];
    resellerSettlementReportId?: Maybe<Scalars["ID"]["output"]>;
    runId: Scalars["ID"]["output"];
    settlementReportId?: Maybe<Scalars["ID"]["output"]>;
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
    userId: Scalars["ID"]["output"];
};

export type GetLhdnSubmissionStatus = {
    status: LhdnSubmissionStatus;
    submissionId: Scalars["ID"]["output"];
};

export type GroupActivation = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    created?: Maybe<Scalars["DateTime"]["output"]>;
    groupId: Scalars["ID"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type GroupIdEventsActivationStatus = {
    groupId: Scalars["ID"]["output"];
    isActivated: Scalars["Boolean"]["output"];
    requiresUserActivation: Scalars["Boolean"]["output"];
    tenantId: Scalars["ID"]["output"];
};

export type GroupedBookings = {
    bookings: Array<BookingSummary>;
    endDt: Scalars["DateTime"]["output"];
    referenceId: Scalars["String"]["output"];
    startDt: Scalars["DateTime"]["output"];
};

export type GroupedBookingsConnection = {
    edges: Array<GroupedBookingsEdge>;
    pageInfo: PageInfo;
};

export type GroupedBookingsEdge = {
    cursor: Scalars["String"]["output"];
    node: GroupedBookings;
};

export type IPay88SessionInitiated = {
    backendUrl: Scalars["String"]["output"];
    externalId: Scalars["ID"]["output"];
    paymentSessionCreated: Scalars["DateTime"]["output"];
    paymentSessionId: Scalars["ID"]["output"];
    requestSignature: Scalars["String"]["output"];
    responseUrl: Scalars["String"]["output"];
};

export type IdType = "ARMY" | "BRN" | "NRIC" | "PASSPORT";

export type InitiatePaymentSessionRequest = {
    amount: Scalars["Int"]["input"];
    customReferenceId?: InputMaybe<Scalars["String"]["input"]>;
    description: Scalars["String"]["input"];
    expiryInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
    metadata: Scalars["JSONString"]["input"];
    payerEmail: Scalars["EmailAddress"]["input"];
    payerName: Scalars["String"]["input"];
    payerPhoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    provider: PaymentProvider;
    redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type InitiatePaymentSessionResponse =
    | AccessError
    | BillplzSessionInitiated
    | IPay88SessionInitiated
    | InternalError
    | InvalidRedirectUrlError
    | KiplepaySessionInitiated
    | MastercardSessionInitiated
    | RazorpaySessionInitiated
    | SPaySessionInitiated;

export type InternalError = {
    message: Scalars["String"]["output"];
};

export type InvalidBooking = {
    bookingId: Scalars["ID"]["output"];
    reason: Scalars["String"]["output"];
};

export type InvalidCourtsiteReschedulePaymentDetailsRequest = {
    message: Scalars["String"]["output"];
};

export type InvalidRedirectUrlError = {
    message: Scalars["String"]["output"];
};

export type InvalidServiceModeError = {
    message: Scalars["String"]["output"];
};

export type InvalidValue = {
    message: Scalars["String"]["output"];
};

export type Invoice = {
    amountPaid: Scalars["Int"]["output"];
    associatedUsers: Array<InvoiceAssociatedUser>;
    cancelled?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    expiresOn?: Maybe<Scalars["DateTime"]["output"]>;
    invoiceeEmail?: Maybe<Scalars["EmailAddress"]["output"]>;
    invoiceeId?: Maybe<Scalars["ID"]["output"]>;
    invoiceeName: Scalars["String"]["output"];
    invoiceePhoneNumber?: Maybe<Scalars["PhoneNumber"]["output"]>;
    isCourtsiteOwned: Scalars["Boolean"]["output"];
    items: Array<InvoiceItem>;
    metadata: Scalars["JSONString"]["output"];
    outstandingBalance: Scalars["Int"]["output"];
    payments: Array<Payment>;
    printNumber?: Maybe<Scalars["Int"]["output"]>;
    promos: Array<InvoicePromo>;
    settlementRate?: Maybe<Scalars["Float"]["output"]>;
    subtotal: Scalars["Int"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    total: Scalars["Int"]["output"];
    uid: Scalars["ID"]["output"];
};

export type InvoiceAlreadyCancelledError = {
    message: Scalars["String"]["output"];
};

export type InvoiceAlreadyPaidError = {
    invoice: Invoice;
};

export type InvoiceAssociatedUser = {
    created: Scalars["DateTime"]["output"];
    userId: Scalars["ID"]["output"];
};

export type InvoiceCancelled = {
    invoice: Invoice;
};

export type InvoiceConnection = {
    edges: Array<InvoiceEdge>;
    pageInfo: PageInfo;
};

export type InvoiceCourtsiteOwnershipRequestError = {
    message: Scalars["String"]["output"];
};

export type InvoiceCourtsiteOwnershipUpdated = {
    invoice: Invoice;
};

export type InvoiceCreated = {
    invoice: Invoice;
};

export type InvoiceDetail = {
    invoiceDatetime: Scalars["DateTime"]["input"];
    invoiceItems: Array<InvoiceLineItem>;
    invoiceNumber: Scalars["String"]["input"];
};

export type InvoiceEdge = {
    cursor: Scalars["String"]["output"];
    node: Invoice;
};

export type InvoiceFilter = {
    byCreatedDate?: InputMaybe<DateRangeFilter>;
    byInvoicePrintNumber?: InputMaybe<Scalars["Int"]["input"]>;
    byInvoiceeName?: InputMaybe<Scalars["String"]["input"]>;
    byIsCourtsiteOwned?: InputMaybe<Scalars["Boolean"]["input"]>;
    byRelatedEntityId?: InputMaybe<Scalars["String"]["input"]>;
    byRelatedEntityIds?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    byStatus?: InputMaybe<InvoiceStatus>;
};

export type InvoiceItem = {
    created: Scalars["DateTime"]["output"];
    description: Scalars["String"]["output"];
    metadata: Scalars["JSONString"]["output"];
    perUnitPrice: Scalars["Int"]["output"];
    quantity: Scalars["Int"]["output"];
    relatedEntity?: Maybe<InvoiceItemRelatedEntity>;
    subtotal: Scalars["Int"]["output"];
    uid: Scalars["ID"]["output"];
};

export type InvoiceItemRelatedEntity = {
    created: Scalars["DateTime"]["output"];
    entityId: Scalars["ID"]["output"];
    entityType: InvoiceItemRelatedEntityType;
    uid: Scalars["ID"]["output"];
};

/** An enumeration. */
export type InvoiceItemRelatedEntityType =
    | "ADDON"
    | "BOOKING"
    | "COMPETITION_REGISTRATION"
    | "PROMOCODE"
    | "UNKNOWN";

export type InvoiceItemRelatedEntityUpdated = {
    invoice: Invoice;
    newInvoiceItemRelatedEntityId: Scalars["ID"]["output"];
};

export type InvoiceItemsModified = {
    invoice: Invoice;
};

export type InvoiceLineItem = {
    description: Scalars["String"]["input"];
    total: Scalars["Int"]["input"];
    unitPrice: Scalars["Int"]["input"];
};

export type InvoiceMetadataUpdated = {
    invoiceId: Scalars["ID"]["output"];
    metadata: Scalars["JSONString"]["output"];
};

export type InvoicePaid = {
    invoice: Invoice;
};

export type InvoicePromo = {
    code: Scalars["String"]["output"];
    discountedAmount: Scalars["Int"]["output"];
};

export type InvoiceStatistics = {
    invoicePerYear: Scalars["Int"]["output"];
};

export type InvoiceStatus = {
    cancelled?: InputMaybe<Scalars["Boolean"]["input"]>;
    paid?: InputMaybe<Scalars["Boolean"]["input"]>;
    unpaid?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type InvoiceUncancelled = {
    invoice: Invoice;
};

export type JoinGameRequest = {
    playerDetails: GamePlayerInput;
    uid: Scalars["ID"]["input"];
};

export type JoinGameResponse =
    | AccessError
    | GameJoined
    | NoPlayerSlotError
    | NotFoundError;

export type KiplepaySessionInitiated = {
    backendUrl: Scalars["String"]["output"];
    externalId: Scalars["ID"]["output"];
    merchantId: Scalars["ID"]["output"];
    paymentSessionCreated: Scalars["DateTime"]["output"];
    paymentSessionId: Scalars["ID"]["output"];
    requestMerchantHashvalue: Scalars["String"]["output"];
    responseUrl: Scalars["String"]["output"];
};

export type KiplepaySettlementDetailsResult = {
    jsonData: Scalars["JSONString"]["output"];
};

export type KiplepaySettlementNotification = {
    created: Scalars["DateTime"]["output"];
    notificationData: Scalars["JSONString"]["output"];
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type KonsolCancelInvoiceCompleted = {
    state: KonsolCancelInvoiceState;
};

export type KonsolCancelInvoiceState = {
    bookingIds?: Maybe<Array<Scalars["ID"]["output"]>>;
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    id: Scalars["ID"]["output"];
    invoiceId: Scalars["ID"]["output"];
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
};

export type KonsolCheckoutInvalidBooking = {
    message: Scalars["String"]["output"];
};

export type KonsolCheckoutNotCancellable = {
    message: Scalars["String"]["output"];
};

export type KonsolCheckoutStarted = {
    state: KonsolCheckoutState;
};

export type KonsolCheckoutState = {
    bookingIds: Array<Scalars["ID"]["output"]>;
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    capturePaymentState?: Maybe<CapturePaymentState>;
    capturePaymentWorkflowId?: Maybe<Scalars["ID"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    expired?: Maybe<Scalars["Time"]["output"]>;
    id: Scalars["ID"]["output"];
    invoiceId?: Maybe<Scalars["ID"]["output"]>;
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
    userId: Scalars["ID"]["output"];
};

export type KonsolCreateInvoiceCompleted = {
    state: KonsolCreateInvoiceState;
};

export type KonsolCreateInvoiceState = {
    bookingIds: Array<Scalars["ID"]["output"]>;
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    id: Scalars["ID"]["output"];
    invoiceId?: Maybe<Scalars["ID"]["output"]>;
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
};

export type KonsolPayInvoiceCompleted = {
    state: KonsolPayInvoiceState;
};

export type KonsolPayInvoiceNotCancellable = {
    message: Scalars["String"]["output"];
};

export type KonsolPayInvoiceState = {
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    capturePaymentWorkflowId: Scalars["ID"]["output"];
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    expired?: Maybe<Scalars["Time"]["output"]>;
    id: Scalars["ID"]["output"];
    invoiceId: Scalars["ID"]["output"];
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
};

export type LhdnInvoiceSubmitted = {
    acceptedDocuments: Array<AcceptedDocument>;
    rejectedDocuments: Array<RejectedDocument>;
    responseData: Scalars["String"]["output"];
    submissionUid: Scalars["String"]["output"];
};

export type LhdnSubmission = {
    created: Scalars["DateTime"]["output"];
    data: Scalars["String"]["output"];
    invoiceId: Scalars["ID"]["output"];
    status: LhdnSubmissionStatus;
    submissionId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

/** An enumeration. */
export type LhdnSubmissionStatus =
    | "CREATED"
    | "INPROGRESS"
    | "INVALID"
    | "PARTIALLYVALID"
    | "VALID";

export type LeaveGameRequest = {
    uid: Scalars["ID"]["input"];
};

export type LeaveGameResponse = AccessError | GameLeft | NotFoundError;

export type Light = {
    boardId: Scalars["ID"]["output"];
    cooldown: Scalars["Int"]["output"];
    created: Scalars["DateTime"]["output"];
    currentCommand?: Maybe<Command>;
    disableToggling: Scalars["Boolean"]["output"];
    isUserActivated: Scalars["Boolean"]["output"];
    metadata: Scalars["JSONString"]["output"];
    name: Scalars["String"]["output"];
    relayId: Scalars["ID"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    triggers: Array<Trigger>;
    uid: Scalars["ID"]["output"];
    warmup: Scalars["Int"]["output"];
};

export type LightCreated = {
    light: Light;
};

export type LightDurationSet = {
    light: Light;
};

export type LightEventsActivated = {
    groupActivations?: Maybe<Array<GroupActivation>>;
};

export type LightEventsDeactivated = {
    groupActivations?: Maybe<Array<GroupActivation>>;
};

/** An enumeration. */
export type LightState = "OFF" | "ON";

export type LightUpdated = {
    light: Light;
};

export type LocationArchived = {
    location: SearchLocation;
};

export type LocationCreated = {
    location: SearchLocation;
};

export type LocationEdited = {
    location: SearchLocation;
};

export type LocationFilter = {
    byLatitude?: InputMaybe<Scalars["Float"]["input"]>;
    byLongitude?: InputMaybe<Scalars["Float"]["input"]>;
};

export type LocationInput = {
    tenantId: Scalars["ID"]["input"];
};

export type LogCourtsiteCheckoutCustomEventRequest = {
    checkoutId: Scalars["ID"]["input"];
    eventName: Scalars["String"]["input"];
};

export type LogCourtsiteCheckoutCustomEventResponse =
    | CourtsiteCheckoutCustomEventLogged
    | WorkflowCancelled
    | WorkflowNotFound;

export type MakeBookingsRequest = {
    bookingSource?: InputMaybe<BookingSource>;
    guest?: InputMaybe<BookingGuestRequest>;
    ignoreServiceLimits?: InputMaybe<Scalars["Boolean"]["input"]>;
    isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
    serviceId?: InputMaybe<Scalars["ID"]["input"]>;
    slots: Array<BookingSlotRequest>;
    tenantId: Scalars["ID"]["input"];
};

export type MakeBookingsResponse =
    | AccessError
    | BookingsMade
    | BookingsUnavailable
    | InternalError;

export type MandatoryUserProfileSet = {
    user: UserProfile;
};

export type MastercardSessionInitiated = {
    externalId: Scalars["ID"]["output"];
    mastercardSessionId: Scalars["String"]["output"];
    paymentSessionCreated: Scalars["DateTime"]["output"];
    paymentSessionId: Scalars["ID"]["output"];
    successIndicator?: Maybe<Scalars["String"]["output"]>;
};

export type Member = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    capabilities: Array<Scalars["String"]["output"]>;
    fullName: Scalars["String"]["output"];
    joined: Scalars["DateTime"]["output"];
    metadata: Scalars["JSONString"]["output"];
    uid: Scalars["ID"]["output"];
};

export type MemberAlreadyAddedError = {
    message: Scalars["String"]["output"];
};

export type MemberAlreadyArchivedError = {
    message: Scalars["String"]["output"];
};

export type MemberArchived = {
    memberId: Scalars["ID"]["output"];
};

export type MemberCapabilitiesSet = {
    member: Member;
};

/** An enumeration. */
export type MemberRole = "ADMIN" | "EMPLOYEE";

export type MemberRoleSet = {
    memberId: Scalars["ID"]["output"];
    role: Scalars["String"]["output"];
};

export type MemberRoleSetResponse =
    | AccessError
    | CannotModifySelfError
    | MemberAlreadyArchivedError
    | MemberRoleSet
    | NotFoundError;

export type MetabaseDashboardType =
    | "AUDIT"
    | "CUSTOMER_TREND"
    | "CUSTOMER_TREND_WITH_CATEGORY"
    | "DAILY_UTILIZATION"
    | "RESELLER_DAILY_UTILIZATION"
    | "RESELLER_REVENUE"
    | "RESELLER_UTILIZATION"
    | "REVENUE"
    | "UTILIZATION";

export type MissingPriceRules = {
    message: Scalars["String"]["output"];
};

export type MissingService = {
    message: Scalars["String"]["output"];
};

export type ModificationInvalidError = {
    invalidBookings: Array<InvalidBooking>;
};

export type ModifyAddOnRequest = {
    addOnId: Scalars["ID"]["input"];
    maxQuantity?: InputMaybe<Scalars["Int"]["input"]>;
    name: Scalars["String"]["input"];
    price: Scalars["Int"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ModifyAddOnResponse =
    | AccessError
    | AddOnAlreadyArchived
    | AddOnModified
    | NotFoundError;

export type ModifyBookingGuestRequest = {
    bookingId: Scalars["ID"]["input"];
    guestId: Scalars["ID"]["input"];
    newEmail?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    newGuestName: Scalars["String"]["input"];
    newPhoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type ModifyBookingGuestResponse =
    | AccessError
    | BookingGuestModified
    | NotFoundError;

export type ModifyBookingsRequest = {
    bookingGroupModifications?: InputMaybe<Array<BookingGroupModification>>;
    tenantId: Scalars["ID"]["input"];
};

export type ModifyBookingsResponse =
    | AccessError
    | BookingsModified
    | ModificationInvalidError;

export type ModifyCancelledReasonRequest = {
    bookingIds: Array<Scalars["ID"]["input"]>;
    cancelledReason: CancelledReason;
    tenantId: Scalars["ID"]["input"];
};

export type ModifyCancelledReasonResponse =
    | AccessError
    | BookingCancelledReasonModfied
    | BookingNotCancelledError
    | NotFoundError;

export type ModifyInvoiceItemsRequest = {
    invoiceId: Scalars["ID"]["input"];
    items: Array<CreateInvoiceItemRequest>;
    tenantId: Scalars["ID"]["input"];
};

export type ModifyInvoiceItemsResponse =
    | AccessError
    | InvoiceAlreadyPaidError
    | InvoiceItemsModified
    | NotFoundError;

export type Mutation = {
    activateLightEvents?: Maybe<ActivateLightEventsResponse>;
    activatePromoCode: ActivatePromoCodeResponse;
    addAssociatedUser: AddAssociatedUserResponse;
    addBookingGuest: AddBookingGuestResponse;
    addBookingGuestToBookings: AddBookingGuestToBookingsResponse;
    addMemberToOrganisation: AddMemberToOrganisationResponse;
    addMemberToOrganisationWithoutFirebase: AddMemberToOrganisationWithoutFirebaseResponse;
    addPaymentMethod: AddPaymentMethodResponse;
    addServiceResource: AddServiceResourceResponse;
    approveOrganisation: ApproveOrganisationResponse;
    archiveAddOn: ArchiveAddOnResponse;
    archiveCustomer: ArchiveCustomerResponse;
    archiveEvents?: Maybe<ArchiveEventsResponse>;
    archiveGame: ArchiveGameResponse;
    archiveLocation: ArchiveLocationResponse;
    archiveMember: ArchiveMemberResponse;
    archivePaymentMethod: ArchivePaymentMethodResponse;
    archivePromoCodeV2: ArchivePromoCodeResponseV2;
    archiveResource: ArchiveResourceResponse;
    archiveResourceLinks: ArchiveResourceLinksResponse;
    archiveResourceType: ArchiveResourceTypeResponse;
    archiveSchedule: ArchiveScheduleResponse;
    archiveService?: Maybe<ArchiveServiceResponse>;
    archiveServiceResource: ArchiveServiceResourceResponse;
    archiveSettlement: ArchiveSettlementResponse;
    archiveTag: ArchiveTagResponse;
    archiveTrigger?: Maybe<ArchiveTriggerResponse>;
    assignServiceToCategory: AssignServiceToCategoryResponse;
    cancelBooking: CancelBookingResponse;
    cancelBookings: CancelBookingsResponse;
    cancelCapturePaymentWorkflow: CancelCapturePaymentWorkflowResponse;
    cancelCourtsiteCheckout: CancelCourtsiteCheckoutResponse;
    cancelInvoice: CancelInvoiceResponse;
    cancelKonsolCheckout: CancelKonsolCheckoutResponse;
    cancelKonsolPayInvoice: CancelKonsolPayInvoiceResponse;
    cancelPayment: CancelPaymentResponse;
    cancelPaymentSession: CancelPaymentSessionResponse;
    changeResourceType: ChangeResourceTypeResponse;
    checkPaymentSession: CheckPaymentSessionResponse;
    confirmBooking: ConfirmBookingResponse;
    confirmBookings: ConfirmBookingsResponse;
    confirmCapturePayment: ConfirmCapturePaymentResponse;
    confirmPaymentSession: ConfirmPaymentSessionResponse;
    createAddOn: CreateAddOnResponse;
    createCategory: CreateCategoryResponse;
    createCustomer: CreateCustomerResponse;
    createCustomers: CreateCustomersResponse;
    createDailyService?: Maybe<CreateDailyServiceResponse>;
    createGame: CreateGameResponse;
    createInvoice: CreateInvoiceResponse;
    createLight?: Maybe<CreateLightResponse>;
    createLocation: CreateLocationResponse;
    createOrganisation: CreateOrganisationResponse;
    createOverride?: Maybe<CreateOverrideResponse>;
    createPriceRulesetsForServices?: Maybe<CreateRulesetsForServicesResponse>;
    createPromoCode: CreatePromoCodeResponse;
    createPromoCodeV2: CreatePromoCodeResponseV2;
    createPsmGrpCashSalesTx: CreatePsmgrpCashSalesTxResponse;
    createResource: CreateResourceResponse;
    createResourceLinks: CreateResourceLinksResponse;
    createResourceType: CreateResourceTypeResponse;
    createSchedule: CreateScheduleResponse;
    createService: CreateServiceResponse;
    createSettlement: CreateSettlementResponse;
    createSettlementConfiguration: CreateSettlementConfigurationResponse;
    createSettlementConfigurationWithApproval: CreateSettlementConfigurationWithApprovalResponse;
    createTag: CreateTagResponse;
    createTrigger?: Maybe<CreateTriggerResponse>;
    createUserProfile: CreateUserProfileResponse;
    createUserProfileManual: CreateUserProfileManualResponse;
    deactivateLightEvents?: Maybe<DeactivateLightEventsResponse>;
    editCustomerInfo: EditCustomerInfoResponse;
    editGame: EditGameResponse;
    editGameOrganizer: EditGameOrganizerResponse;
    editLocation: EditLocationResponse;
    editPaymentMethodAvailability: EditPaymentMethodAvailabilityResponse;
    editPaymentMethodMetadata: EditPaymentMethodMetadataResponse;
    editPaymentMethodName: EditPaymentMethodNameResponse;
    editPromoCodeV2: EditPromoCodeResponseV2;
    editSettlementConfiguration: EditSettlementConfigurationResponse;
    editSettlementConfigurationWithApproval: EditSettlementConfigurationWithApprovalResponse;
    editTag: EditTagResponse;
    forceRetryCapturePayment: ForceRetryCapturePaymentResponse;
    generateSettlementDownloadLink: GenerateSettlementDownloadLinkResponse;
    initiatePaymentSession: InitiatePaymentSessionResponse;
    joinGame: JoinGameResponse;
    leaveGame: LeaveGameResponse;
    logCourtsiteCheckoutCustomEvent: LogCourtsiteCheckoutCustomEventResponse;
    makeBookings: MakeBookingsResponse;
    modifyAddOn: ModifyAddOnResponse;
    modifyBookingGuest: ModifyBookingGuestResponse;
    modifyBookings: ModifyBookingsResponse;
    modifyCancelledReason: ModifyCancelledReasonResponse;
    modifyInvoiceItems: ModifyInvoiceItemsResponse;
    payInvoice: PayInvoiceResponse;
    provideCapturePaymentProviderDetails: ProvideCapturePaymentProviderDetailsResponse;
    provideCourtsiteCheckoutPaymentDetails: ProvideCourtsiteCheckoutPaymentDetailsResponse;
    provideCourtsiteReschedulePaymentDetails: ProvideCourtsiteReschedulePaymentDetailsResponse;
    removeBookingGuest: RemoveBookingGuestResponse;
    renameCategory: RenameCategoryResponse;
    renameResource: RenameResourceResponse;
    renameService: RenameServiceResponse;
    rescheduleBooking: RescheduleBookingResponse;
    reviewSettlementConfigurationWithApproval: ReviewSettlementConfigurationWithApprovalResponse;
    reviveBookings: ReviveBookingsResponse;
    setAddOnIsInStock: SetAddOnIsInStockResponse;
    setDailyServiceTimes: SetDailyServiceTimesResponse;
    setLightDuration?: Maybe<SetLightDurationResponse>;
    setMandatoryUserProfile: SetMandatoryUserProfileResponse;
    setMemberCapabilities: SetMemberCapabilitiesResponse;
    setMemberRole: MemberRoleSetResponse;
    setOrganisationIsCourtsitePartner: SetOrganisationIsCourtsitePartnerResponse;
    setOrganisationWeightages: SetOrganisationWeightagesResponse;
    setPromoCodeCriteria: SetPromoCodeCriteriaResponse;
    setPromoCodeDiscount: SetPromoCodeDiscountResponse;
    setServiceBookingLimits: SetServiceBookingLimitsResponse;
    setServiceDurationOptions: SetServiceDurationOptionsResponse;
    setServiceTags: SetServiceTagsResponse;
    setUserProfile: SetUserProfileResponse;
    setUserProfileManual: SetUserProfileManualResponse;
    startCancelBookingWorkflow: StartCancelBookingWorkflowResponse;
    startCancelBookings: StartCancelBookingsResponse;
    startCancelPaymentWorkflow: StartCancelPaymentWorkflowResponse;
    startCourtsiteCheckout: StartCourtsiteCheckoutResponse;
    startCourtsiteGameWorkflow: StartCourtsiteGameWorkflowResponse;
    startCourtsiteReschedule: StartCourtsiteRescheduleResponse;
    startGeneratePsmGrpCashSaleTx: StartGeneratePsmGrpCashSaleTxResponse;
    startGenerateSettlementReport: GenerateSettlementReportResponse;
    startKonsolCancelInvoice: StartKonsolCancelInvoiceResponse;
    startKonsolCheckout: StartKonsolCheckoutResponse;
    startKonsolCreateInvoice: StartKonsolCreateInvoiceResponse;
    startKonsolPayInvoice: StartKonsolPayInvoiceResponse;
    startRecoverPaymentSession: StartRecoverPaymentSessionResponse;
    startRescheduleRecurringBookings: StartRescheduleRecurringBookingsResponse;
    startSettlementWatcher: StartSettlementWatcherResponse;
    startSubmitLHDNEInvoices: StartSubmitLhdneInvoicesResponse;
    submitLhdnInvoice: SubmitLhdnInvoiceResponse;
    syncEvents?: Maybe<SyncEventsResponse>;
    syncUserProfileToFirebase: SyncUserProfileToFirebaseResponse;
    unapproveOrganisation: UnapproveOrganisationResponse;
    uncancelInvoice: UncancelInvoiceResponse;
    uncancelPayment: UncancelPaymentResponse;
    unconfirmBooking: UnconfirmBookingResponse;
    updateBookingMetadata: UpdateBookingMetadataResponse;
    updateBookingReferenceId: UpdateBookingReferenceIdResponse;
    updateBookingsReferenceId: UpdateBookingsReferenceIdResponse;
    updateCategoryMetadata: UpdateCategoryMetadataResponse;
    updateCustomerMetadata: UpdateCustomerMetadataResponse;
    updateFixedDurationOptions?: Maybe<UpdateFixedDurationOptionsResponse>;
    updateInvoiceCourtsiteOwnership: UpdateInvoiceCourtsiteOwnershipResponse;
    updateInvoiceItemRelatedEntity: UpdateInvoiceItemRelatedEntityResponse;
    updateInvoiceMetadata: UpdateInvoiceMetadataResponse;
    updateLight?: Maybe<UpdateLightResponse>;
    updateMyProfileEmail: UpdateMyProfileEmailResponse;
    updateMyProfilePhoneNumber: UpdateMyProfilePhoneNumberResponse;
    updateOrganisation: UpdateOrganisationResponse;
    updateOverride?: Maybe<UpdateOverrideResponse>;
    updateResourceTypesMetadata: UpdateResourceTypesMetadataResponse;
    updateSchedule: UpdateScheduleResponse;
    updateServiceMetadata: UpdateServiceMetadataResponse;
    updateServiceVisibility: UpdateServiceVisibilityResponse;
    updateUser: UpdateUserResponse;
};

export type MutationActivateLightEventsArgs = {
    request: ActivateLightEventsRequest;
};

export type MutationActivatePromoCodeArgs = {
    request: ActivatePromoCodeRequest;
};

export type MutationAddAssociatedUserArgs = {
    request: AddAssociatedUserRequest;
};

export type MutationAddBookingGuestArgs = {
    request: AddBookingGuestRequest;
};

export type MutationAddBookingGuestToBookingsArgs = {
    request: AddBookingGuestToBookingsRequest;
};

export type MutationAddMemberToOrganisationArgs = {
    request: AddMemberToOrganisationRequest;
};

export type MutationAddMemberToOrganisationWithoutFirebaseArgs = {
    request: AddMemberToOrganisationWithoutFirebaseRequest;
};

export type MutationAddPaymentMethodArgs = {
    request: AddPaymentMethodRequest;
};

export type MutationAddServiceResourceArgs = {
    request: AddServiceResourceRequest;
};

export type MutationApproveOrganisationArgs = {
    request: ApproveOrganisationRequest;
};

export type MutationArchiveAddOnArgs = {
    request: ArchiveAddOnRequest;
};

export type MutationArchiveCustomerArgs = {
    request: ArchiveCustomerRequest;
};

export type MutationArchiveEventsArgs = {
    request: ArchiveEventsRequest;
};

export type MutationArchiveGameArgs = {
    request: ArchiveGameRequest;
};

export type MutationArchiveLocationArgs = {
    request: ArchiveLocationRequest;
};

export type MutationArchiveMemberArgs = {
    request: ArchiveMemberRequest;
};

export type MutationArchivePaymentMethodArgs = {
    request: ArchivePaymentMethodRequest;
};

export type MutationArchivePromoCodeV2Args = {
    request: ArchivePromoCodeRequestV2;
};

export type MutationArchiveResourceArgs = {
    request: ArchiveResourceRequest;
};

export type MutationArchiveResourceLinksArgs = {
    request: ArchiveResourceLinksRequest;
};

export type MutationArchiveResourceTypeArgs = {
    request: ArchiveResourceTypeRequest;
};

export type MutationArchiveScheduleArgs = {
    request: ArchiveScheduleRequest;
};

export type MutationArchiveServiceArgs = {
    request: ArchiveServiceRequest;
};

export type MutationArchiveServiceResourceArgs = {
    request: ArchiveServiceResourceRequest;
};

export type MutationArchiveSettlementArgs = {
    request: ArchiveSettlementRequest;
};

export type MutationArchiveTagArgs = {
    request: ArchiveTagRequest;
};

export type MutationArchiveTriggerArgs = {
    request: ArchiveTriggerRequest;
};

export type MutationAssignServiceToCategoryArgs = {
    request: AssignServiceToCategoryRequest;
};

export type MutationCancelBookingArgs = {
    request: CancelBookingRequest;
};

export type MutationCancelBookingsArgs = {
    request: CancelBookingsRequest;
};

export type MutationCancelCapturePaymentWorkflowArgs = {
    request: CancelCapturePaymentWorkflowRequest;
};

export type MutationCancelCourtsiteCheckoutArgs = {
    request: CancelCourtsiteCheckoutRequest;
};

export type MutationCancelInvoiceArgs = {
    request: CancelInvoiceRequest;
};

export type MutationCancelKonsolCheckoutArgs = {
    request: CancelKonsolCheckoutRequest;
};

export type MutationCancelKonsolPayInvoiceArgs = {
    request: CancelKonsolPayInvoiceRequest;
};

export type MutationCancelPaymentArgs = {
    request: CancelPaymentRequest;
};

export type MutationCancelPaymentSessionArgs = {
    request: CancelPaymentSessionRequest;
};

export type MutationChangeResourceTypeArgs = {
    request: ChangeResourceTypeRequest;
};

export type MutationCheckPaymentSessionArgs = {
    request: CheckPaymentSessionRequest;
};

export type MutationConfirmBookingArgs = {
    request: ConfirmBookingRequest;
};

export type MutationConfirmBookingsArgs = {
    request: ConfirmBookingsRequest;
};

export type MutationConfirmCapturePaymentArgs = {
    request: ConfirmCapturePaymentRequest;
};

export type MutationConfirmPaymentSessionArgs = {
    request: ConfirmPaymentSessionRequest;
};

export type MutationCreateAddOnArgs = {
    request: CreateAddOnRequest;
};

export type MutationCreateCategoryArgs = {
    request: CreateCategoryRequest;
};

export type MutationCreateCustomerArgs = {
    request: CreateCustomerRequest;
};

export type MutationCreateCustomersArgs = {
    request: CreateCustomersRequest;
};

export type MutationCreateDailyServiceArgs = {
    request: CreateDailyServiceRequest;
};

export type MutationCreateGameArgs = {
    request: CreateGameRequest;
};

export type MutationCreateInvoiceArgs = {
    request: CreateInvoiceRequest;
};

export type MutationCreateLightArgs = {
    request: CreateLightRequest;
};

export type MutationCreateLocationArgs = {
    request: CreateLocationRequest;
};

export type MutationCreateOrganisationArgs = {
    request: CreateOrganisationRequest;
};

export type MutationCreateOverrideArgs = {
    request: CreateOverrideRequest;
};

export type MutationCreatePriceRulesetsForServicesArgs = {
    request: CreatePriceRulesetsForServicesRequest;
};

export type MutationCreatePromoCodeArgs = {
    request: CreatePromoCodeRequest;
};

export type MutationCreatePromoCodeV2Args = {
    request: CreatePromoCodeRequestV2;
};

export type MutationCreatePsmGrpCashSalesTxArgs = {
    request: CreatePsmgrpCashSalesTxRequest;
};

export type MutationCreateResourceArgs = {
    request: CreateResourceRequest;
};

export type MutationCreateResourceLinksArgs = {
    request: CreateResourceLinksRequest;
};

export type MutationCreateResourceTypeArgs = {
    request: CreateResourceTypeRequest;
};

export type MutationCreateScheduleArgs = {
    request: CreateScheduleRequest;
};

export type MutationCreateServiceArgs = {
    request: CreateServiceRequest;
};

export type MutationCreateSettlementArgs = {
    request: CreateSettlementRequest;
};

export type MutationCreateSettlementConfigurationArgs = {
    request: CreateSettlementConfigurationRequest;
};

export type MutationCreateSettlementConfigurationWithApprovalArgs = {
    request: CreateSettlementConfigurationWithApprovalRequest;
};

export type MutationCreateTagArgs = {
    request: CreateTagRequest;
};

export type MutationCreateTriggerArgs = {
    request: CreateTriggerRequest;
};

export type MutationCreateUserProfileArgs = {
    request: CreateUserProfileRequest;
};

export type MutationCreateUserProfileManualArgs = {
    request: CreateUserProfileManualRequest;
};

export type MutationDeactivateLightEventsArgs = {
    request: DeactivateLightEventsRequest;
};

export type MutationEditCustomerInfoArgs = {
    request: EditCustomerInfoRequest;
};

export type MutationEditGameArgs = {
    request: EditGameRequest;
};

export type MutationEditGameOrganizerArgs = {
    request: EditGameOrganizerRequest;
};

export type MutationEditLocationArgs = {
    request: EditLocationRequest;
};

export type MutationEditPaymentMethodAvailabilityArgs = {
    request: EditPaymentMethodAvailabilityRequest;
};

export type MutationEditPaymentMethodMetadataArgs = {
    request: EditPaymentMethodMetadataRequest;
};

export type MutationEditPaymentMethodNameArgs = {
    request: EditPaymentMethodNameRequest;
};

export type MutationEditPromoCodeV2Args = {
    request: EditPromoCodeRequestV2;
};

export type MutationEditSettlementConfigurationArgs = {
    request: EditSettlementConfigurationRequest;
};

export type MutationEditSettlementConfigurationWithApprovalArgs = {
    request: EditSettlementConfigurationWithApprovalRequest;
};

export type MutationEditTagArgs = {
    request: EditTagRequest;
};

export type MutationForceRetryCapturePaymentArgs = {
    request: ForceRetryCapturePaymentRequest;
};

export type MutationGenerateSettlementDownloadLinkArgs = {
    request: GenerateSettlementDownloadLinkRequest;
};

export type MutationInitiatePaymentSessionArgs = {
    request: InitiatePaymentSessionRequest;
};

export type MutationJoinGameArgs = {
    request: JoinGameRequest;
};

export type MutationLeaveGameArgs = {
    request: LeaveGameRequest;
};

export type MutationLogCourtsiteCheckoutCustomEventArgs = {
    request: LogCourtsiteCheckoutCustomEventRequest;
};

export type MutationMakeBookingsArgs = {
    request: MakeBookingsRequest;
};

export type MutationModifyAddOnArgs = {
    request: ModifyAddOnRequest;
};

export type MutationModifyBookingGuestArgs = {
    request: ModifyBookingGuestRequest;
};

export type MutationModifyBookingsArgs = {
    request: ModifyBookingsRequest;
};

export type MutationModifyCancelledReasonArgs = {
    request: ModifyCancelledReasonRequest;
};

export type MutationModifyInvoiceItemsArgs = {
    request: ModifyInvoiceItemsRequest;
};

export type MutationPayInvoiceArgs = {
    request: PayInvoiceRequest;
};

export type MutationProvideCapturePaymentProviderDetailsArgs = {
    request: ProvideCapturePaymentProviderDetailsRequest;
};

export type MutationProvideCourtsiteCheckoutPaymentDetailsArgs = {
    request: ProvideCourtsiteCheckoutPaymentDetailsRequest;
};

export type MutationProvideCourtsiteReschedulePaymentDetailsArgs = {
    request: ProvideCourtsiteReschedulePaymentDetailsRequest;
};

export type MutationRemoveBookingGuestArgs = {
    request: RemoveBookingGuestRequest;
};

export type MutationRenameCategoryArgs = {
    request: RenameCategoryRequest;
};

export type MutationRenameResourceArgs = {
    request: RenameResourceRequest;
};

export type MutationRenameServiceArgs = {
    request: RenameServiceRequest;
};

export type MutationRescheduleBookingArgs = {
    request: RescheduleBookingRequest;
};

export type MutationReviewSettlementConfigurationWithApprovalArgs = {
    request: ReviewSettlementConfigurationWithApprovalRequest;
};

export type MutationReviveBookingsArgs = {
    request: ReviveBookingsRequest;
};

export type MutationSetAddOnIsInStockArgs = {
    request: SetAddOnIsInStockRequest;
};

export type MutationSetDailyServiceTimesArgs = {
    request: SetDailyServiceTimesRequest;
};

export type MutationSetLightDurationArgs = {
    request: SetLightDurationRequest;
};

export type MutationSetMandatoryUserProfileArgs = {
    request: SetMandatoryUserProfileRequest;
};

export type MutationSetMemberCapabilitiesArgs = {
    request: SetMemberCapabilitiesRequest;
};

export type MutationSetMemberRoleArgs = {
    request: SetMemberRoleRequest;
};

export type MutationSetOrganisationIsCourtsitePartnerArgs = {
    request: SetOrganisationIsCourtsitePartnerRequest;
};

export type MutationSetOrganisationWeightagesArgs = {
    request: SetOrganisationWeightagesRequest;
};

export type MutationSetPromoCodeCriteriaArgs = {
    request: SetPromoCodeCriteriaRequest;
};

export type MutationSetPromoCodeDiscountArgs = {
    request: SetPromoCodeDiscountRequest;
};

export type MutationSetServiceBookingLimitsArgs = {
    request: SetServiceBookingLimitsRequest;
};

export type MutationSetServiceDurationOptionsArgs = {
    request: SetServiceDurationOptionsRequest;
};

export type MutationSetServiceTagsArgs = {
    request: SetServiceTagsRequest;
};

export type MutationSetUserProfileArgs = {
    request: SetUserProfileRequest;
};

export type MutationSetUserProfileManualArgs = {
    request: SetUserProfileManualRequest;
};

export type MutationStartCancelBookingWorkflowArgs = {
    request: StartCancelBookingWorkflowRequest;
};

export type MutationStartCancelBookingsArgs = {
    request: StartCancelBookingsRequest;
};

export type MutationStartCancelPaymentWorkflowArgs = {
    request: StartCancelPaymentWorkflowRequest;
};

export type MutationStartCourtsiteCheckoutArgs = {
    request: StartCourtsiteCheckoutRequest;
};

export type MutationStartCourtsiteGameWorkflowArgs = {
    request: StartCourtsiteGameWorkflowRequest;
};

export type MutationStartCourtsiteRescheduleArgs = {
    request: StartCourtsiteRescheduleRequest;
};

export type MutationStartGeneratePsmGrpCashSaleTxArgs = {
    request: StartGeneratePsmGrpCashSaleTxRequest;
};

export type MutationStartGenerateSettlementReportArgs = {
    request: GenerateSettlementReportRequest;
};

export type MutationStartKonsolCancelInvoiceArgs = {
    request: StartKonsolCancelInvoiceRequest;
};

export type MutationStartKonsolCheckoutArgs = {
    request: StartKonsolCheckoutRequest;
};

export type MutationStartKonsolCreateInvoiceArgs = {
    request: StartKonsolCreateInvoiceRequest;
};

export type MutationStartKonsolPayInvoiceArgs = {
    request: StartKonsolPayInvoiceRequest;
};

export type MutationStartRecoverPaymentSessionArgs = {
    request: StartRecoverPaymentSessionRequest;
};

export type MutationStartRescheduleRecurringBookingsArgs = {
    request: StartRescheduleRecurringBookingsRequest;
};

export type MutationStartSettlementWatcherArgs = {
    request: StartSettlementWatcherRequest;
};

export type MutationStartSubmitLhdneInvoicesArgs = {
    request: StartSubmitLhdneInvoicesRequest;
};

export type MutationSubmitLhdnInvoiceArgs = {
    request: SubmitLhdnInvoiceRequest;
};

export type MutationSyncEventsArgs = {
    request: SyncEventsRequest;
};

export type MutationSyncUserProfileToFirebaseArgs = {
    firebaseId: Scalars["ID"]["input"];
};

export type MutationUnapproveOrganisationArgs = {
    request: UnapproveOrganisationRequest;
};

export type MutationUncancelInvoiceArgs = {
    request: UncancelInvoiceRequest;
};

export type MutationUncancelPaymentArgs = {
    request: UncancelPaymentRequest;
};

export type MutationUnconfirmBookingArgs = {
    request: UnconfirmBookingRequest;
};

export type MutationUpdateBookingMetadataArgs = {
    request: UpdateBookingMetadataRequest;
};

export type MutationUpdateBookingReferenceIdArgs = {
    request: UpdateBookingReferenceIdRequest;
};

export type MutationUpdateBookingsReferenceIdArgs = {
    request: UpdateBookingsReferenceIdRequest;
};

export type MutationUpdateCategoryMetadataArgs = {
    request: UpdateCategoryMetadataRequest;
};

export type MutationUpdateCustomerMetadataArgs = {
    request: UpdateCustomerMetadataRequest;
};

export type MutationUpdateFixedDurationOptionsArgs = {
    request: UpdateFixedDurationOptionsRequest;
};

export type MutationUpdateInvoiceCourtsiteOwnershipArgs = {
    request: UpdateInvoiceCourtsiteOwnershipRequest;
};

export type MutationUpdateInvoiceItemRelatedEntityArgs = {
    request: UpdateInvoiceItemRelatedEntityRequest;
};

export type MutationUpdateInvoiceMetadataArgs = {
    request: UpdateInvoiceMetadataRequest;
};

export type MutationUpdateLightArgs = {
    request: UpdateLightRequest;
};

export type MutationUpdateMyProfileEmailArgs = {
    request: UpdateMyProfileEmailRequest;
};

export type MutationUpdateMyProfilePhoneNumberArgs = {
    request: UpdateMyProfilePhoneNumberRequest;
};

export type MutationUpdateOrganisationArgs = {
    request: UpdateOrganisationRequest;
};

export type MutationUpdateOverrideArgs = {
    request: UpdateOverrideRequest;
};

export type MutationUpdateResourceTypesMetadataArgs = {
    request: UpdateResourceTypesMetadataRequests;
};

export type MutationUpdateScheduleArgs = {
    request: UpdatedScheduleRequest;
};

export type MutationUpdateServiceMetadataArgs = {
    request: UpdateServiceMetadataRequest;
};

export type MutationUpdateServiceVisibilityArgs = {
    request: UpdateServiceVisibilityRequest;
};

export type MutationUpdateUserArgs = {
    request: UpdateUserRequest;
};

export type MyBookingsFilter = {
    byCancelReason?: InputMaybe<CancelledReason>;
    byEndDateAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
    byEndDateBefore?: InputMaybe<Scalars["DateTime"]["input"]>;
    byStartDate?: InputMaybe<DateRangeFilter>;
    byStartDateAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
    byStartDateBefore?: InputMaybe<Scalars["DateTime"]["input"]>;
    byStatus?: InputMaybe<BookingStatus>;
};

export type MyGroupedBookingsFilter = {
    byCategoryId?: InputMaybe<Scalars["ID"]["input"]>;
    byEndDateAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
    byEndDateBefore?: InputMaybe<Scalars["DateTime"]["input"]>;
    byStartDateAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
    byStartDateBefore?: InputMaybe<Scalars["DateTime"]["input"]>;
    byStatus?: InputMaybe<BookingStatus>;
};

export type MyInvoiceStatisticFilter = {
    byCreatedAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
    byCreatedBefore?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MyInvoicesFilter = {
    byInvoiceIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    byRelatedEntityId?: InputMaybe<Scalars["String"]["input"]>;
    byRelatedEntityIds?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    byStatus?: InputMaybe<InvoiceStatus>;
};

export type MyInvoicesPagedFilter = {
    byStatus?: InputMaybe<InvoiceStatus>;
};

export type NoPlayerSlotError = {
    message: Scalars["String"]["output"];
};

export type NotFoundError = {
    message: Scalars["String"]["output"];
};

export type Organisation = {
    amenities: Array<Amenities>;
    approved?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    isCourtsitePartner: Scalars["Boolean"]["output"];
    location: Coordinates;
    members: Array<Member>;
    metadata: Scalars["JSONString"]["output"];
    myCapabilities: Array<Scalars["String"]["output"]>;
    name: Scalars["String"]["output"];
    services: Array<Scalars["String"]["output"]>;
    uid: Scalars["ID"]["output"];
    weightage: Scalars["Float"]["output"];
};

export type OrganisationApproved = {
    organisation: Organisation;
};

export type OrganisationConnection = {
    edges: Array<OrganisationEdge>;
    pageInfo: PageInfo;
};

export type OrganisationCreated = {
    organisationId: Scalars["ID"]["output"];
    ownerId: Scalars["ID"]["output"];
};

export type OrganisationEdge = {
    cursor: Scalars["String"]["output"];
    node: Organisation;
};

export type OrganisationIsCourtsitePartnerSet = {
    organisation: Organisation;
};

export type OrganisationMemberAdded = {
    memberId: Scalars["ID"]["output"];
    organisationId: Scalars["ID"]["output"];
};

export type OrganisationSortOrder =
    | "BY_NAME_ASC"
    | "BY_NAME_DESC"
    | "BY_UID_ASC"
    | "BY_UID_DESC"
    | "BY_WEIGHTAGE_ASC"
    | "BY_WEIGHTAGE_DESC";

export type OrganisationUnapproved = {
    organisation: Organisation;
};

export type OrganisationUpdated = {
    organisationId: Scalars["ID"]["output"];
};

export type OrganisationWeightage = {
    organisationId: Scalars["ID"]["output"];
    weightage: Scalars["Float"]["output"];
};

export type OrganisationWeightageInput = {
    organisationId: Scalars["ID"]["input"];
    weightage: Scalars["Float"]["input"];
};

export type OrganisationWeightagesSet = {
    organisationWeightages?: Maybe<Array<OrganisationWeightage>>;
};

export type Organizer = {
    displayPicture?: Maybe<Scalars["String"]["output"]>;
    email?: Maybe<Scalars["EmailAddress"]["output"]>;
    name: Scalars["String"]["output"];
    organizerId: Scalars["ID"]["output"];
    phoneNumber?: Maybe<Scalars["PhoneNumber"]["output"]>;
};

export type Override = Command & {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    end: Scalars["DateTime"]["output"];
    light: Light;
    lightState: LightState;
    metadata: Scalars["JSONString"]["output"];
    start: Scalars["DateTime"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type OverrideCreated = {
    override: Override;
};

export type OverrideUpdated = {
    override: Override;
};

export type PsmgrpCashSalesTxCreated = {
    txId: Scalars["String"]["output"];
};

export type PageInfo = {
    endCursor?: Maybe<Scalars["String"]["output"]>;
    hasNextPage?: Maybe<Scalars["Boolean"]["output"]>;
    hasPreviousPage?: Maybe<Scalars["Boolean"]["output"]>;
    startCursor?: Maybe<Scalars["String"]["output"]>;
};

export type PageInput = {
    after?: InputMaybe<Scalars["ID"]["input"]>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PartyDetails = {
    address: Address;
    email: Scalars["EmailAddress"]["input"];
    idNumber: Scalars["String"]["input"];
    idType: IdType;
    name: Scalars["String"]["input"];
    phoneNumber: Scalars["String"]["input"];
    sstId?: InputMaybe<Scalars["String"]["input"]>;
    tin: Scalars["String"]["input"];
};

export type PayInvoiceRequest = {
    amount: Scalars["Int"]["input"];
    externalReference?: InputMaybe<Scalars["String"]["input"]>;
    idempotentKey?: InputMaybe<Scalars["String"]["input"]>;
    invoiceId: Scalars["ID"]["input"];
    paymentMethodId?: InputMaybe<Scalars["ID"]["input"]>;
    paymentType?: InputMaybe<Scalars["String"]["input"]>;
    tenantId: Scalars["ID"]["input"];
    uncancel?: InputMaybe<Scalars["Boolean"]["input"]>;
    userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type PayInvoiceResponse =
    | AccessError
    | InternalError
    | InvoicePaid
    | NotFoundError;

export type Payment = {
    amount: Scalars["Int"]["output"];
    cancelled?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    externalReference?: Maybe<Scalars["String"]["output"]>;
    idempotentKey: Scalars["String"]["output"];
    invoice: Invoice;
    metadata: Scalars["JSONString"]["output"];
    paymentMethod?: Maybe<PaymentMethod>;
    paymentMethodId?: Maybe<Scalars["String"]["output"]>;
    paymentType?: Maybe<Scalars["String"]["output"]>;
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type PaymentAlreadyCancelled = {
    message: Scalars["String"]["output"];
};

export type PaymentCancelled = {
    invoiceId: Scalars["ID"]["output"];
    paymentId: Scalars["ID"]["output"];
};

export type PaymentFilter = {
    byCreatedDate?: InputMaybe<DateRangeFilter>;
    byInvoiceeName?: InputMaybe<Scalars["String"]["input"]>;
    byPaymentType?: InputMaybe<Scalars["String"]["input"]>;
};

export type PaymentMethod = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    availability: Scalars["Boolean"]["output"];
    created: Scalars["DateTime"]["output"];
    metadata: Scalars["JSONString"]["output"];
    name: Scalars["String"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
    updated?: Maybe<Scalars["DateTime"]["output"]>;
};

export type PaymentMethodAdded = {
    paymentMethod: PaymentMethod;
};

export type PaymentMethodAlreadyArchived = {
    message: Scalars["String"]["output"];
};

export type PaymentMethodArchived = {
    paymentMethod: PaymentMethod;
};

export type PaymentMethodAvailabilityEdited = {
    availability: Scalars["Boolean"]["output"];
    paymentMethodId: Scalars["ID"]["output"];
};

export type PaymentMethodMetadataEdited = {
    metadata: Scalars["JSONString"]["output"];
    paymentMethodId: Scalars["ID"]["output"];
};

export type PaymentMethodNameEdited = {
    name: Scalars["String"]["output"];
    paymentMethodId: Scalars["ID"]["output"];
};

/** An enumeration. */
export type PaymentProvider =
    | "BILLPLZ"
    | "IPAY88"
    | "KIPLEPAY"
    | "MASTERCARD"
    | "RAZORPAY"
    | "SPAY";

export type PaymentSession = {
    amount: Scalars["Int"]["output"];
    created: Scalars["DateTime"]["output"];
    currentState: PaymentState;
    customReferenceId?: Maybe<Scalars["String"]["output"]>;
    description: Scalars["String"]["output"];
    expiryInMinutes: Scalars["Int"]["output"];
    externalId?: Maybe<Scalars["String"]["output"]>;
    isExpired: Scalars["Boolean"]["output"];
    metadata: Scalars["JSONString"]["output"];
    payerEmail?: Maybe<Scalars["EmailAddress"]["output"]>;
    payerName: Scalars["String"]["output"];
    payerPhoneNumber?: Maybe<Scalars["PhoneNumber"]["output"]>;
    provider: PaymentProvider;
    providerPaymentMethod: Scalars["String"]["output"];
    stateTransitions: Array<PaymentStateTransition>;
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
    updated?: Maybe<Scalars["DateTime"]["output"]>;
};

export type PaymentSessionCancelled = {
    message: Scalars["String"]["output"];
};

export type PaymentSessionConfirmed = {
    message: Scalars["String"]["output"];
    paymentSession: PaymentSession;
};

export type PaymentSessionNotCancellable = {
    message: Scalars["String"]["output"];
};

export type PaymentSessionRecovered = {
    state: RecoverPaymentSessionState;
};

export type PaymentSessionUnconfirmed = {
    message: Scalars["String"]["output"];
    paymentSession: PaymentSession;
};

/** An enumeration. */
export type PaymentState =
    | "CANCELLED"
    | "CONFIRMED"
    | "CREATED"
    | "ERROR"
    | "REJECTED"
    | "UNCONFIRMED"
    | "WAITING";

export type PaymentStateTransition = {
    created: Scalars["DateTime"]["output"];
    currentState: PaymentState;
    data: Scalars["String"]["output"];
    metadata: Scalars["JSONString"]["output"];
    previousState?: Maybe<PaymentState>;
    uid: Scalars["ID"]["output"];
};

export type PaymentUncancelled = {
    invoice: Invoice;
};

export type PlayerAddedEvent = GameEvent & {
    created: Scalars["DateTime"]["output"];
    player: GamePlayer;
    uid: Scalars["ID"]["output"];
};

export type PlayerRemovedEvent = GameEvent & {
    created: Scalars["DateTime"]["output"];
    playerId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type PriceRate = {
    minimumDurationMinutes: Scalars["Int"]["output"];
    perHourRate: Scalars["Int"]["output"];
};

export type PriceRateInput = {
    minimumDurationMinutes: Scalars["Int"]["input"];
    perHourRate: Scalars["Int"]["input"];
};

export type PriceRule = {
    created: Scalars["DateTime"]["output"];
    deposit: Scalars["Int"]["output"];
    depositType: DepositType;
    end: DayTime;
    metadata: Scalars["JSONString"]["output"];
    rulesetId: Scalars["ID"]["output"];
    start: DayTime;
    uid: Scalars["ID"]["output"];
};

export type PriceRuleDurationRate = PriceRule & {
    created: Scalars["DateTime"]["output"];
    deposit: Scalars["Int"]["output"];
    depositType: DepositType;
    end: DayTime;
    metadata: Scalars["JSONString"]["output"];
    rates: Array<PriceRate>;
    rulesetId: Scalars["ID"]["output"];
    start: DayTime;
    uid: Scalars["ID"]["output"];
};

export type PriceRulePerDayRate = PriceRule & {
    created: Scalars["DateTime"]["output"];
    deposit: Scalars["Int"]["output"];
    depositType: DepositType;
    end: DayTime;
    metadata: Scalars["JSONString"]["output"];
    perDayRate: Scalars["Int"]["output"];
    rulesetId: Scalars["ID"]["output"];
    start: DayTime;
    uid: Scalars["ID"]["output"];
};

export type PriceRulePerUnitRate = PriceRule & {
    created: Scalars["DateTime"]["output"];
    deposit: Scalars["Int"]["output"];
    depositType: DepositType;
    end: DayTime;
    metadata: Scalars["JSONString"]["output"];
    perUnitRate: Scalars["Int"]["output"];
    rulesetId: Scalars["ID"]["output"];
    start: DayTime;
    uid: Scalars["ID"]["output"];
};

export type PriceRuleset = {
    appliesTo: Scalars["ID"]["output"];
    created: Scalars["DateTime"]["output"];
    effectiveFrom: Scalars["DateTime"]["output"];
    metadata: Scalars["JSONString"]["output"];
    rules: Array<PriceRule>;
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type PriceRulesetsForServicesCreated = {
    rulesets: Array<PriceRuleset>;
};

export type PromoCode = {
    code: Scalars["ID"]["output"];
    discount: Scalars["Int"]["output"];
    endDt: Scalars["DateTime"]["output"];
    isAvailable: Scalars["Boolean"]["output"];
    itemTypeIds?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
    metadata?: Maybe<Scalars["JSONString"]["output"]>;
    startDt: Scalars["DateTime"]["output"];
    tenantIds?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
    tenants: Array<Organisation>;
    type: DiscountType;
};

export type PromoCodeActivated = {
    promocodeCode: Scalars["ID"]["output"];
};

export type PromoCodeAlreadyExists = {
    message: Scalars["String"]["output"];
};

export type PromoCodeArchivedV2 = {
    promoCode: PromoCodeV2;
};

export type PromoCodeCreated = {
    promocodeCode: Scalars["ID"]["output"];
};

export type PromoCodeCreatedV2 = {
    promoCode: PromoCodeV2;
};

export type PromoCodeDiscount = {
    discountedAmount: Scalars["Int"]["output"];
    items: Array<PromoCodeDiscountItemBreakdown>;
    promoCode: PromoCodeV2;
    subtotal: Scalars["Int"]["output"];
    total: Scalars["Int"]["output"];
};

export type PromoCodeDiscountItem = {
    bookingStart?: InputMaybe<Scalars["DateTime"]["input"]>;
    perUnitPrice: Scalars["Int"]["input"];
    quantity: Scalars["Int"]["input"];
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type PromoCodeDiscountItemBreakdown = {
    bookingStart?: Maybe<Scalars["DateTime"]["output"]>;
    discountAmount: Scalars["Int"]["output"];
    discountType: DiscountTypeV2;
    discountedAmount: Scalars["Int"]["output"];
    perUnitPrice: Scalars["Int"]["output"];
    quantity: Scalars["Int"]["output"];
    serviceId: Scalars["ID"]["output"];
    subtotal: Scalars["Int"]["output"];
    tenantId: Scalars["ID"]["output"];
    total: Scalars["Int"]["output"];
};

export type PromoCodeDiscountSet = {
    promocodeCode: Scalars["ID"]["output"];
};

export type PromoCodeEditedV2 = {
    promoCode: PromoCodeV2;
};

export type PromoCodeFilter = {
    byActivePeriodAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
    byActivePeriodBefore?: InputMaybe<Scalars["DateTime"]["input"]>;
    byAvailability?: InputMaybe<Scalars["Boolean"]["input"]>;
    byItemTypeId?: InputMaybe<Scalars["ID"]["input"]>;
    byTenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type PromoCodeV2 = {
    applicableServiceIds: Array<Scalars["String"]["output"]>;
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    bookingEnd?: Maybe<Scalars["DateTime"]["output"]>;
    bookingStart?: Maybe<Scalars["DateTime"]["output"]>;
    code: Scalars["String"]["output"];
    created: Scalars["DateTime"]["output"];
    discountAmount: Scalars["Int"]["output"];
    discountType: DiscountTypeV2;
    endDt?: Maybe<Scalars["DateTime"]["output"]>;
    name: Scalars["String"]["output"];
    redemptionLimit: Scalars["Int"]["output"];
    startDt: Scalars["DateTime"]["output"];
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type ProvideCapturePaymentProviderDetailsRequest = {
    payerDetailsEmail?: InputMaybe<Scalars["String"]["input"]>;
    payerDetailsName?: InputMaybe<Scalars["String"]["input"]>;
    payerDetailsPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
    paymentMethodId?: InputMaybe<Scalars["ID"]["input"]>;
    paymentProvider?: InputMaybe<PaymentProvider>;
    redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
    workflowId: Scalars["ID"]["input"];
};

export type ProvideCapturePaymentProviderDetailsResponse =
    | CapturePaymentProviderDetailsProvided
    | WorkflowCancelled
    | WorkflowNotFound
    | WorkflowNotReady;

export type ProvideCourtsiteCheckoutPaymentDetailsRequest = {
    checkoutId: Scalars["ID"]["input"];
    email: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    paymentProvider: PaymentProvider;
    phoneNumber: Scalars["String"]["input"];
    redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProvideCourtsiteCheckoutPaymentDetailsResponse =
    | CourtsiteCheckoutExpired
    | CourtsiteCheckoutPaymentDetailsProvided
    | WorkflowCancelled
    | WorkflowNotFound
    | WorkflowNotReady;

export type ProvideCourtsiteReschedulePaymentDetailsRequest = {
    email: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    paymentProvider?: InputMaybe<PaymentProvider>;
    phoneNumber: Scalars["String"]["input"];
    redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
    workflowId: Scalars["ID"]["input"];
};

export type ProvideCourtsiteReschedulePaymentDetailsResponse =
    | CourtsiteRescheduleExpired
    | CourtsiteReschedulePaymentDetailsProvided
    | InvalidCourtsiteReschedulePaymentDetailsRequest
    | WorkflowCancelled
    | WorkflowNotFound
    | WorkflowNotReady;

export type PublicGameCount = {
    categoryId: Scalars["ID"]["output"];
    gameCount: Scalars["Int"]["output"];
    locationTenantIds: Array<Maybe<Scalars["ID"]["output"]>>;
};

export type PublicGamesFilter = {
    categoryId?: InputMaybe<Scalars["ID"]["input"]>;
    dayOfGames?: InputMaybe<Scalars["Int"]["input"]>;
    locationTenantIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
    startsAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
    startsBefore?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type Query = {
    addOns: Array<AddOn>;
    approvedOrganisations?: Maybe<OrganisationConnection>;
    booking: Booking;
    bookings: BookingConnection;
    bookingsByIds: Array<Booking>;
    bookingsWithAddons?: Maybe<Array<Booking>>;
    capturePaymentStateById: CapturePaymentState;
    cartItems: Array<CartItems>;
    categories: Array<Category>;
    checkPromoCodeDiscount: PromoCodeDiscount;
    checkoutDiscount: CheckoutDiscount;
    confirmedBookingsWithAddons?: Maybe<Array<Booking>>;
    coreGatewayVersion: Scalars["String"]["output"];
    courtsiteCheckoutStateById: CourtsiteCheckoutState;
    courtsitePartners: Array<Organisation>;
    courtsiteRescheduleStateById: CourtsiteRescheduleState;
    courtsiteSearch: Array<Organisation>;
    currentCommands: Array<Command>;
    customer: Customer;
    customers: Array<Customer>;
    effectivePriceRuleset: PriceRuleset;
    fixedDurationOptionsStartingBetween: Array<ServiceFixedDurationOptions>;
    game: Game;
    groupIdEventsActivationStatus?: Maybe<Array<GroupIdEventsActivationStatus>>;
    invoice: Invoice;
    invoices: Array<Invoice>;
    invoicesByUids?: Maybe<Array<Invoice>>;
    kiplepaySettlementEnquiry?: Maybe<KiplepaySettlementDetailsResult>;
    kiplepaySettlementNotification?: Maybe<KiplepaySettlementNotification>;
    kiplepaySettlementNotifications?: Maybe<
        Array<Maybe<KiplepaySettlementNotification>>
    >;
    konsolCheckoutStateById: KonsolCheckoutState;
    latestSettlementConfigurationsChange: Array<
        Maybe<SettlementConfigurationChange>
    >;
    lhdnSubmissionByInvoiceId?: Maybe<LhdnSubmission>;
    lhdnSubmissionStatus?: Maybe<GetLhdnSubmissionStatus>;
    lights: Array<Light>;
    location: SearchLocation;
    locations: Array<SearchLocation>;
    metabaseDashboardUrl: Scalars["String"]["output"];
    myBooking: Booking;
    myBookingFilterCount: Array<BookingFilterCount>;
    myBookingStatistics: BookingStatistics;
    myBookings: BookingConnection;
    myBookingsByIds: Array<Booking>;
    myGameFilterCount: Array<GameFilterCount>;
    myGameStatistics: GameStatistics;
    myGames: GameConnection;
    myGroupedBookings: GroupedBookingsConnection;
    myInvoice: Invoice;
    myInvoiceStatistics: InvoiceStatistics;
    myInvoices?: Maybe<Array<Invoice>>;
    myInvoicesByIds?: Maybe<Array<Invoice>>;
    myInvoicesByRelatedEntityIds?: Maybe<Array<Invoice>>;
    myInvoicesPaged?: Maybe<InvoiceConnection>;
    myMemberServiceIds: Array<Scalars["String"]["output"]>;
    myOrganisations: Array<Organisation>;
    myProfile?: Maybe<UserProfile>;
    onlineServices: Array<Service>;
    onlineServicesByCategory: Array<Service>;
    organisation: Organisation;
    /** Not for public use */
    organisations: OrganisationConnection;
    organisationsWithoutPagination: Array<Organisation>;
    paidInvoices: Array<Invoice>;
    paymentMethods: Array<PaymentMethod>;
    paymentSession: PaymentSession;
    paymentSessionsByExternalIds?: Maybe<Array<PaymentSession>>;
    payments: Array<Payment>;
    promoCodeV2: PromoCodeV2;
    promoCodesV2: Array<PromoCodeV2>;
    promocodes?: Maybe<Array<PromoCode>>;
    psmGrpHealth: Scalars["String"]["output"];
    publicGameCount: Array<PublicGameCount>;
    publicGames: GameConnection;
    quotation: Quotation;
    quotations?: Maybe<Array<Quotation>>;
    resellerTenant: ResellerTenant;
    resellerTenants?: Maybe<Array<ResellerTenant>>;
    resourceTypes: Array<ResourceType>;
    resources: Array<Resource>;
    runningSettlementWatchers: WorkflowListPage;
    schedule: Schedule;
    schedules: Array<Schedule>;
    serviceAvailabilities?: Maybe<Array<ServiceAvailabilityResult>>;
    serviceAvailability: ServiceAvailabilityResult;
    serviceById?: Maybe<Service>;
    serviceTags: Array<ServiceTags>;
    services: Array<Service>;
    settlementConfiguration: SettlementConfiguration;
    settlementConfigurationChanges: SettlementConfigurationChangeConnection;
    settlementConfigurations: Array<Maybe<SettlementConfiguration>>;
    settlements: SettlementConnection;
    submitLHDNEInvoicesStateById: SubmitLhdneInvoicesState;
    tag: Tag;
    tags: Array<Tag>;
    tenantIdsFromPastBookings?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
    unavailableResourceSlotsBetween?: Maybe<Array<UnavailableResourceSlot>>;
    userProfileFromEmailPhoneNumber?: Maybe<UserProfile>;
    userProfileFromFirebaseId?: Maybe<UserProfile>;
    validateTaxpayerTin?: Maybe<ValidateTaxPayerTinResponse>;
    workflowApiVersion: Scalars["String"]["output"];
};

export type QueryAddOnsArgs = {
    tenantId: Scalars["ID"]["input"];
};

export type QueryApprovedOrganisationsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<ApprovedOrganisationsFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    sortOrder?: InputMaybe<OrganisationSortOrder>;
};

export type QueryBookingArgs = {
    bookingId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryBookingsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<BookingFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryBookingsByIdsArgs = {
    bookingIds: Array<Scalars["ID"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryBookingsWithAddonsArgs = {
    byStatus?: InputMaybe<BookingStatus>;
    startDateAfter: Scalars["DateTime"]["input"];
    startDateBefore: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryCapturePaymentStateByIdArgs = {
    workflowId: Scalars["ID"]["input"];
};

export type QueryCategoriesArgs = {
    filter?: InputMaybe<CategoryFilter>;
};

export type QueryCheckPromoCodeDiscountArgs = {
    items: Array<PromoCodeDiscountItem>;
    promoCodeCode: Scalars["String"]["input"];
    tenantId: Scalars["String"]["input"];
};

export type QueryCheckoutDiscountArgs = {
    items: Array<CheckoutDiscountItem>;
    promocodeId: Scalars["ID"]["input"];
};

export type QueryConfirmedBookingsWithAddonsArgs = {
    startDateAfter: Scalars["DateTime"]["input"];
    startDateBefore: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryCourtsiteCheckoutStateByIdArgs = {
    checkoutId: Scalars["ID"]["input"];
};

export type QueryCourtsitePartnersArgs = {
    filter?: InputMaybe<CourtsitePartnersFilter>;
    sortOrder?: InputMaybe<OrganisationSortOrder>;
};

export type QueryCourtsiteRescheduleStateByIdArgs = {
    workflowId: Scalars["ID"]["input"];
};

export type QueryCourtsiteSearchArgs = {
    request: CourtsiteSearchRequest;
};

export type QueryCurrentCommandsArgs = {
    lightIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryCustomerArgs = {
    tenantId: Scalars["ID"]["input"];
    uid: Scalars["ID"]["input"];
};

export type QueryCustomersArgs = {
    after?: InputMaybe<Scalars["ID"]["input"]>;
    filter?: InputMaybe<CustomerFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryEffectivePriceRulesetArgs = {
    at: Scalars["DateTime"]["input"];
    setFor: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryFixedDurationOptionsStartingBetweenArgs = {
    end: Scalars["DateTime"]["input"];
    serviceIds: Array<Scalars["ID"]["input"]>;
    start: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryGameArgs = {
    uid: Scalars["ID"]["input"];
};

export type QueryGroupIdEventsActivationStatusArgs = {
    groupIds: Array<Scalars["ID"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryInvoiceArgs = {
    tenantId: Scalars["ID"]["input"];
    uid: Scalars["ID"]["input"];
};

export type QueryInvoicesArgs = {
    after?: InputMaybe<Scalars["ID"]["input"]>;
    filter?: InputMaybe<InvoiceFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryInvoicesByUidsArgs = {
    invoiceIds: Array<Scalars["ID"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryKiplepaySettlementEnquiryArgs = {
    page?: InputMaybe<Scalars["Int"]["input"]>;
    settlementId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryKiplepaySettlementNotificationArgs = {
    notificationId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryKiplepaySettlementNotificationsArgs = {
    after: Scalars["DateTime"]["input"];
};

export type QueryKonsolCheckoutStateByIdArgs = {
    checkoutId: Scalars["ID"]["input"];
};

export type QueryLatestSettlementConfigurationsChangeArgs = {
    reviewState?: InputMaybe<ReviewState>;
    tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryLhdnSubmissionByInvoiceIdArgs = {
    invoiceId: Scalars["ID"]["input"];
};

export type QueryLhdnSubmissionStatusArgs = {
    pageNo?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    submissionId: Scalars["ID"]["input"];
};

export type QueryLightsArgs = {
    tenantId: Scalars["ID"]["input"];
};

export type QueryLocationArgs = {
    uid: Scalars["String"]["input"];
};

export type QueryLocationsArgs = {
    city?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryMetabaseDashboardUrlArgs = {
    categoryId?: InputMaybe<Scalars["ID"]["input"]>;
    dashboardType: MetabaseDashboardType;
    end: Scalars["DateTime"]["input"];
    serviceIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
    start: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryMyBookingArgs = {
    bookingId: Scalars["ID"]["input"];
};

export type QueryMyBookingFilterCountArgs = {
    categoryId?: InputMaybe<Scalars["ID"]["input"]>;
    startsAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
    tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryMyBookingsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<MyBookingsFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    order?: InputMaybe<SortOrderEnum>;
};

export type QueryMyBookingsByIdsArgs = {
    bookingIds: Array<Scalars["ID"]["input"]>;
};

export type QueryMyGameFilterCountArgs = {
    categoryId?: InputMaybe<Scalars["ID"]["input"]>;
    startsAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
    tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryMyGamesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<GameFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    order?: InputMaybe<SortOrderEnum>;
};

export type QueryMyGroupedBookingsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<MyGroupedBookingsFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    order?: InputMaybe<SortOrderEnum>;
};

export type QueryMyInvoiceArgs = {
    invoiceId: Scalars["ID"]["input"];
};

export type QueryMyInvoiceStatisticsArgs = {
    filter?: InputMaybe<MyInvoiceStatisticFilter>;
};

export type QueryMyInvoicesArgs = {
    filter?: InputMaybe<MyInvoicesFilter>;
};

export type QueryMyInvoicesByIdsArgs = {
    invoiceIds: Array<Scalars["ID"]["input"]>;
};

export type QueryMyInvoicesByRelatedEntityIdsArgs = {
    relatedEntityIds: Array<Scalars["ID"]["input"]>;
};

export type QueryMyInvoicesPagedArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<MyInvoicesPagedFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    order?: InputMaybe<SortOrderEnum>;
};

export type QueryMyMemberServiceIdsArgs = {
    onDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryOnlineServicesArgs = {
    filter?: InputMaybe<ServiceFilter>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryOnlineServicesByCategoryArgs = {
    categoryId: Scalars["ID"]["input"];
};

export type QueryOrganisationArgs = {
    uid: Scalars["ID"]["input"];
};

export type QueryOrganisationsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryOrganisationsWithoutPaginationArgs = {
    sortOrder?: InputMaybe<OrganisationSortOrder>;
};

export type QueryPaidInvoicesArgs = {
    excludeCancelled?: InputMaybe<Scalars["Boolean"]["input"]>;
    isCourtsiteOwned?: InputMaybe<Scalars["Boolean"]["input"]>;
    paidBetween: DateRangeFilter;
    tenantId: Scalars["ID"]["input"];
};

export type QueryPaymentMethodsArgs = {
    tenantId: Scalars["ID"]["input"];
};

export type QueryPaymentSessionArgs = {
    tenantId: Scalars["ID"]["input"];
    uid: Scalars["ID"]["input"];
};

export type QueryPaymentSessionsByExternalIdsArgs = {
    externalIds: Array<Scalars["ID"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryPaymentsArgs = {
    after?: InputMaybe<Scalars["ID"]["input"]>;
    filter?: InputMaybe<PaymentFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryPromoCodeV2Args = {
    promoCodeId: Scalars["String"]["input"];
    tenantId: Scalars["String"]["input"];
};

export type QueryPromoCodesV2Args = {
    tenantId: Scalars["String"]["input"];
};

export type QueryPromocodesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<PromoCodeFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryPublicGameCountArgs = {
    categoryId?: InputMaybe<Scalars["ID"]["input"]>;
    startsAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
    tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryPublicGamesArgs = {
    filter?: InputMaybe<PublicGamesFilter>;
    page?: InputMaybe<PageInput>;
};

export type QueryQuotationArgs = {
    end: Scalars["DateTime"]["input"];
    setFor: Scalars["ID"]["input"];
    start: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryQuotationsArgs = {
    request: Array<QuotationRequest>;
};

export type QueryResellerTenantArgs = {
    tenantId: Scalars["ID"]["input"];
};

export type QueryResourceTypesArgs = {
    tenantId: Scalars["ID"]["input"];
};

export type QueryResourcesArgs = {
    filter?: InputMaybe<ResourceFilter>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryRunningSettlementWatchersArgs = {
    nextPageToken?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryScheduleArgs = {
    scheduleId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QuerySchedulesArgs = {
    tenantId: Scalars["ID"]["input"];
};

export type QueryServiceAvailabilitiesArgs = {
    request: Array<ServiceAvailabilityRequest>;
};

export type QueryServiceAvailabilityArgs = {
    request: ServiceAvailabilityRequest;
};

export type QueryServiceByIdArgs = {
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryServiceTagsArgs = {
    tenantId: Scalars["ID"]["input"];
};

export type QueryServicesArgs = {
    filter?: InputMaybe<ServiceFilter>;
    tenantId: Scalars["ID"]["input"];
};

export type QuerySettlementConfigurationArgs = {
    configurationId: Scalars["ID"]["input"];
};

export type QuerySettlementConfigurationChangesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    reviewState?: InputMaybe<ReviewState>;
    sortBy?: InputMaybe<ConfigurationChangeSortOrderEnum>;
    tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QuerySettlementsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filters?: InputMaybe<SettlementsFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    sortBy?: InputMaybe<SettlementSortOrderEnum>;
    tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QuerySubmitLhdneInvoicesStateByIdArgs = {
    workflowId: Scalars["ID"]["input"];
};

export type QueryTagArgs = {
    tagId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryTagsArgs = {
    tenantId: Scalars["ID"]["input"];
};

export type QueryTenantIdsFromPastBookingsArgs = {
    filter?: InputMaybe<ServiceModeFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryUnavailableResourceSlotsBetweenArgs = {
    categoryId: Scalars["ID"]["input"];
    dtRange: DateRangeFilter;
    tenantId: Scalars["ID"]["input"];
};

export type QueryUserProfileFromEmailPhoneNumberArgs = {
    query: Scalars["String"]["input"];
};

export type QueryUserProfileFromFirebaseIdArgs = {
    firebaseId: Scalars["ID"]["input"];
};

export type QueryValidateTaxpayerTinArgs = {
    idType: IdType;
    idValue: Scalars["String"]["input"];
    tin: Scalars["String"]["input"];
};

export type Quotation = {
    deposit: Scalars["Int"]["output"];
    price: Scalars["Int"]["output"];
    quotationFor: Scalars["ID"]["output"];
    remainder: Scalars["Int"]["output"];
    satisfiesMinimumDuration: Scalars["Boolean"]["output"];
    timeFullyAccounted: Scalars["Boolean"]["output"];
};

export type QuotationRequest = {
    end: Scalars["DateTime"]["input"];
    setFor: Scalars["ID"]["input"];
    start: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type RazorpaySessionInitiated = {
    externalId: Scalars["ID"]["output"];
    paymentSessionCreated: Scalars["DateTime"]["output"];
    paymentSessionId: Scalars["ID"]["output"];
    responseUrl: Scalars["String"]["output"];
};

export type RecoverPaymentSessionState = {
    bookingIds: Array<Scalars["ID"]["output"]>;
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    failureReason: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    invoiceId: Scalars["ID"]["output"];
    paymentSessionId: Scalars["ID"]["output"];
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
    userEmail: Scalars["String"]["output"];
    userId: Scalars["ID"]["output"];
};

export type RecurringBookingsRescheduled = {
    state: RescheduleRecurringBookingsState;
};

/** An enumeration. */
export type RecurringSeriesModification = "MAINTAIN" | "NEW" | "REMOVE";

export type RejectedDocument = {
    error: Error;
    invoiceCode: Scalars["String"]["output"];
};

export type RemoveBookingGuestRequest = {
    bookingIds: Array<Scalars["ID"]["input"]>;
    tenantId: Scalars["ID"]["input"];
    userId: Scalars["ID"]["input"];
};

export type RemoveBookingGuestResponse =
    | AccessError
    | BookingGuestRemoved
    | NotFoundError;

export type RenameCategoryRequest = {
    categoryId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
};

export type RenameCategoryResponse =
    | AccessError
    | CategoryRenamed
    | NotFoundError;

export type RenameResourceRequest = {
    name: Scalars["String"]["input"];
    resourceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type RenameResourceResponse =
    | AccessError
    | NotFoundError
    | ResourceAlreadyArchived
    | ResourceRenamed;

export type RenameServiceRequest = {
    newName: Scalars["String"]["input"];
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type RenameServiceResponse =
    | AccessError
    | NotFoundError
    | ServiceRenamed;

export type RescheduleBookingRequest = {
    bookingId: Scalars["ID"]["input"];
    endDt: Scalars["DateTime"]["input"];
    metadata: Scalars["JSONString"]["input"];
    selectedResourceIds: Array<Scalars["ID"]["input"]>;
    serviceId: Scalars["ID"]["input"];
    startDt: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type RescheduleBookingResponse =
    | AccessError
    | BookingRescheduled
    | BookingUnavailable
    | NotFoundError;

export type RescheduleBookingWorkflowBookingNotReschedulable = {
    message: Scalars["String"]["output"];
};

export type RescheduleBookingWorkflowBookingUnavailable = {
    message: Scalars["String"]["output"];
};

export type RescheduleBookingWorkflowCompleted = {
    state: RescheduleBookingWorkflowState;
};

export type RescheduleBookingWorkflowResourceChangeInput = {
    newResourceId: Scalars["String"]["input"];
    targetResourceId: Scalars["String"]["input"];
};

export type RescheduleBookingWorkflowState = {
    bookingId: Scalars["ID"]["output"];
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    id: Scalars["ID"]["output"];
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
};

export type RescheduleRecurringBookingGuestModificationInput = {
    email?: InputMaybe<Scalars["String"]["input"]>;
    guestId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type RescheduleRecurringBookingModificationInput = {
    bookingId: Scalars["ID"]["input"];
    endDt: Scalars["Time"]["input"];
    guestChanges: Array<RescheduleRecurringBookingGuestModificationInput>;
    metadata: Scalars["JSONString"]["input"];
    resourceChanges: Array<RescheduleBookingWorkflowResourceChangeInput>;
    startDt: Scalars["Time"]["input"];
};

export type RescheduleRecurringBookingsModificationsInput = {
    bookingModifications: Array<RescheduleRecurringBookingModificationInput>;
    recurringSeriesModification?: InputMaybe<RescheduleRecurringBookingsSeriesModifications>;
};

export type RescheduleRecurringBookingsSeriesModifications =
    | "MAINTAIN"
    | "NEW"
    | "REMOVE";

export type RescheduleRecurringBookingsState = {
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    id: Scalars["ID"]["output"];
    rescheduleError?: Maybe<Scalars["String"]["output"]>;
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
    userId: Scalars["ID"]["output"];
};

export type ResellerTenant = {
    activeAfter?: Maybe<Scalars["DateTime"]["output"]>;
    isActive: Scalars["Boolean"]["output"];
    tenantId: Scalars["ID"]["output"];
};

export type Resource = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    bookings: Array<Booking>;
    created: Scalars["DateTime"]["output"];
    linkedBookings: Array<Booking>;
    linkedResources: Array<Resource>;
    links: Array<Maybe<ResourceLink>>;
    metadata: Scalars["JSONString"]["output"];
    name: Scalars["String"]["output"];
    resourceType: ResourceType;
    resourceTypeId: Scalars["ID"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
    updated?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ResourceBookingsArgs = {
    endDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    includeBeforeStartDt?: InputMaybe<Scalars["Boolean"]["input"]>;
    startDt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ResourceLinkedBookingsArgs = {
    endDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    includeBeforeStartDt?: InputMaybe<Scalars["Boolean"]["input"]>;
    startDt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ResourceAlreadyArchived = {
    reason: Scalars["String"]["output"];
};

export type ResourceArchived = {
    resourceId: Scalars["ID"]["output"];
};

export type ResourceBookingSummary = {
    created: Scalars["DateTime"]["output"];
    resourceId: Scalars["ID"]["output"];
    resourceName: Scalars["String"]["output"];
    uid: Scalars["ID"]["output"];
};

export type ResourceCreated = {
    resourceId: Scalars["ID"]["output"];
};

export type ResourceFilter = {
    byResourceTypeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ResourceLink = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    metadata: Scalars["JSONString"]["output"];
    resourceIds: Array<Maybe<Scalars["ID"]["output"]>>;
    resources: Array<Maybe<Resource>>;
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type ResourceLinksAlreadyArchived = {
    message: Scalars["String"]["output"];
};

export type ResourceLinksAlreadyExist = {
    message: Scalars["String"]["output"];
};

export type ResourceLinksArchived = {
    resourceLinkIds: Array<Maybe<Scalars["ID"]["output"]>>;
};

export type ResourceLinksCreated = {
    resourceLinks?: Maybe<Array<Maybe<ResourceLink>>>;
};

export type ResourceRenamed = {
    name: Scalars["String"]["output"];
    resourceId: Scalars["ID"]["output"];
};

export type ResourceType = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    metadata: Scalars["JSONString"]["output"];
    name: Scalars["String"]["output"];
    resources: Array<Resource>;
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
    updated?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ResourceTypeArchived = {
    resourceTypeId: Scalars["ID"]["output"];
};

export type ResourceTypeChanged = {
    resourceId: Scalars["ID"]["output"];
    resourceTypeId: Scalars["ID"]["output"];
};

export type ResourceTypeCreated = {
    resourceTypeId: Scalars["ID"]["output"];
};

export type ResourceTypesMetadataInput = {
    metadata: Scalars["JSONString"]["input"];
    resourceTypeId: Scalars["ID"]["input"];
};

export type ResourceTypesMetadataUpdated = {
    resourceTypes: Array<Maybe<ResourceType>>;
};

export type ReviewSettlementConfigurationWithApprovalRequest = {
    configurationChangeId: Scalars["ID"]["input"];
    reviewMessage?: InputMaybe<Scalars["String"]["input"]>;
    reviewState: ReviewState;
};

export type ReviewSettlementConfigurationWithApprovalResponse =
    | AccessError
    | NotFoundError
    | SettlementConfigurationWithApprovalReviewed;

/** An enumeration. */
export type ReviewState = "APPROVED" | "PENDING" | "REJECTED";

export type ReviveBookingFailure = {
    bookingId: Scalars["ID"]["output"];
    reason: ServiceUnavailabilityReason;
};

export type ReviveBookingsFailure = {
    failures: Array<ReviveBookingFailure>;
};

export type ReviveBookingsRequest = {
    bookingIds: Array<Scalars["ID"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type ReviveBookingsResponse =
    | AccessError
    | BookingsRevived
    | NotFoundError
    | ReviveBookingsFailure;

export type Rule = {
    created: Scalars["DateTime"]["output"];
    uid: Scalars["ID"]["output"];
};

export type RuleInput = {
    dates?: InputMaybe<Array<Scalars["Date"]["input"]>>;
    effectiveFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
    effectiveTo?: InputMaybe<Scalars["DateTime"]["input"]>;
    maxAdvancedBookingDays?: InputMaybe<Scalars["Int"]["input"]>;
    minAdvancedBookingMinutes?: InputMaybe<Scalars["Int"]["input"]>;
    ruleType: RuleType;
};

export type RuleType =
    | "ALLOW_DATES"
    | "ALLOW_FROM_DATETIME"
    | "ALLOW_MAXIMUM_ADVANCED_BOOKING_TIME"
    | "ALLOW_MINIMUM_ADVANCED_BOOKING_TIME"
    | "ALLOW_TO_DATETIME";

export type SPaySessionInitiated = {
    backendUrl: Scalars["String"]["output"];
    encryptedPayload: Scalars["String"]["output"];
    externalId: Scalars["ID"]["output"];
    paymentSessionCreated: Scalars["DateTime"]["output"];
    paymentSessionId: Scalars["ID"]["output"];
    qrCode: Scalars["String"]["output"];
    responseUrl: Scalars["String"]["output"];
};

export type Schedule = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    daytimeSlots: Array<DayTimeSlot>;
    metadata: Scalars["JSONString"]["output"];
    name: Scalars["String"]["output"];
    rules: Array<Rule>;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type ScheduleArchived = {
    scheduleId: Scalars["ID"]["output"];
};

export type ScheduleCreated = {
    schedule: Schedule;
};

export type ScheduleUpdated = {
    schedule: Schedule;
};

export type SearchLocation = {
    city?: Maybe<Scalars["String"]["output"]>;
    coordinates: Coordinates;
    created: Scalars["DateTime"]["output"];
    name: Scalars["String"]["output"];
    uid: Scalars["ID"]["output"];
};

export type Service = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    bookingWindow?: Maybe<Scalars["Int"]["output"]>;
    category?: Maybe<Category>;
    created: Scalars["DateTime"]["output"];
    customDurationOptions?: Maybe<Array<Scalars["Int"]["output"]>>;
    disallowBookingGap: Scalars["Boolean"]["output"];
    endTime?: Maybe<Scalars["Time"]["output"]>;
    metadata: Scalars["JSONString"]["output"];
    minBookingWindowMinutes?: Maybe<Scalars["Int"]["output"]>;
    minRescheduleNotice?: Maybe<Scalars["Int"]["output"]>;
    name: Scalars["String"]["output"];
    options: Array<DurationOption>;
    resources: Array<ServiceResource>;
    serviceCategory?: Maybe<ServiceCategory>;
    serviceMode: Scalars["String"]["output"];
    startTime?: Maybe<Scalars["Time"]["output"]>;
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
    updated?: Maybe<Scalars["DateTime"]["output"]>;
    visibility: ServiceVisibility;
};

export type ServiceAlreadyArchived = {
    message: Scalars["String"]["output"];
};

export type ServiceArchived = {
    service: Service;
};

export type ServiceAvailabilityRequest = {
    endDt: Scalars["DateTime"]["input"];
    resourceId?: InputMaybe<Scalars["ID"]["input"]>;
    serviceId: Scalars["ID"]["input"];
    startDt: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ServiceAvailabilityResult = {
    availableSelectableResourceIds: Array<Scalars["ID"]["output"]>;
    availableSelectableResources: Array<Resource>;
    availableUnselectableResourceIds: Array<Scalars["ID"]["output"]>;
    availableUnselectableResources: Array<Resource>;
    isAvailable: Scalars["Boolean"]["output"];
    reason: ServiceUnavailabilityReason;
    serviceId: Scalars["ID"]["output"];
    tenantId: Scalars["ID"]["output"];
};

export type ServiceBookingLimitsSet = {
    serviceId: Scalars["ID"]["output"];
};

export type ServiceBookingSummary = {
    categoryId: Scalars["ID"]["output"];
    categoryName: Scalars["String"]["output"];
    created: Scalars["DateTime"]["output"];
    serviceMode: Scalars["String"]["output"];
    uid: Scalars["ID"]["output"];
};

export type ServiceCategory = {
    categoryId: Scalars["ID"]["output"];
    created: Scalars["DateTime"]["output"];
    metadata: Scalars["JSONString"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
    updated?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ServiceCategoryAssigned = {
    categoryId: Scalars["ID"]["output"];
    serviceId: Scalars["ID"]["output"];
};

export type ServiceCreated = {
    serviceId: Scalars["ID"]["output"];
};

export type ServiceDurationOptionsSet = {
    service: Service;
};

export type ServiceFilter = {
    byCategoryId?: InputMaybe<Scalars["ID"]["input"]>;
    includeArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ServiceFixedDurationOptions = {
    options: Array<DatetimeRange>;
    serviceId: Scalars["ID"]["output"];
};

export type ServiceMetadataUpdated = {
    metadata: Scalars["JSONString"]["output"];
    serviceId: Scalars["ID"]["output"];
};

export type ServiceModeFilter = {
    includeDailyServices?: InputMaybe<Scalars["Boolean"]["input"]>;
    includeHourlyServices?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ServiceRenamed = {
    serviceId: Scalars["ID"]["output"];
};

export type ServiceResource = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    optionKey: Scalars["String"]["output"];
    resource: Resource;
    resourceId: Scalars["ID"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
    updated?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ServiceResourceAdded = {
    resourceId: Scalars["ID"]["output"];
    serviceId: Scalars["ID"]["output"];
};

export type ServiceResourceArchived = {
    resourceId: Scalars["ID"]["output"];
    serviceId: Scalars["ID"]["output"];
};

export type ServiceResourceInput = {
    optionKey: Scalars["String"]["input"];
    resourceId: Scalars["ID"]["input"];
};

export type ServiceTags = {
    created: Scalars["DateTime"]["output"];
    serviceId: Scalars["ID"]["output"];
    tagIds: Array<Scalars["ID"]["output"]>;
    tags: Array<Tag>;
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
};

export type ServiceTagsSet = {
    serviceTag: ServiceTags;
};

/** An enumeration. */
export type ServiceUnavailabilityReason =
    | "BOOKING_NOT_FOUND"
    | "BOOKING_WINDOW_CONFLICT"
    | "DURATION_OPTION_CONFLICT"
    | "INVALID_DATE_RANGE"
    | "NONE"
    | "NO_SERVICE_ID_PROVIDED"
    | "NO_SERVICE_SCHEDULE"
    | "RESCHEDULE_CONFLICT"
    | "RESCHEDULE_ISSUE"
    | "SELECTABLE_RESOURCES_UNAVAILABLE"
    | "SERVICE_ARCHIVED"
    | "SERVICE_NOT_FOUND"
    | "SERVICE_UNAVAILABLE"
    | "UNSELECTABLE_RESOURCES";

/** An enumeration. */
export type ServiceVisibility = "OFFLINE" | "ONLINE";

export type ServiceVisibilityUpdated = {
    serviceId: Scalars["ID"]["output"];
    visibility: Scalars["String"]["output"];
};

export type SetAddOnIsInStockRequest = {
    addOnId: Scalars["ID"]["input"];
    isInStock: Scalars["Boolean"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type SetAddOnIsInStockResponse =
    | AccessError
    | AddOnAlreadyArchived
    | AddOnIsInStockSet
    | NotFoundError;

export type SetDailyServiceTimesRequest = {
    endTime: Scalars["Time"]["input"];
    serviceId: Scalars["ID"]["input"];
    startTime: Scalars["Time"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type SetDailyServiceTimesResponse =
    | AccessError
    | DailyServiceTimesSet
    | InvalidServiceModeError
    | NotFoundError;

export type SetLightDurationRequest = {
    cooldown: Scalars["Int"]["input"];
    lightId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
    warmup: Scalars["Int"]["input"];
};

export type SetLightDurationResponse =
    | AccessError
    | LightDurationSet
    | NotFoundError;

export type SetMandatoryUserProfileRequest = {
    dateOfBirth: Scalars["Date"]["input"];
    email: Scalars["EmailAddress"]["input"];
    gender: Gender;
    name: Scalars["String"]["input"];
    otherPreferredSports?: InputMaybe<Scalars["String"]["input"]>;
    phoneNumber: Scalars["PhoneNumber"]["input"];
    postcode: Scalars["String"]["input"];
    preferredSports: Array<Scalars["ID"]["input"]>;
    sportsFrequency: Scalars["Int"]["input"];
};

export type SetMandatoryUserProfileResponse =
    | AccessError
    | MandatoryUserProfileSet
    | NotFoundError;

export type SetMemberCapabilitiesRequest = {
    capabilities: Array<Scalars["String"]["input"]>;
    memberId: Scalars["ID"]["input"];
    organisationId: Scalars["ID"]["input"];
};

export type SetMemberCapabilitiesResponse =
    | AccessError
    | CannotModifySelfError
    | MemberAlreadyArchivedError
    | MemberCapabilitiesSet
    | NotFoundError;

export type SetMemberRoleRequest = {
    memberId: Scalars["ID"]["input"];
    organisationId: Scalars["ID"]["input"];
    role: MemberRole;
};

export type SetOrganisationIsCourtsitePartnerRequest = {
    isCourtsitePartner: Scalars["Boolean"]["input"];
    organisationId: Scalars["ID"]["input"];
};

export type SetOrganisationIsCourtsitePartnerResponse =
    | AccessError
    | NotFoundError
    | OrganisationIsCourtsitePartnerSet;

export type SetOrganisationWeightagesRequest = {
    organisationInput?: InputMaybe<Array<OrganisationWeightageInput>>;
};

export type SetOrganisationWeightagesResponse =
    | AccessError
    | NotFoundError
    | OrganisationWeightagesSet;

export type SetPromoCodeCriteriaRequest = {
    code: Scalars["ID"]["input"];
    itemTypeIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
    tenantIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
};

export type SetPromoCodeCriteriaResponse =
    | NotFoundError
    | SetPromoCodeCriteriaSet;

export type SetPromoCodeCriteriaSet = {
    itemTypeIds: Array<Maybe<Scalars["ID"]["output"]>>;
    promocodeCode: Scalars["ID"]["output"];
    tenantIds: Array<Maybe<Scalars["ID"]["output"]>>;
};

export type SetPromoCodeDiscountRequest = {
    code: Scalars["ID"]["input"];
    discount: Scalars["Int"]["input"];
    type?: InputMaybe<DiscountType>;
};

export type SetPromoCodeDiscountResponse = NotFoundError | PromoCodeDiscountSet;

export type SetServiceBookingLimitsRequest = {
    bookingWindowByWeeks?: InputMaybe<Scalars["Int"]["input"]>;
    disallowBookingGap?: InputMaybe<Scalars["Boolean"]["input"]>;
    minBookingWindowByMinutes?: InputMaybe<Scalars["Int"]["input"]>;
    minRescheduleNotice?: InputMaybe<Scalars["Int"]["input"]>;
    selectorRange?: InputMaybe<BookingSelectorRangeInput>;
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type SetServiceBookingLimitsResponse =
    | AccessError
    | NotFoundError
    | ServiceBookingLimitsSet;

export type SetServiceDurationOptionsRequest = {
    options: Array<Scalars["Int"]["input"]>;
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type SetServiceDurationOptionsResponse =
    | AccessError
    | NotFoundError
    | ServiceDurationOptionsSet;

export type SetServiceTagsRequest = {
    serviceId: Scalars["ID"]["input"];
    tagIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
    tenantId: Scalars["ID"]["input"];
};

export type SetServiceTagsResponse = AccessError | ServiceTagsSet;

export type SetUserProfileManualRequest = {
    dateOfBirth?: InputMaybe<Scalars["Date"]["input"]>;
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    firebaseId: Scalars["ID"]["input"];
    gender?: InputMaybe<Gender>;
    name: Scalars["String"]["input"];
    otherPreferredSports?: InputMaybe<Scalars["String"]["input"]>;
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    postcode?: InputMaybe<Scalars["String"]["input"]>;
    preferredSports?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    sportsFrequency?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SetUserProfileManualResponse =
    | AccessError
    | NotFoundError
    | UserProfileSet;

export type SetUserProfileRequest = {
    dateOfBirth?: InputMaybe<Scalars["Date"]["input"]>;
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    gender?: InputMaybe<Gender>;
    name: Scalars["String"]["input"];
    otherPreferredSports?: InputMaybe<Scalars["String"]["input"]>;
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    postcode?: InputMaybe<Scalars["String"]["input"]>;
    preferredSports?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    sportsFrequency?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SetUserProfileResponse =
    | AccessError
    | InvalidValue
    | UserProfileSet;

export type Settlement = {
    amount: Scalars["Int"]["output"];
    created: Scalars["DateTime"]["output"];
    endDate: Scalars["DateTime"]["output"];
    isAutoGen: Scalars["Boolean"]["output"];
    isResellerSettlement: Scalars["Boolean"]["output"];
    metadata: Scalars["JSONString"]["output"];
    reportUrl: Scalars["String"]["output"];
    startDate: Scalars["DateTime"]["output"];
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type SettlementArchived = {
    settlement: Settlement;
};

export type SettlementConfiguration = {
    commissionPercentage: Scalars["Float"]["output"];
    created: Scalars["DateTime"]["output"];
    metadata: Scalars["JSONString"]["output"];
    monthlyFee: Scalars["Int"]["output"];
    settlementRange: SettlementRangeConfiguration;
    slug: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type SettlementConfigurationChange = {
    changes: Scalars["JSONString"]["output"];
    configurationId: Scalars["ID"]["output"];
    created: Scalars["DateTime"]["output"];
    createdByEmail: Scalars["String"]["output"];
    createdByUserId: Scalars["ID"]["output"];
    createdOn: Scalars["DateTime"]["output"];
    reviewMessage?: Maybe<Scalars["String"]["output"]>;
    reviewState?: Maybe<ReviewState>;
    reviewedByEmail?: Maybe<Scalars["String"]["output"]>;
    reviewedByUserId?: Maybe<Scalars["ID"]["output"]>;
    reviewedOn?: Maybe<Scalars["DateTime"]["output"]>;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type SettlementConfigurationChangeConnection = {
    edges: Array<SettlementConfigurationChangeEdge>;
    pageInfo: PageInfo;
};

export type SettlementConfigurationChangeEdge = {
    cursor: Scalars["String"]["output"];
    node: SettlementConfigurationChange;
};

export type SettlementConfigurationCreated = {
    configuration: SettlementConfiguration;
};

export type SettlementConfigurationEdited = {
    configuration: SettlementConfiguration;
};

export type SettlementConfigurationWithApprovalCreated = {
    configurationChange: SettlementConfigurationChange;
};

export type SettlementConfigurationWithApprovalEdited = {
    configurationChange: SettlementConfigurationChange;
};

export type SettlementConfigurationWithApprovalReviewed = {
    configurationChange: SettlementConfigurationChange;
};

export type SettlementConnection = {
    edges: Array<SettlementEdge>;
    pageInfo: PageInfo;
};

export type SettlementCreated = {
    settlement: Settlement;
};

export type SettlementDownloadLinkGenerated = {
    link: Scalars["String"]["output"];
};

export type SettlementEdge = {
    cursor: Scalars["String"]["output"];
    node: Settlement;
};

export type SettlementRangeByDayOfMonth = SettlementRangeConfiguration & {
    targetDays: Array<Scalars["Int"]["output"]>;
};

export type SettlementRangeByDayOfWeek = SettlementRangeConfiguration & {
    targetDays: Array<Scalars["Int"]["output"]>;
};

export type SettlementRangeConfiguration = {
    targetDays: Array<Scalars["Int"]["output"]>;
};

export type SettlementRangeConfigurationInput = {
    settlementRangeType: SettlementRangeConfigurationType;
    targetDays: Array<Scalars["Int"]["input"]>;
};

export type SettlementRangeConfigurationType = "DAY_OF_MONTH" | "DAY_OF_WEEK";

export type SettlementSortOrderEnum = "BY_SETTLEMENT_END_DATE_DESC";

export type SettlementWatcherRunning = {
    id: Scalars["ID"]["output"];
    runId: Scalars["ID"]["output"];
};

export type SettlementsFilter = {
    byEndDate?: InputMaybe<DateRangeFilter>;
};

export type SortOrderEnum = "ASC" | "DESC";

export type StartCancelBookingWorkflowRequest = {
    bookingId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type StartCancelBookingWorkflowResponse =
    | CancelBookingWorkflowBookingNotCancellable
    | CancelBookingWorkflowCompleted
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartCancelBookingsRequest = {
    bookingIds: Array<Scalars["String"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type StartCancelBookingsResponse =
    | CancelBookingsWorkflowBookingsNotCancellable
    | CancelBookingsWorkflowCompleted
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartCancelPaymentWorkflowRequest = {
    invoiceId: Scalars["ID"]["input"];
    paymentId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type StartCancelPaymentWorkflowResponse =
    | CancelPaymentWorkflowCompleted
    | CancelPaymentWorkflowPaymentNotCancellable
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartCourtsiteCheckoutRequest = {
    addOns?: InputMaybe<Array<CourtsiteCheckoutAddOnInput>>;
    guest: CourtsiteCheckoutGuestInput;
    metadata: Scalars["JSONString"]["input"];
    promocode?: InputMaybe<Scalars["String"]["input"]>;
    slots: Array<CourtsiteCheckoutSlotInput>;
    tenantId: Scalars["ID"]["input"];
};

export type StartCourtsiteCheckoutResponse =
    | CourtsiteCheckoutBookingsUnavailable
    | CourtsiteCheckoutStarted
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartCourtsiteGameWorkflowLocationInput = {
    tenantId: Scalars["String"]["input"];
};

export type StartCourtsiteGameWorkflowPlayerInput = {
    displayPicture?: InputMaybe<Scalars["String"]["input"]>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type StartCourtsiteGameWorkflowRequest = {
    categoryId: Scalars["ID"]["input"];
    description: Scalars["String"]["input"];
    endDt: Scalars["Time"]["input"];
    location: StartCourtsiteGameWorkflowLocationInput;
    maxPlayers: Scalars["Int"]["input"];
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    organizerDetails: StartCourtsiteGameWorkflowPlayerInput;
    private?: InputMaybe<Scalars["Boolean"]["input"]>;
    reservedPlayers?: InputMaybe<Scalars["Int"]["input"]>;
    startDt: Scalars["Time"]["input"];
};

export type StartCourtsiteGameWorkflowResponse =
    | CourtsiteGameWorkflowStarted
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartCourtsiteRescheduleRequest = {
    bookingId: Scalars["ID"]["input"];
    newSlot: CourtsiteBookingSlotInput;
    tenantId: Scalars["ID"]["input"];
};

export type StartCourtsiteRescheduleResponse =
    | CourtsiteRescheduleBookingsConflictingWorkflow
    | CourtsiteRescheduleBookingsUnavailable
    | CourtsiteRescheduleStarted
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartGeneratePsmGrpCashSaleTxRequest = {
    kiplePaySettlementNotificationId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type StartGeneratePsmGrpCashSaleTxResponse =
    | GeneratePsmGrpCashSaleTxCompleted
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartKonsolCancelInvoiceRequest = {
    invoiceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type StartKonsolCancelInvoiceResponse =
    | KonsolCancelInvoiceCompleted
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartKonsolCheckoutRequest = {
    bookingIds: Array<Scalars["ID"]["input"]>;
    invoiceeEmail?: InputMaybe<Scalars["String"]["input"]>;
    invoiceeName: Scalars["String"]["input"];
    invoiceePhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
    isOnlinePayment: Scalars["Boolean"]["input"];
    paymentMethodId?: InputMaybe<Scalars["ID"]["input"]>;
    redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type StartKonsolCheckoutResponse =
    | KonsolCheckoutInvalidBooking
    | KonsolCheckoutStarted
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartKonsolCreateInvoiceRequest = {
    bookingIds: Array<Scalars["ID"]["input"]>;
    invoiceeEmail?: InputMaybe<Scalars["String"]["input"]>;
    invoiceeName: Scalars["String"]["input"];
    invoiceePhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type StartKonsolCreateInvoiceResponse =
    | KonsolCreateInvoiceCompleted
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartKonsolPayInvoiceRequest = {
    invoiceId: Scalars["ID"]["input"];
    isOnlinePayment: Scalars["Boolean"]["input"];
    paymentMethodId: Scalars["ID"]["input"];
    redirectUrl: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type StartKonsolPayInvoiceResponse =
    | KonsolPayInvoiceCompleted
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartRecoverPaymentSessionRequest = {
    paymentSessionId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type StartRecoverPaymentSessionResponse =
    | PaymentSessionRecovered
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartRescheduleBookingWorkflowRequest = {
    bookingId: Scalars["ID"]["input"];
    endDt: Scalars["Time"]["input"];
    resourceChanges: Array<RescheduleBookingWorkflowResourceChangeInput>;
    startDt: Scalars["Time"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type StartRescheduleBookingWorkflowResponse =
    | RescheduleBookingWorkflowBookingNotReschedulable
    | RescheduleBookingWorkflowBookingUnavailable
    | RescheduleBookingWorkflowCompleted
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartRescheduleRecurringBookingsRequest = {
    bookingGroupModifications: Array<RescheduleRecurringBookingsModificationsInput>;
    tenantId: Scalars["String"]["input"];
};

export type StartRescheduleRecurringBookingsResponse =
    | RecurringBookingsRescheduled
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartSettlementWatcherRequest = {
    startDt?: InputMaybe<Scalars["Time"]["input"]>;
    triggerHours?: InputMaybe<Array<Scalars["Int"]["input"]>>;
    watchDuration?: InputMaybe<Scalars["Int"]["input"]>;
    willContinueOnEnd: Scalars["Boolean"]["input"];
};

export type StartSettlementWatcherResponse =
    | SettlementWatcherRunning
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartSubmitLhdneInvoicesRequest = {
    addressLine1: Scalars["String"]["input"];
    addressLine2?: InputMaybe<Scalars["String"]["input"]>;
    addressLine3?: InputMaybe<Scalars["String"]["input"]>;
    businessOrigin?: InputMaybe<Scalars["String"]["input"]>;
    city: Scalars["String"]["input"];
    country: Scalars["String"]["input"];
    email: Scalars["String"]["input"];
    idType: Scalars["String"]["input"];
    idValue: Scalars["String"]["input"];
    invoiceIds: Array<Scalars["ID"]["input"]>;
    invoiceType: Scalars["String"]["input"];
    mobileNumber: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    postcode: Scalars["String"]["input"];
    sstNumber?: InputMaybe<Scalars["String"]["input"]>;
    state: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
    tin: Scalars["String"]["input"];
};

export type StartSubmitLhdneInvoicesResponse =
    | SubmitLhdneInvoicesStarted
    | WorkflowCancelled
    | WorkflowNotReady;

export type SubmitLhdneInvoicesStarted = {
    state: SubmitLhdneInvoicesState;
};

export type SubmitLhdneInvoicesState = {
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    id: Scalars["ID"]["output"];
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
};

export type SubmitLhdnInvoiceRequest = {
    invoiceDetails: Array<InvoiceDetail>;
    invoiceId: Scalars["ID"]["input"];
    partyDetails: PartyDetails;
};

export type SubmitLhdnInvoiceResponse =
    | AccessError
    | InternalError
    | LhdnInvoiceSubmitted;

export type SyncEventsRequest = {
    events: Array<EventInput>;
    tenantId: Scalars["ID"]["input"];
};

export type SyncEventsResponse = AccessError | EventsSynced;

export type SyncUserProfileToFirebaseResponse =
    | AccessError
    | NotFoundError
    | UserProfileSyncedToFirebase;

export type Tag = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    name: Scalars["String"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type TagArchived = {
    tag: Tag;
};

export type TagCreated = {
    tag: Tag;
};

export type TagEdited = {
    tag: Tag;
};

export type TaxPayerTinValidated = {
    idType: IdType;
    idValue: Scalars["String"]["output"];
    tin: Scalars["String"]["output"];
};

export type Trigger = {
    created: Scalars["DateTime"]["output"];
    light: Light;
    metadata: Scalars["JSONString"]["output"];
    resourceId: Scalars["ID"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type TriggerArchived = {
    trigger: Trigger;
};

export type TriggerCreated = {
    trigger: Trigger;
};

export type UnableToAddServiceResource = {
    message: Scalars["String"]["output"];
};

export type UnableToArchiveResource = {
    reason: Scalars["String"]["output"];
};

export type UnableToArchiveResourceType = {
    reason: Scalars["String"]["output"];
};

export type UnableToChangeResourceType = {
    reason: Scalars["String"]["output"];
};

export type UnapproveOrganisationRequest = {
    organisationId: Scalars["ID"]["input"];
};

export type UnapproveOrganisationResponse =
    | AccessError
    | NotFoundError
    | OrganisationUnapproved;

export type UnavailableBookingSlot = {
    endDt: Scalars["DateTime"]["output"];
    metadata: Scalars["JSONString"]["output"];
    reason: ServiceUnavailabilityReason;
    selectedResourceIds: Array<Scalars["ID"]["output"]>;
    serviceId?: Maybe<Scalars["ID"]["output"]>;
    startDt: Scalars["DateTime"]["output"];
};

export type UnavailableResourceSlot = {
    endDt: Scalars["DateTime"]["output"];
    resourceId: Scalars["ID"]["output"];
    startDt: Scalars["DateTime"]["output"];
    tenantId: Scalars["ID"]["output"];
};

export type UncancelInvoiceRequest = {
    invoiceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UncancelInvoiceResponse =
    | AccessError
    | InvoiceUncancelled
    | NotFoundError;

export type UncancelPaymentRequest = {
    invoiceId: Scalars["ID"]["input"];
    paymentId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UncancelPaymentResponse =
    | AccessError
    | NotFoundError
    | PaymentUncancelled;

export type UnconfirmBookingRequest = {
    bookingId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UnconfirmBookingResponse =
    | AccessError
    | BookingAlreadyCancelledError
    | BookingAlreadyUnconfirmedError
    | BookingUnconfirmed
    | NotFoundError;

export type UnhandledRuleTypeError = {
    message: Scalars["String"]["output"];
};

export type UpdateBookingMetadataRequest = {
    bookingId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UpdateBookingMetadataResponse =
    | AccessError
    | BookingMetadataUpdated
    | NotFoundError;

export type UpdateBookingReferenceIdRequest = {
    bookingId: Scalars["ID"]["input"];
    referenceId: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UpdateBookingReferenceIdResponse =
    | AccessError
    | BookingAlreadyConfirmedError
    | BookingReferenceIdUpdated
    | NotFoundError;

export type UpdateBookingsReferenceIdRequest = {
    bookingIds: Array<Scalars["ID"]["input"]>;
    referenceId: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UpdateBookingsReferenceIdResponse =
    | AccessError
    | BookingsReferenceIdUpdated
    | NotFoundError;

export type UpdateCategoryMetadataRequest = {
    categoryId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
};

export type UpdateCategoryMetadataResponse =
    | AccessError
    | CategoryMetadataUpdated
    | NotFoundError;

export type UpdateCustomerMetadataRequest = {
    customerId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UpdateCustomerMetadataResponse =
    | AccessError
    | CustomerMetadataUpdated
    | NotFoundError;

export type UpdateFixedDurationOptionsRequest = {
    options: Array<DurationOptionInput>;
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UpdateFixedDurationOptionsResponse =
    | AccessError
    | FixedDurationOptionsUpdated;

export type UpdateInvoiceCourtsiteOwnershipRequest = {
    invoiceId: Scalars["ID"]["input"];
    isCourtsiteOwned: Scalars["Boolean"]["input"];
    settlementRate?: InputMaybe<Scalars["Float"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type UpdateInvoiceCourtsiteOwnershipResponse =
    | AccessError
    | InvoiceCourtsiteOwnershipRequestError
    | InvoiceCourtsiteOwnershipUpdated
    | NotFoundError;

export type UpdateInvoiceItemRelatedEntityRequest = {
    entityId: Scalars["ID"]["input"];
    entityType: InvoiceItemRelatedEntityType;
    invoiceId: Scalars["ID"]["input"];
    invoiceItemId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UpdateInvoiceItemRelatedEntityResponse =
    | AccessError
    | InvoiceItemRelatedEntityUpdated
    | NotFoundError;

export type UpdateInvoiceMetadataRequest = {
    invoiceId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UpdateInvoiceMetadataResponse =
    | AccessError
    | InvoiceMetadataUpdated
    | NotFoundError;

export type UpdateLightRequest = {
    boardId: Scalars["ID"]["input"];
    disableToggling: Scalars["Boolean"]["input"];
    isUserActivated: Scalars["Boolean"]["input"];
    lightId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    relayId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UpdateLightResponse = AccessError | LightUpdated | NotFoundError;

export type UpdateMyProfileEmailRequest = {
    email: Scalars["EmailAddress"]["input"];
};

export type UpdateMyProfileEmailResponse =
    | AccessError
    | InvalidValue
    | NotFoundError
    | UserProfileSet;

export type UpdateMyProfilePhoneNumberRequest = {
    phoneNumber: Scalars["PhoneNumber"]["input"];
};

export type UpdateMyProfilePhoneNumberResponse =
    | AccessError
    | InvalidValue
    | NotFoundError
    | UserProfileSet;

export type UpdateOrganisationRequest = {
    latitude: Scalars["Float"]["input"];
    longitude: Scalars["Float"]["input"];
    metadata: Scalars["JSONString"]["input"];
    organisationId: Scalars["ID"]["input"];
    organisationName: Scalars["String"]["input"];
    services: Array<Scalars["String"]["input"]>;
};

export type UpdateOrganisationResponse =
    | AccessError
    | NotFoundError
    | OrganisationUpdated;

export type UpdateOverrideRequest = {
    end: Scalars["DateTime"]["input"];
    overrideId: Scalars["ID"]["input"];
    start: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UpdateOverrideResponse =
    | AccessError
    | NotFoundError
    | OverrideUpdated;

export type UpdateResourceTypesMetadataRequests = {
    requests: Array<ResourceTypesMetadataInput>;
    tenantId: Scalars["ID"]["input"];
};

export type UpdateResourceTypesMetadataResponse =
    | AccessError
    | NotFoundError
    | ResourceTypesMetadataUpdated;

export type UpdateScheduleResponse =
    | AccessError
    | NotFoundError
    | ScheduleUpdated;

export type UpdateServiceMetadataRequest = {
    metadata: Scalars["JSONString"]["input"];
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UpdateServiceMetadataResponse =
    | AccessError
    | NotFoundError
    | ServiceMetadataUpdated;

export type UpdateServiceVisibilityRequest = {
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
    visibility: ServiceVisibility;
};

export type UpdateServiceVisibilityResponse =
    | AccessError
    | NotFoundError
    | ServiceVisibilityUpdated;

export type UpdateUserRequest = {
    displayName: Scalars["String"]["input"];
    phoneNumber: Scalars["String"]["input"];
};

export type UpdateUserResponse = AccessError | InternalError | UserUpdated;

export type UpdatedScheduleRequest = {
    daytimeSlots: Array<DayTimeSlotInput>;
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    rules: Array<RuleInput>;
    scheduleId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UserAlreadyExists = {
    message: Scalars["String"]["output"];
};

export type UserProfile = {
    dateOfBirth?: Maybe<Scalars["Date"]["output"]>;
    email?: Maybe<Scalars["EmailAddress"]["output"]>;
    firebaseId: Scalars["ID"]["output"];
    gender?: Maybe<Gender>;
    joined?: Maybe<Scalars["DateTime"]["output"]>;
    name: Scalars["String"]["output"];
    otherPreferredSports?: Maybe<Scalars["String"]["output"]>;
    phoneNumber?: Maybe<Scalars["PhoneNumber"]["output"]>;
    postCode?: Maybe<Scalars["String"]["output"]>;
    preferredSports?: Maybe<Array<Scalars["String"]["output"]>>;
    sportsFrequency?: Maybe<Scalars["Int"]["output"]>;
};

export type UserProfileCreated = {
    user: UserProfile;
};

export type UserProfileSet = {
    user: UserProfile;
};

export type UserProfileSyncedToFirebase = {
    user: UserProfile;
};

export type UserUpdated = {
    displayName: Scalars["String"]["output"];
    phoneNumber: Scalars["String"]["output"];
};

export type ValidateTaxPayerTinResponse =
    | AccessError
    | InternalError
    | TaxPayerTinValidated;

export type WorkflowCancelled = {
    message: Scalars["String"]["output"];
};

export type WorkflowExpired = {
    message: Scalars["String"]["output"];
};

export type WorkflowInfo = {
    runId: Scalars["ID"]["output"];
    workflowId: Scalars["ID"]["output"];
};

export type WorkflowListPage = {
    executions: Array<WorkflowInfo>;
    nextPageToken: Scalars["ID"]["output"];
};

export type WorkflowNotFound = {
    message: Scalars["String"]["output"];
};

export type WorkflowNotReady = {
    message: Scalars["String"]["output"];
};

export type ArchiveMemberMutationVariables = Exact<{
    request: ArchiveMemberRequest;
}>;

export type ArchiveMemberMutation = {
    archiveMember:
        | { __typename: "AccessError"; message: string }
        | { __typename: "CannotModifySelfError"; message: string }
        | { __typename: "MemberAlreadyArchivedError"; message: string }
        | { __typename: "MemberArchived"; memberId: string }
        | { __typename: "NotFoundError"; message: string };
};

export type ArchiveResourceTypeMutationVariables = Exact<{
    request: ArchiveResourceTypeRequest;
}>;

export type ArchiveResourceTypeMutation = {
    archiveResourceType:
        | { __typename: "AccessError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | { __typename: "ResourceTypeArchived"; resourceTypeId: string }
        | { __typename: "UnableToArchiveResourceType"; reason: string };
};

export type ArchiveCustomerMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    customerId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type ArchiveCustomerMutation = {
    archiveCustomer:
        | { __typename: "AccessError"; message: string }
        | { __typename: "CustomerAlreadyArchived" }
        | { __typename: "CustomerArchived" }
        | { __typename: "NotFoundError"; message: string };
};

export type CreateCustomerMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    tags?: InputMaybe<Array<CustomerTagInput>>;
    metadata?: InputMaybe<Scalars["JSONString"]["input"]>;
}>;

export type CreateCustomerMutation = {
    createCustomer:
        | { __typename: "AccessError"; message: string }
        | { __typename: "CustomerCreated" }
        | { __typename: "InternalError"; message: string };
};

export type EditCustomerInfoMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    customerId?: InputMaybe<Scalars["ID"]["input"]>;
    name: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    tags?: InputMaybe<Array<CustomerTagInput>>;
    metadata?: InputMaybe<Scalars["JSONString"]["input"]>;
}>;

export type EditCustomerInfoMutation = {
    editCustomerInfo:
        | { __typename: "AccessError"; message: string }
        | { __typename: "CustomerInfoEdited" }
        | { __typename: "NotFoundError"; message: string };
};

export type SetMemberCapabilitiesMutationVariables = Exact<{
    request: SetMemberCapabilitiesRequest;
}>;

export type SetMemberCapabilitiesMutation = {
    setMemberCapabilities:
        | { __typename: "AccessError"; message: string }
        | { __typename: "CannotModifySelfError"; message: string }
        | { __typename: "MemberAlreadyArchivedError"; message: string }
        | { __typename: "MemberCapabilitiesSet"; member: { uid: string } }
        | { __typename: "NotFoundError"; message: string };
};

export type CreateTagMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
}>;

export type CreateTagMutation = {
    createTag:
        | { __typename: "AccessError"; message: string }
        | { __typename: "TagCreated" };
};

export type ArchiveTagMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    tagId: Scalars["ID"]["input"];
}>;

export type ArchiveTagMutation = {
    archiveTag:
        | { __typename: "AccessError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | { __typename: "TagArchived" };
};

export type EditTagMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    tagId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
}>;

export type EditTagMutation = {
    editTag:
        | { __typename: "AccessError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | { __typename: "TagEdited" };
};

export type KonsolDeleteWebBookingInvoicesQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    bookingId: Scalars["String"]["input"];
}>;

export type KonsolDeleteWebBookingInvoicesQuery = {
    invoices: Array<{ uid: string; metadata: string }>;
};

export type KonsolDeleteWebBookingPaymentCaptureMutationVariables = Exact<{
    workflowId: Scalars["ID"]["input"];
    bookingIds: Array<Scalars["ID"]["input"]>;
}>;

export type KonsolDeleteWebBookingPaymentCaptureMutation = {
    cancelCapturePaymentWorkflow:
        | { __typename: "CapturePaymentCancelled" }
        | { __typename: "WorkflowNotFound"; message: string }
        | { __typename: "WorkflowNotReady"; message: string };
};

export type ModifyAddOnMutationVariables = Exact<{
    request: ModifyAddOnRequest;
}>;

export type ModifyAddOnMutation = {
    modifyAddOn:
        | { __typename: "AccessError" }
        | { __typename: "AddOnAlreadyArchived" }
        | { __typename: "AddOnModified" }
        | { __typename: "NotFoundError" };
};

export type QuotationQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    setFor: Scalars["ID"]["input"];
    start: Scalars["DateTime"]["input"];
    end: Scalars["DateTime"]["input"];
}>;

export type QuotationQuery = {
    quotation: { price: number; timeFullyAccounted: boolean };
};

export type KonsolCreateDailyBookingContactsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
    email?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type KonsolCreateDailyBookingContactsQuery = {
    customers: Array<{
        uid: string;
        name: string;
        phoneNumber?: string | null;
        email?: string | null;
        metadata?: string | null;
    }>;
};

export type KonsolBookingCreateFormGuestFormItemQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
    email?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type KonsolBookingCreateFormGuestFormItemQuery = {
    customers: Array<{
        uid: string;
        name: string;
        phoneNumber?: string | null;
        email?: string | null;
        metadata?: string | null;
    }>;
};

export type MakeBookingsMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    serviceId: Scalars["ID"]["input"];
    slots: Array<BookingSlotRequest>;
    guest: BookingGuestRequest;
    isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type MakeBookingsMutation = {
    makeBookings:
        | { __typename: "AccessError"; message: string }
        | { __typename: "BookingsMade"; bookingIds: Array<string> }
        | {
              __typename: "BookingsUnavailable";
              reason: ServiceUnavailabilityReason;
          }
        | { __typename: "InternalError" };
};

export type BookingByIdQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    bookingId: Scalars["ID"]["input"];
}>;

export type BookingByIdQuery = {
    booking: {
        uid: string;
        startDt: string;
        endDt: string;
        confirmed?: string | null;
        metadata: string;
        resources: Array<{
            uid: string;
            archived?: string | null;
            resource: { uid: string; name: string };
        }>;
        service?: {
            uid: string;
            startTime?: string | null;
            endTime?: string | null;
            serviceMode: string;
            resources: Array<{
                uid: string;
                archived?: string | null;
                resource: { uid: string; name: string };
            }>;
        } | null;
        guest?: {
            uid: string;
            name: string;
            email?: string | null;
            phoneNumber?: string | null;
        } | null;
    };
};

export type EditBookingInvoiceQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    filter?: InputMaybe<InvoiceFilter>;
}>;

export type EditBookingInvoiceQuery = {
    invoices: Array<{ uid: string; outstandingBalance: number }>;
};

export type RecurringEditBookingIdsQueryQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    bookingIds: Array<Scalars["ID"]["input"]>;
}>;

export type RecurringEditBookingIdsQueryQuery = {
    bookingsByIds: Array<{
        uid: string;
        startDt: string;
        endDt: string;
        metadata: string;
        confirmed?: string | null;
        guest?: {
            uid: string;
            name: string;
            email?: string | null;
            phoneNumber?: string | null;
        } | null;
        resources: Array<{
            archived?: string | null;
            resource: { uid: string; name: string };
        }>;
        service?: {
            uid: string;
            resources: Array<{
                archived?: string | null;
                resource: { uid: string; name: string };
            }>;
        } | null;
    }>;
};

export type GetCustomersQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    after?: InputMaybe<Scalars["ID"]["input"]>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
    email?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetCustomersQuery = {
    customers: Array<{
        uid: string;
        name: string;
        phoneNumber?: string | null;
        email?: string | null;
        metadata?: string | null;
    }>;
};

export type StartRescheduleRecurringBookingsMutationVariables = Exact<{
    tenantId: Scalars["String"]["input"];
    bookingGroupModifications: Array<RescheduleRecurringBookingsModificationsInput>;
}>;

export type StartRescheduleRecurringBookingsMutation = {
    startRescheduleRecurringBookings:
        | { __typename: "RecurringBookingsRescheduled" }
        | { __typename: "WorkflowCancelled" }
        | { __typename: "WorkflowNotReady" };
};

export type StartKonsolPayInvoiceMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    invoiceId: Scalars["ID"]["input"];
    isOnlinePayment: Scalars["Boolean"]["input"];
    redirectUrl: Scalars["String"]["input"];
    paymentMethodId: Scalars["ID"]["input"];
}>;

export type StartKonsolPayInvoiceMutation = {
    startKonsolPayInvoice:
        | { __typename: "KonsolPayInvoiceCompleted" }
        | { __typename: "WorkflowCancelled"; message: string }
        | { __typename: "WorkflowNotReady"; message: string };
};

export type CreateInvoiceMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    items: Array<CreateInvoiceItemRequest>;
    invoiceeName: Scalars["String"]["input"];
    invoiceePhoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    invoiceeEmail?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    metadata: Scalars["JSONString"]["input"];
}>;

export type CreateInvoiceMutation = {
    createInvoice:
        | { __typename: "AccessError"; message: string }
        | { __typename: "InternalError"; message: string }
        | { __typename: "InvoiceCreated" };
};

export type KonsolInvoiceCreateFormUseCustomersQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
    email?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type KonsolInvoiceCreateFormUseCustomersQuery = {
    customers: Array<{
        uid: string;
        name: string;
        phoneNumber?: string | null;
        email?: string | null;
        metadata?: string | null;
    }>;
};

export type KonsolOfflinePaymentLinkPaymentQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type KonsolOfflinePaymentLinkPaymentQuery = {
    paymentMethods: Array<{
        uid: string;
        name: string;
        availability: boolean;
        archived?: string | null;
    }>;
};

export type KonsolPayPaymentLinkOfflineMutationVariables = Exact<{
    workflowId: Scalars["ID"]["input"];
    paymentMethodId: Scalars["ID"]["input"];
}>;

export type KonsolPayPaymentLinkOfflineMutation = {
    provideCapturePaymentProviderDetails:
        | {
              __typename: "CapturePaymentProviderDetailsProvided";
              state: { state: string; completed?: string | null };
          }
        | { __typename: "WorkflowCancelled"; message: string }
        | { __typename: "WorkflowNotFound"; message: string }
        | { __typename: "WorkflowNotReady"; message: string };
};

export type KonsolApproveOrgMutationVariables = Exact<{
    request: ApproveOrganisationRequest;
}>;

export type KonsolApproveOrgMutation = {
    approveOrganisation:
        | { __typename: "AccessError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | {
              __typename: "OrganisationApproved";
              organisation: { uid: string; approved?: string | null };
          };
};

export type KonsolUnapproveOrgMutationVariables = Exact<{
    request: UnapproveOrganisationRequest;
}>;

export type KonsolUnapproveOrgMutation = {
    unapproveOrganisation:
        | { __typename: "AccessError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | {
              __typename: "OrganisationUnapproved";
              organisation: { uid: string; approved?: string | null };
          };
};

export type KonsolSetOrgIsCourtsitePartnerMutationVariables = Exact<{
    request: SetOrganisationIsCourtsitePartnerRequest;
}>;

export type KonsolSetOrgIsCourtsitePartnerMutation = {
    setOrganisationIsCourtsitePartner:
        | { __typename: "AccessError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | {
              __typename: "OrganisationIsCourtsitePartnerSet";
              organisation: { uid: string; isCourtsitePartner: boolean };
          };
};

export type UpdateOrganisationMutationVariables = Exact<{
    request: UpdateOrganisationRequest;
}>;

export type UpdateOrganisationMutation = {
    updateOrganisation:
        | { __typename: "AccessError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | { __typename: "OrganisationUpdated"; organisationId: string };
};

export type OrgImageUploaderCategoriesQueryQueryVariables = Exact<{
    [key: string]: never;
}>;

export type OrgImageUploaderCategoriesQueryQuery = {
    categories: Array<{ uid: string; name: string }>;
};

export type EditOrganisationServiceCategoriesQueryQueryVariables = Exact<{
    [key: string]: never;
}>;

export type EditOrganisationServiceCategoriesQueryQuery = {
    categories: Array<{ uid: string; name: string }>;
};

export type CreateOrganisationMutationVariables = Exact<{
    organisationName: Scalars["String"]["input"];
    latitude: Scalars["Float"]["input"];
    longitude: Scalars["Float"]["input"];
    services: Array<Scalars["String"]["input"]>;
    ownerName: Scalars["String"]["input"];
    metadata: Scalars["JSONString"]["input"];
}>;

export type CreateOrganisationMutation = {
    createOrganisation:
        | { __typename: "AccessError"; message: string }
        | { __typename: "OrganisationCreated"; organisationId: string };
};

export type KonsolNewOrgFormQueryVariables = Exact<{ [key: string]: never }>;

export type KonsolNewOrgFormQuery = {
    categories: Array<{ uid: string; name: string }>;
};

export type AddMemberToOrganisationWithoutFirebaseMutationVariables = Exact<{
    request: AddMemberToOrganisationWithoutFirebaseRequest;
}>;

export type AddMemberToOrganisationWithoutFirebaseMutation = {
    addMemberToOrganisationWithoutFirebase:
        | { __typename: "AccessError"; message: string }
        | { __typename: "MemberAlreadyAddedError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | { __typename: "OrganisationMemberAdded"; memberId: string };
};

export type UserProfileFromEmailPhoneNumberQueryVariables = Exact<{
    query: Scalars["String"]["input"];
}>;

export type UserProfileFromEmailPhoneNumberQuery = {
    userProfileFromEmailPhoneNumber?: {
        firebaseId: string;
        name: string;
        email?: string | null;
        phoneNumber?: string | null;
    } | null;
};

export type AddPaymentMethodMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
    metadata: Scalars["JSONString"]["input"];
    availability: Scalars["Boolean"]["input"];
}>;

export type AddPaymentMethodMutation = {
    addPaymentMethod:
        | { __typename: "AccessError"; message: string }
        | { __typename: "PaymentMethodAdded" };
};

export type EditPaymentMethodMetadataMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    paymentMethodId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
}>;

export type EditPaymentMethodMetadataMutation = {
    editPaymentMethodMetadata:
        | { __typename: "AccessError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | {
              __typename: "PaymentMethodMetadataEdited";
              paymentMethodId: string;
          };
};

export type EditPaymentMethodNameMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    paymentMethodId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
}>;

export type EditPaymentMethodNameMutation = {
    editPaymentMethodName:
        | { __typename: "AccessError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | { __typename: "PaymentMethodNameEdited"; paymentMethodId: string };
};

export type CreatePriceRulesetsForServicesMutationVariables = Exact<{
    request: CreatePriceRulesetsForServicesRequest;
}>;

export type CreatePriceRulesetsForServicesMutation = {
    createPriceRulesetsForServices?:
        | { __typename: "AccessError"; message: string }
        | { __typename: "MissingPriceRules"; message: string }
        | { __typename: "MissingService"; message: string }
        | { __typename: "PriceRulesetsForServicesCreated" }
        | null;
};

export type KonsolPriceRuleSetEditFormQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    setFor: Scalars["ID"]["input"];
    at: Scalars["DateTime"]["input"];
}>;

export type KonsolPriceRuleSetEditFormQuery = {
    effectivePriceRuleset: {
        uid: string;
        tenantId: string;
        effectiveFrom: string;
        appliesTo: string;
        created: string;
        metadata: string;
        rules: Array<
            | {
                  uid: string;
                  rulesetId: string;
                  created: string;
                  metadata: string;
                  deposit: number;
                  depositType: DepositType;
                  rates: Array<{
                      minimumDurationMinutes: number;
                      perHourRate: number;
                  }>;
                  start: {
                      hour: number;
                      minute: number;
                      hourOffset: number;
                      isoWeekday: number;
                  };
                  end: {
                      hour: number;
                      minute: number;
                      hourOffset: number;
                      isoWeekday: number;
                  };
              }
            | {
                  uid: string;
                  created: string;
                  perDayRate: number;
                  rulesetId: string;
                  metadata: string;
                  deposit: number;
                  depositType: DepositType;
                  start: {
                      hour: number;
                      minute: number;
                      hourOffset: number;
                      isoWeekday: number;
                  };
              }
            | {
                  uid: string;
                  rulesetId: string;
                  created: string;
                  metadata: string;
                  deposit: number;
                  depositType: DepositType;
              }
        >;
    };
};

export type ChangeResourceTypeMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    resourceId: Scalars["ID"]["input"];
    resourceTypeId: Scalars["ID"]["input"];
}>;

export type ChangeResourceTypeMutation = {
    changeResourceType:
        | { __typename: "AccessError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | { __typename: "ResourceTypeChanged"; resourceTypeId: string }
        | { __typename: "UnableToChangeResourceType"; reason: string };
};

export type CreateResourceMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
    resourceTypeId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
}>;

export type CreateResourceMutation = {
    createResource:
        | { __typename: "AccessError" }
        | { __typename: "NotFoundError" }
        | { __typename: "ResourceCreated" };
};

export type RenameResourceMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    resourceId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
}>;

export type RenameResourceMutation = {
    renameResource:
        | { __typename: "AccessError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | { __typename: "ResourceAlreadyArchived" }
        | { __typename: "ResourceRenamed" };
};

export type CreateResourceTypeMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
}>;

export type CreateResourceTypeMutation = {
    createResourceType:
        | { __typename: "AccessError" }
        | { __typename: "ResourceTypeCreated"; resourceTypeId: string };
};

export type UpdateResourceTypesMetadataMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    requests: Array<ResourceTypesMetadataInput>;
}>;

export type UpdateResourceTypesMetadataMutation = {
    updateResourceTypesMetadata:
        | { __typename: "AccessError" }
        | { __typename: "NotFoundError" }
        | { __typename: "ResourceTypesMetadataUpdated" };
};

export type CreateServiceMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    categoryId?: InputMaybe<Scalars["ID"]["input"]>;
    name: Scalars["String"]["input"];
    resources: Array<ServiceResourceInput>;
    metadata: Scalars["JSONString"]["input"];
}>;

export type CreateServiceMutation = {
    createService:
        | { __typename: "AccessError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | { __typename: "ServiceCreated"; serviceId: string };
};

export type CreateDailyServiceMutationVariables = Exact<{
    request: CreateDailyServiceRequest;
}>;

export type CreateDailyServiceMutation = {
    createDailyService?:
        | { __typename: "AccessError"; message: string }
        | { __typename: "DailyServiceCreated"; serviceId: string }
        | { __typename: "NotFoundError"; message: string }
        | null;
};

export type CreateOverrideMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    lightId: Scalars["ID"]["input"];
    state: LightState;
    start: Scalars["DateTime"]["input"];
    end: Scalars["DateTime"]["input"];
    metadata: Scalars["JSONString"]["input"];
}>;

export type CreateOverrideMutation = {
    createOverride?:
        | { __typename: "AccessError"; message: string }
        | { __typename: "InternalError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | { __typename: "OverrideCreated" }
        | null;
};

export type CreateTriggerMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    lightId: Scalars["ID"]["input"];
    resourceId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
}>;

export type CreateTriggerMutation = {
    createTrigger?:
        | { __typename: "AccessError"; message: string }
        | { __typename: "InternalError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | { __typename: "TriggerCreated" }
        | null;
};

export type KonsolTriggerCreateFormQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type KonsolTriggerCreateFormQuery = {
    resourceTypes: Array<{
        uid: string;
        name: string;
        metadata: string;
        resources: Array<{ uid: string; name: string }>;
    }>;
};

export type StartKonsolCheckoutMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    bookingIds: Array<Scalars["ID"]["input"]>;
    isOnlinePayment: Scalars["Boolean"]["input"];
    redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
    paymentMethodId?: InputMaybe<Scalars["ID"]["input"]>;
    invoiceeName: Scalars["String"]["input"];
    invoiceePhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
    invoiceeEmail?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type StartKonsolCheckoutMutation = {
    startKonsolCheckout:
        | { message: string }
        | {
              state: {
                  id: string;
                  state: string;
                  invoiceId?: string | null;
                  bookingIds: Array<string>;
              };
          }
        | { message: string }
        | { message: string };
};

export type StartKonsolCreateInvoiceMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    bookingIds: Array<Scalars["ID"]["input"]>;
    invoiceeName: Scalars["String"]["input"];
    invoiceePhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
    invoiceeEmail?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type StartKonsolCreateInvoiceMutation = {
    startKonsolCreateInvoice:
        | {
              state: {
                  id: string;
                  state: string;
                  invoiceId?: string | null;
                  bookingIds: Array<string>;
              };
          }
        | { message: string }
        | { message: string };
};

export type KonsolCartBookingsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    bookingIds: Array<Scalars["ID"]["input"]>;
}>;

export type KonsolCartBookingsQuery = {
    bookingsByIds: Array<{
        uid: string;
        serviceId?: string | null;
        startDt: string;
        endDt: string;
        created: string;
        confirmed?: string | null;
        metadata: string;
        cancelled?: string | null;
        resources: Array<{
            uid: string;
            resourceId: string;
            created: string;
            archived?: string | null;
            resource: { uid: string; name: string };
        }>;
        service?: { uid: string; serviceMode: string } | null;
        guest?: {
            uid: string;
            name: string;
            phoneNumber?: string | null;
            email?: string | null;
        } | null;
    }>;
};

export type GetInvoiceQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    uid: Scalars["ID"]["input"];
}>;

export type GetInvoiceQuery = {
    invoice: {
        uid: string;
        printNumber?: number | null;
        invoiceeName: string;
        invoiceeEmail?: string | null;
        invoiceePhoneNumber?: string | null;
        outstandingBalance: number;
        total: number;
        cancelled?: string | null;
        created: string;
        isCourtsiteOwned: boolean;
        settlementRate?: number | null;
        metadata: string;
        items: Array<{
            uid: string;
            created: string;
            description: string;
            quantity: number;
            subtotal: number;
            metadata: string;
            relatedEntity?: {
                entityId: string;
                entityType: InvoiceItemRelatedEntityType;
            } | null;
        }>;
        payments: Array<{
            uid: string;
            amount: number;
            paymentType?: string | null;
            created: string;
            paymentMethod?: { uid: string; name: string } | null;
        }>;
    };
};

export type StartCancelPaymentWorkflowMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    invoiceId: Scalars["ID"]["input"];
    paymentId: Scalars["ID"]["input"];
}>;

export type StartCancelPaymentWorkflowMutation = {
    startCancelPaymentWorkflow:
        | { __typename: "CancelPaymentWorkflowCompleted" }
        | {
              __typename: "CancelPaymentWorkflowPaymentNotCancellable";
              message: string;
          }
        | { __typename: "WorkflowCancelled"; message: string }
        | { __typename: "WorkflowNotReady"; message: string };
};

export type CancelKonsolCheckoutMutationVariables = Exact<{
    workflowId: Scalars["ID"]["input"];
}>;

export type CancelKonsolCheckoutMutation = {
    cancelKonsolCheckout:
        | { __typename: "KonsolCheckoutNotCancellable"; message: string }
        | { __typename: "WorkflowCancelled"; message: string }
        | { __typename: "WorkflowNotFound"; message: string };
};

export type CancelCapturePaymentWorkflowMutationVariables = Exact<{
    request: CancelCapturePaymentWorkflowRequest;
}>;

export type CancelCapturePaymentWorkflowMutation = {
    cancelCapturePaymentWorkflow:
        | { __typename: "CapturePaymentCancelled" }
        | { __typename: "WorkflowNotFound"; message: string }
        | { __typename: "WorkflowNotReady"; message: string };
};

export type StartKonsolCancelInvoiceMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    invoiceId: Scalars["ID"]["input"];
}>;

export type StartKonsolCancelInvoiceMutation = {
    startKonsolCancelInvoice:
        | { __typename: "KonsolCancelInvoiceCompleted" }
        | { __typename: "WorkflowCancelled"; message: string }
        | { __typename: "WorkflowNotReady"; message: string };
};

export type CancelKonsolPayInvoiceMutationVariables = Exact<{
    workflowId: Scalars["ID"]["input"];
}>;

export type CancelKonsolPayInvoiceMutation = {
    cancelKonsolPayInvoice:
        | { __typename: "KonsolPayInvoiceNotCancellable"; message: string }
        | { __typename: "WorkflowCancelled"; message: string }
        | { __typename: "WorkflowNotFound"; message: string };
};

export type KonsolCapturePaymentInvoiceQueryVariables = Exact<{
    capturePaymentWorkflowId: Scalars["ID"]["input"];
}>;

export type KonsolCapturePaymentInvoiceQuery = {
    capturePaymentStateById: {
        cancelled?: string | null;
        completed?: string | null;
        expired?: string | null;
        id: string;
    };
};

export type KonsolUseBookingsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    bookingIds: Array<Scalars["ID"]["input"]>;
}>;

export type KonsolUseBookingsQuery = {
    bookingsByIds: Array<{
        uid: string;
        startDt: string;
        endDt: string;
        metadata: string;
        referenceId: string;
        bookingSource?: BookingSource | null;
        service?: { uid: string; name: string; serviceMode: string } | null;
        resources: Array<{
            uid: string;
            archived?: string | null;
            resource: { uid: string; name: string };
        }>;
    }>;
};

export type GetRecurringBookingsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    recurringId: Scalars["ID"]["input"];
    dateRange?: InputMaybe<DateRangeFilter>;
}>;

export type GetRecurringBookingsQuery = {
    bookings: {
        edges: Array<{
            node: {
                uid: string;
                startDt: string;
                endDt: string;
                confirmed?: string | null;
                cancelled?: string | null;
                resources: Array<{
                    archived?: string | null;
                    resource: { uid: string; name: string };
                }>;
            };
        }>;
    };
};

export type KonsolSingleCheckoutModalStartCheckoutMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    bookingIds: Array<Scalars["ID"]["input"]>;
    isOnlinePayment: Scalars["Boolean"]["input"];
    redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
    paymentMethodId?: InputMaybe<Scalars["ID"]["input"]>;
    invoiceeName: Scalars["String"]["input"];
    invoiceePhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
    invoiceeEmail?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type KonsolSingleCheckoutModalStartCheckoutMutation = {
    startKonsolCheckout:
        | { __typename: "KonsolCheckoutInvalidBooking"; message: string }
        | {
              __typename: "KonsolCheckoutStarted";
              state: { id: string; state: string; invoiceId?: string | null };
          }
        | { __typename: "WorkflowCancelled"; message: string }
        | { __typename: "WorkflowNotReady"; message: string };
};

export type KonsolSingleCheckoutModalCreateInvoiceMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    bookingIds: Array<Scalars["ID"]["input"]>;
    invoiceeName: Scalars["String"]["input"];
    invoiceePhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
    invoiceeEmail?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type KonsolSingleCheckoutModalCreateInvoiceMutation = {
    startKonsolCreateInvoice:
        | {
              __typename: "KonsolCreateInvoiceCompleted";
              state: { id: string; state: string; invoiceId?: string | null };
          }
        | { __typename: "WorkflowCancelled"; message: string }
        | { __typename: "WorkflowNotReady"; message: string };
};

export type KonsolPreviewOrgTemplateQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type KonsolPreviewOrgTemplateQuery = {
    onlineServices: Array<{
        uid: string;
        name: string;
        serviceMode: string;
        metadata: string;
        serviceCategory?: { uid: string; categoryId: string } | null;
        resources: Array<{
            uid: string;
            resourceId: string;
            archived?: string | null;
        }>;
    }>;
    categories: Array<{ uid: string; name: string; metadata: string }>;
};

export type KonsolOrganisationLayoutQueryVariables = Exact<{
    [key: string]: never;
}>;

export type KonsolOrganisationLayoutQuery = {
    myOrganisations: Array<{
        uid: string;
        name: string;
        services: Array<string>;
        isCourtsitePartner: boolean;
        metadata: string;
        approved?: string | null;
        myCapabilities: Array<string>;
        location: { latitude: number; longitude: number };
    }>;
};

export type GetCartItemsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCartItemsQuery = {
    cartItems: Array<{ uid: string; tenantId: string }>;
};

export type CreateUserProfileMutationVariables = Exact<{
    name: Scalars["String"]["input"];
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
}>;

export type CreateUserProfileMutation = {
    createUserProfile:
        | { __typename: "AccessError"; message: string }
        | { __typename: "InvalidValue"; message: string }
        | { __typename: "UserAlreadyExists" }
        | { __typename: "UserProfileCreated" };
};

export type GetPaymentMethodsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type GetPaymentMethodsQuery = {
    paymentMethods: Array<{
        uid: string;
        name: string;
        metadata: string;
        availability: boolean;
        archived?: string | null;
        updated?: string | null;
    }>;
};

export type KonsolUseTagsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type KonsolUseTagsQuery = { tags: Array<{ uid: string; name: string }> };

export type CheckinBookingsListQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    filter?: InputMaybe<BookingFilter>;
}>;

export type CheckinBookingsListQuery = {
    bookings: {
        edges: Array<{
            node: {
                uid: string;
                startDt: string;
                endDt: string;
                referenceId: string;
                resources: Array<{
                    uid: string;
                    archived?: string | null;
                    resource: { name: string; uid: string };
                }>;
            };
        }>;
    };
};

export type KonsolDashboardQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type KonsolDashboardQuery = {
    services: Array<{
        uid: string;
        name: string;
        serviceMode: string;
        category?: { uid: string; name: string } | null;
    }>;
    resellerTenant: { isActive: boolean };
};

export type KonsolDashboardAnalyticsPageQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    start: Scalars["DateTime"]["input"];
    end: Scalars["DateTime"]["input"];
    dashboardType: MetabaseDashboardType;
    categoryId?: InputMaybe<Scalars["ID"]["input"]>;
    serviceIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
}>;

export type KonsolDashboardAnalyticsPageQuery = {
    metabaseDashboardUrl: string;
};

export type KonsolHargaServicesQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type KonsolHargaServicesQuery = {
    services: Array<{ uid: string; name: string; serviceMode: string }>;
};

export type KonsolHargaPriceQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    setFor: Scalars["ID"]["input"];
    at: Scalars["DateTime"]["input"];
}>;

export type KonsolHargaPriceQuery = {
    effectivePriceRuleset: { uid: string } & {
        " $fragmentRefs"?: {
            HargaCardPriceRulesetFragment: HargaCardPriceRulesetFragment;
            DailyHargaCardPriceRulesetFragment: DailyHargaCardPriceRulesetFragment;
        };
    };
};

export type DailyHargaCardPriceRulesetFragment = {
    effectiveFrom: string;
    rules: Array<
        | {
              __typename: "PriceRuleDurationRate";
              uid: string;
              deposit: number;
              depositType: DepositType;
          }
        | {
              __typename: "PriceRulePerDayRate";
              perDayRate: number;
              uid: string;
              deposit: number;
              depositType: DepositType;
              start: {
                  hour: number;
                  minute: number;
                  hourOffset: number;
                  isoWeekday: number;
              };
          }
        | {
              __typename: "PriceRulePerUnitRate";
              uid: string;
              deposit: number;
              depositType: DepositType;
          }
    >;
} & { " $fragmentName"?: "DailyHargaCardPriceRulesetFragment" };

export type HargaCardPriceRulesetFragment = {
    effectiveFrom: string;
    rules: Array<
        | {
              __typename: "PriceRuleDurationRate";
              uid: string;
              deposit: number;
              depositType: DepositType;
              start: {
                  hour: number;
                  minute: number;
                  hourOffset: number;
                  isoWeekday: number;
              };
              end: {
                  hour: number;
                  minute: number;
                  hourOffset: number;
                  isoWeekday: number;
              };
              rates: Array<{
                  minimumDurationMinutes: number;
                  perHourRate: number;
              }>;
          }
        | {
              __typename: "PriceRulePerDayRate";
              uid: string;
              deposit: number;
              depositType: DepositType;
          }
        | {
              __typename: "PriceRulePerUnitRate";
              uid: string;
              deposit: number;
              depositType: DepositType;
          }
    >;
} & { " $fragmentName"?: "HargaCardPriceRulesetFragment" };

export type ServicesHargaQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type ServicesHargaQuery = {
    services: Array<{
        uid: string;
        name: string;
        created: string;
        metadata: string;
        visibility: ServiceVisibility;
        serviceMode: string;
        resources: Array<{
            uid: string;
            created: string;
            resourceId: string;
            optionKey: string;
            archived?: string | null;
        }>;
        category?: { uid: string; name: string } | null;
    }>;
};

export type KonsolInvoisInvoicesQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    filter?: InputMaybe<InvoiceFilter>;
    after?: InputMaybe<Scalars["ID"]["input"]>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type KonsolInvoisInvoicesQuery = {
    invoices: Array<{
        uid: string;
        invoiceeName: string;
        invoiceePhoneNumber?: string | null;
        total: number;
        amountPaid: number;
        outstandingBalance: number;
        created: string;
        printNumber?: number | null;
        cancelled?: string | null;
    }>;
};

export type AddOnsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type AddOnsQuery = {
    addOns: Array<{
        uid: string;
        name: string;
        price: number;
        isInStock: boolean;
        maxQuantity?: number | null;
    }>;
};

export type StockAddOnMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    uid: Scalars["ID"]["input"];
    isInStock: Scalars["Boolean"]["input"];
}>;

export type StockAddOnMutation = {
    setAddOnIsInStock:
        | { __typename: "AccessError" }
        | { __typename: "AddOnAlreadyArchived" }
        | { __typename: "AddOnIsInStockSet" }
        | { __typename: "NotFoundError" };
};

export type ArchiveAddOnMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    uid: Scalars["ID"]["input"];
}>;

export type ArchiveAddOnMutation = {
    archiveAddOn:
        | { __typename: "AccessError" }
        | { __typename: "AddOnAlreadyArchived" }
        | { __typename: "AddOnArchived" }
        | { __typename: "NotFoundError" };
};

export type CreateAddOnMutationVariables = Exact<{
    request: CreateAddOnRequest;
}>;

export type CreateAddOnMutation = {
    createAddOn:
        | { __typename: "AccessError"; message: string }
        | { __typename: "AddOnCreated" };
};

export type ConfirmedBookingsWithAddonsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    startDateBefore: Scalars["DateTime"]["input"];
    startDateAfter: Scalars["DateTime"]["input"];
}>;

export type ConfirmedBookingsWithAddonsQuery = {
    confirmedBookingsWithAddons?: Array<{
        uid: string;
        startDt: string;
        endDt: string;
        referenceId: string;
        addons?: Array<{
            uid: string;
            addonId: string;
            perUnitPrice: number;
            quantity: number;
        } | null> | null;
        service?: {
            uid: string;
            category?: { uid: string; name: string } | null;
        } | null;
        resources: Array<{
            uid: string;
            archived?: string | null;
            resource: { uid: string; name: string };
        }>;
    }> | null;
};

export type BookingAddOnsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type BookingAddOnsQuery = {
    addOns: Array<{ uid: string; name: string }>;
};

export type KonsolBookingsListQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    filter?: InputMaybe<BookingFilter>;
    after?: InputMaybe<Scalars["String"]["input"]>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type KonsolBookingsListQuery = {
    bookings: {
        edges: Array<{
            node: {
                uid: string;
                startDt: string;
                endDt: string;
                created: string;
                referenceId: string;
                bookingSource?: BookingSource | null;
                cancelledReason?: CancelledReason | null;
                metadata: string;
                confirmed?: string | null;
                cancelled?: string | null;
                resources: Array<{
                    uid: string;
                    resourceId: string;
                    created: string;
                    archived?: string | null;
                }>;
                service?: { uid: string; serviceMode: string } | null;
                activities?: Array<
                    | {
                          __typename: "BookingCancelledActivity";
                          occuredAt: string;
                          performedBy: string;
                          cancelledReason?: CancelledReason | null;
                          oldStartDt?: string | null;
                          newStartDt?: string | null;
                          oldEndDt?: string | null;
                          newEndDt?: string | null;
                          qOldResourceIds?: Array<string> | null;
                          qNewResourceIds?: Array<string> | null;
                      }
                    | {
                          __typename: "BookingConfirmedActivity";
                          occuredAt: string;
                          performedBy: string;
                      }
                    | {
                          __typename: "BookingCreatedActivity";
                          startDt: string;
                          endDt: string;
                          occuredAt: string;
                          performedBy: string;
                      }
                    | {
                          __typename: "BookingGuestChangedActivity";
                          oldName: string;
                          oldEmail?: string | null;
                          oldPhoneNumber?: string | null;
                          name: string;
                          email?: string | null;
                          phoneNumber?: string | null;
                          occuredAt: string;
                          performedBy: string;
                      }
                    | {
                          __typename: "BookingMetadataChangedActivity";
                          oldMetadata?: string | null;
                          changedMetadata: string;
                          occuredAt: string;
                          performedBy: string;
                      }
                    | {
                          __typename: "BookingReferenceIdChangedActivity";
                          referenceId: string;
                          occuredAt: string;
                          performedBy: string;
                      }
                    | {
                          __typename: "BookingRescheduledActivity";
                          startDt: string;
                          endDt: string;
                          oldStartDt?: string | null;
                          oldEndDt?: string | null;
                          oldResourceIds?: Array<string | null> | null;
                          newResourceIds?: Array<string | null> | null;
                          occuredAt: string;
                          performedBy: string;
                      }
                    | {
                          __typename: "BookingUnconfirmedActivity";
                          occuredAt: string;
                          performedBy: string;
                      }
                    | null
                > | null;
                guest?: {
                    name: string;
                    phoneNumber?: string | null;
                    email?: string | null;
                    uid: string;
                } | null;
            };
        }>;
        pageInfo: { endCursor?: string | null; hasNextPage?: boolean | null };
    };
};

export type KonsolMasaBookingsInvoicesQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    filter?: InputMaybe<InvoiceFilter>;
    after?: InputMaybe<Scalars["ID"]["input"]>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type KonsolMasaBookingsInvoicesQuery = {
    invoices: Array<{
        uid: string;
        cancelled?: string | null;
        printNumber?: number | null;
        outstandingBalance: number;
    }>;
};

export type SyncEventsMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    events: Array<EventInput>;
}>;

export type SyncEventsMutation = {
    syncEvents?:
        | { __typename: "AccessError"; message: string }
        | { __typename: "EventsSynced" }
        | null;
};

export type StartCancelBookingWorkflowMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    bookingId: Scalars["ID"]["input"];
}>;

export type StartCancelBookingWorkflowMutation = {
    startCancelBookingWorkflow:
        | {
              __typename: "CancelBookingWorkflowBookingNotCancellable";
              message: string;
          }
        | { __typename: "CancelBookingWorkflowCompleted" }
        | { __typename: "WorkflowCancelled"; message: string }
        | { __typename: "WorkflowNotReady"; message: string };
};

export type KonsolMasaBookingsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type KonsolMasaBookingsQuery = {
    resources: Array<{ uid: string; name: string; archived?: string | null }>;
    services: Array<{
        uid: string;
        name: string;
        resources: Array<{
            uid: string;
            resourceId: string;
            archived?: string | null;
        }>;
    }>;
};

export type SetResourceLinksMutationVariables = Exact<{
    createRequest: CreateResourceLinksRequest;
    archiveRequest: ArchiveResourceLinksRequest;
}>;

export type SetResourceLinksMutation = {
    createResourceLinks:
        | { __typename: "AccessError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | { __typename: "ResourceLinksAlreadyExist"; message: string }
        | { __typename: "ResourceLinksCreated" };
    archiveResourceLinks:
        | { __typename: "AccessError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | { __typename: "ResourceLinksAlreadyArchived"; message: string }
        | { __typename: "ResourceLinksArchived" };
};

export type ResourcesConfigQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type ResourcesConfigQuery = {
    resources: Array<
        {
            uid: string;
            resourceTypeId: string;
            name: string;
            archived?: string | null;
            metadata: string;
            links: Array<{
                uid: string;
                resources: Array<{ uid: string } | null>;
            } | null>;
        } & {
            " $fragmentRefs"?: {
                KonsolResourceCardFragment: KonsolResourceCardFragment;
            };
        }
    >;
    resourceTypes: Array<{ uid: string; name: string; metadata: string }>;
};

export type KonsolResourceCardFragment = {
    uid: string;
    name: string;
    created: string;
    links: Array<{
        uid: string;
        resources: Array<{ uid: string; name: string } | null>;
    } | null>;
} & { " $fragmentName"?: "KonsolResourceCardFragment" };

export type ArchiveResourceMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    resourceId: Scalars["ID"]["input"];
}>;

export type ArchiveResourceMutation = {
    archiveResource:
        | { __typename: "AccessError"; message: string }
        | { __typename: "ResourceArchived"; resourceId: string }
        | { __typename: "UnableToArchiveResource"; reason: string };
};

export type ResourcesWithBookingsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    resourceTypeId?: InputMaybe<Scalars["ID"]["input"]>;
    startDt: Scalars["DateTime"]["input"];
    endDt: Scalars["DateTime"]["input"];
}>;

export type ResourcesWithBookingsQuery = {
    resourcesBookings: Array<{
        uid: string;
        resourceTypeId: string;
        name: string;
        created: string;
        bookings: Array<{
            uid: string;
            serviceId?: string | null;
            referenceId: string;
            startDt: string;
            endDt: string;
            bookingSource?: BookingSource | null;
            confirmed?: string | null;
            metadata: string;
            cancelled?: string | null;
            recurringId?: string | null;
            resources: Array<{
                uid: string;
                archived?: string | null;
                resourceId: string;
            }>;
            guest?: {
                uid: string;
                name: string;
                phoneNumber?: string | null;
                email?: string | null;
            } | null;
        }>;
        linkedBookings: Array<{
            uid: string;
            serviceId?: string | null;
            referenceId: string;
            startDt: string;
            endDt: string;
            bookingSource?: BookingSource | null;
            confirmed?: string | null;
            metadata: string;
            cancelled?: string | null;
            recurringId?: string | null;
            resources: Array<{
                uid: string;
                archived?: string | null;
                resourceId: string;
            }>;
            guest?: {
                uid: string;
                name: string;
                phoneNumber?: string | null;
                email?: string | null;
            } | null;
        }>;
    }>;
};

export type RecurringBookingsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    filter?: InputMaybe<BookingFilter>;
}>;

export type RecurringBookingsQuery = {
    bookings: {
        edges: Array<{
            node: {
                uid: string;
                startDt: string;
                endDt: string;
                metadata: string;
                confirmed?: string | null;
                cancelled?: string | null;
                resources: Array<{
                    uid: string;
                    archived?: string | null;
                    resource: { name: string; uid: string };
                }>;
                guest?: {
                    name: string;
                    phoneNumber?: string | null;
                    email?: string | null;
                } | null;
            };
        }>;
    };
};

export type GetInvoicesQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    filter?: InputMaybe<InvoiceFilter>;
    after?: InputMaybe<Scalars["ID"]["input"]>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetInvoicesQuery = {
    invoices: Array<{
        uid: string;
        printNumber?: number | null;
        outstandingBalance: number;
        cancelled?: string | null;
    }>;
};

export type ResourcesAndServicesQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type ResourcesAndServicesQuery = {
    resources: Array<{ uid: string; name: string; resourceTypeId: string }>;
    resourceTypes: Array<{ uid: string; name: string; metadata: string }>;
    services: Array<{
        uid: string;
        name: string;
        visibility: ServiceVisibility;
        startTime?: string | null;
        endTime?: string | null;
        serviceMode: string;
        archived?: string | null;
        resources: Array<{
            resourceId: string;
            optionKey: string;
            archived?: string | null;
        }>;
    }>;
};

export type CancelBookingsMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    bookingIds: Array<Scalars["String"]["input"]>;
}>;

export type CancelBookingsMutation = {
    startCancelBookings:
        | {
              __typename: "CancelBookingsWorkflowBookingsNotCancellable";
              message: string;
          }
        | {
              __typename: "CancelBookingsWorkflowCompleted";
              state: { id: string; bookingIds: Array<string> };
          }
        | { __typename: "WorkflowCancelled"; message: string }
        | { __typename: "WorkflowNotReady"; message: string };
};

export type KonsolEditScheduleQueryQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    scheduleId: Scalars["ID"]["input"];
}>;

export type KonsolEditScheduleQueryQuery = {
    schedule: {
        uid: string;
        tenantId: string;
        name: string;
        metadata: string;
        rules: Array<
            | {
                  __typename: "AllowDatesRule";
                  dates: Array<string>;
                  uid: string;
              }
            | {
                  __typename: "AllowFromDatetimeRule";
                  effectiveFrom: string;
                  uid: string;
              }
            | {
                  __typename: "AllowMaximumAdvancedBookingTimeRule";
                  maxAdvancedBookingDays: number;
                  uid: string;
              }
            | {
                  __typename: "AllowMinimumAdvancedBookingTimeRule";
                  minAdvancedBookingMinutes: number;
                  uid: string;
              }
            | {
                  __typename: "AllowToDatetimeRule";
                  effectiveTo: string;
                  uid: string;
              }
        >;
        daytimeSlots: Array<{
            startMinuteOfWeek: number;
            durationMinutes: number;
        }>;
    };
};

export type KonsolEditScheduleMutationMutationVariables = Exact<{
    request: UpdatedScheduleRequest;
}>;

export type KonsolEditScheduleMutationMutation = {
    updateSchedule:
        | { __typename: "AccessError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | {
              __typename: "ScheduleUpdated";
              schedule: { uid: string; name: string };
          };
};

export type KonsolGetScheduleQueryQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    scheduleId: Scalars["ID"]["input"];
}>;

export type KonsolGetScheduleQueryQuery = {
    schedule: {
        uid: string;
        tenantId: string;
        name: string;
        metadata: string;
        rules: Array<
            | {
                  __typename: "AllowDatesRule";
                  dates: Array<string>;
                  uid: string;
                  created: string;
              }
            | {
                  __typename: "AllowFromDatetimeRule";
                  effectiveFrom: string;
                  uid: string;
                  created: string;
              }
            | {
                  __typename: "AllowMaximumAdvancedBookingTimeRule";
                  maxAdvancedBookingDays: number;
                  uid: string;
                  created: string;
              }
            | {
                  __typename: "AllowMinimumAdvancedBookingTimeRule";
                  minAdvancedBookingMinutes: number;
                  uid: string;
                  created: string;
              }
            | {
                  __typename: "AllowToDatetimeRule";
                  effectiveTo: string;
                  uid: string;
                  created: string;
              }
        >;
        daytimeSlots: Array<{
            startMinuteOfWeek: number;
            durationMinutes: number;
        }>;
    };
};

export type KonsolScheduleArchiveScheduleMutationVariables = Exact<{
    request: ArchiveScheduleRequest;
}>;

export type KonsolScheduleArchiveScheduleMutation = {
    archiveSchedule:
        | { __typename: "AccessError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | { __typename: "ScheduleArchived"; scheduleId: string };
};

export type GetSchedulesQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type GetSchedulesQuery = {
    schedules: Array<{ uid: string; name: string }>;
};

export type KonsolCreateScheduleMutationVariables = Exact<{
    request: CreateScheduleRequest;
}>;

export type KonsolCreateScheduleMutation = {
    createSchedule:
        | { __typename: "AccessError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | {
              __typename: "ScheduleCreated";
              schedule: { uid: string; name: string };
          }
        | { __typename: "UnhandledRuleTypeError"; message: string };
};

export type KonsolRenameServiceMutationVariables = Exact<{
    request: RenameServiceRequest;
}>;

export type KonsolRenameServiceMutation = {
    renameService:
        | { __typename: "AccessError" }
        | { __typename: "NotFoundError" }
        | { __typename: "ServiceRenamed" };
};

export type KonsolSetServiceCategoryMutationVariables = Exact<{
    request: AssignServiceToCategoryRequest;
}>;

export type KonsolSetServiceCategoryMutation = {
    assignServiceToCategory:
        | { __typename: "AccessError" }
        | { __typename: "NotFoundError" }
        | { __typename: "ServiceCategoryAssigned" };
};

export type KonsolSetServiceVisibilityMutationVariables = Exact<{
    request: UpdateServiceVisibilityRequest;
}>;

export type KonsolSetServiceVisibilityMutation = {
    updateServiceVisibility:
        | { __typename: "AccessError" }
        | { __typename: "NotFoundError" }
        | { __typename: "ServiceVisibilityUpdated" };
};

export type KonsolSetServiceBookingLimitsMutationVariables = Exact<{
    request: SetServiceBookingLimitsRequest;
}>;

export type KonsolSetServiceBookingLimitsMutation = {
    setServiceBookingLimits:
        | { __typename: "AccessError" }
        | { __typename: "NotFoundError" }
        | { __typename: "ServiceBookingLimitsSet" };
};

export type KonsolSetServiceMetadataMutationVariables = Exact<{
    request: UpdateServiceMetadataRequest;
}>;

export type KonsolSetServiceMetadataMutation = {
    updateServiceMetadata:
        | { __typename: "AccessError" }
        | { __typename: "NotFoundError" }
        | { __typename: "ServiceMetadataUpdated" };
};

export type KonsolSetServiceFixedDurationsMutationVariables = Exact<{
    request: UpdateFixedDurationOptionsRequest;
}>;

export type KonsolSetServiceFixedDurationsMutation = {
    updateFixedDurationOptions?:
        | { __typename: "AccessError" }
        | { __typename: "FixedDurationOptionsUpdated" }
        | null;
};

export type KonsolSetServiceCustomDurationsMutationVariables = Exact<{
    request: SetServiceDurationOptionsRequest;
}>;

export type KonsolSetServiceCustomDurationsMutation = {
    setServiceDurationOptions:
        | { __typename: "AccessError" }
        | { __typename: "NotFoundError" }
        | { __typename: "ServiceDurationOptionsSet" };
};

export type KonsolSetDailyServiceTimesMutationVariables = Exact<{
    request: SetDailyServiceTimesRequest;
}>;

export type KonsolSetDailyServiceTimesMutation = {
    setDailyServiceTimes:
        | { __typename: "AccessError" }
        | { __typename: "DailyServiceTimesSet" }
        | { __typename: "InvalidServiceModeError" }
        | { __typename: "NotFoundError" };
};

export type KonsolAddServiceResourceMutationVariables = Exact<{
    request: AddServiceResourceRequest;
}>;

export type KonsolAddServiceResourceMutation = {
    addServiceResource:
        | { __typename: "AccessError" }
        | { __typename: "NotFoundError" }
        | { __typename: "ServiceResourceAdded" }
        | { __typename: "UnableToAddServiceResource" };
};

export type KonsolArchiveServiceResourceMutationVariables = Exact<{
    request: ArchiveServiceResourceRequest;
}>;

export type KonsolArchiveServiceResourceMutation = {
    archiveServiceResource:
        | { __typename: "AccessError" }
        | { __typename: "NotFoundError" }
        | { __typename: "ServiceResourceArchived" };
};

export type KonsolSetServiceTagsMutationVariables = Exact<{
    request: SetServiceTagsRequest;
}>;

export type KonsolSetServiceTagsMutation = {
    setServiceTags:
        | { __typename: "AccessError" }
        | { __typename: "ServiceTagsSet" };
};

export type KonsolServiceQueryVariables = Exact<{
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
}>;

export type KonsolServiceQuery = {
    serviceById?: {
        uid: string;
        name: string;
        visibility: ServiceVisibility;
        serviceMode: string;
        startTime?: string | null;
        endTime?: string | null;
        customDurationOptions?: Array<number> | null;
        metadata: string;
        bookingWindow?: number | null;
        disallowBookingGap: boolean;
        minBookingWindowMinutes?: number | null;
        minRescheduleNotice?: number | null;
        options: Array<{
            start: {
                hour: number;
                hourOffset: number;
                isoWeekday: number;
                minute: number;
            };
            end: {
                hour: number;
                hourOffset: number;
                isoWeekday: number;
                minute: number;
            };
        }>;
        serviceCategory?: { uid: string; categoryId: string } | null;
        resources: Array<{
            uid: string;
            resourceId: string;
            archived?: string | null;
        }>;
    } | null;
    serviceTags: Array<{
        serviceId: string;
        tenantId: string;
        tagIds: Array<string>;
    }>;
    tags: Array<{ uid: string; archived?: string | null; name: string }>;
    resources: Array<{
        uid: string;
        name: string;
        archived?: string | null;
        resourceType: { uid: string; name: string };
    }>;
    categories: Array<{ uid: string; name: string }>;
};

export type KonsolArchiveServiceMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    serviceId: Scalars["ID"]["input"];
}>;

export type KonsolArchiveServiceMutation = {
    archiveService?:
        | { __typename: "AccessError" }
        | { __typename: "NotFoundError" }
        | { __typename: "ServiceAlreadyArchived" }
        | { __typename: "ServiceArchived" }
        | null;
};

export type KonsolServicesPageQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type KonsolServicesPageQuery = {
    services: Array<{
        uid: string;
        name: string;
        serviceMode: string;
        archived?: string | null;
        visibility: ServiceVisibility;
        category?: { uid: string; name: string } | null;
        resources: Array<{ uid: string; archived?: string | null }>;
    }>;
};

export type CreateServicePageQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type CreateServicePageQuery = {
    resources: Array<{
        uid: string;
        name: string;
        archived?: string | null;
        resourceType: { uid: string; name: string };
    }>;
    categories: Array<{ uid: string; name: string }>;
};

export type KonsolMembershipListUseCustomersQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    after?: InputMaybe<Scalars["ID"]["input"]>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    tagIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
}>;

export type KonsolMembershipListUseCustomersQuery = {
    customers: Array<{
        uid: string;
        name: string;
        phoneNumber?: string | null;
        email?: string | null;
        metadata?: string | null;
        customerTags: Array<{
            uid: string;
            expiresOn?: string | null;
            tag: { uid: string; name: string };
        }>;
    }>;
};

export type KonsolMembershipTagQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type KonsolMembershipTagQuery = {
    tags: Array<{ uid: string; name: string }>;
};

export type EditPaymentMethodAvailabilityMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    paymentMethodId: Scalars["ID"]["input"];
    availability: Scalars["Boolean"]["input"];
}>;

export type EditPaymentMethodAvailabilityMutation = {
    editPaymentMethodAvailability:
        | { __typename: "AccessError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | { __typename: "PaymentMethodAvailabilityEdited" };
};

export type ArchivePaymentMethodMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    paymentMethodId: Scalars["ID"]["input"];
}>;

export type ArchivePaymentMethodMutation = {
    archivePaymentMethod:
        | { __typename: "AccessError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | { __typename: "PaymentMethodAlreadyArchived"; message: string }
        | { __typename: "PaymentMethodArchived" };
};

export type KonsolPersekutuanImagesQueryVariables = Exact<{
    [key: string]: never;
}>;

export type KonsolPersekutuanImagesQuery = {
    categories: Array<{
        " $fragmentRefs"?: {
            ImageTagsFormCategoryFragment: ImageTagsFormCategoryFragment;
        };
    }>;
};

export type ImageTagsFormCategoryFragment = { uid: string; name: string } & {
    " $fragmentName"?: "ImageTagsFormCategoryFragment";
};

export type PersekutanUsersOrganisationQueryVariables = Exact<{
    uid: Scalars["ID"]["input"];
}>;

export type PersekutanUsersOrganisationQuery = {
    organisation: {
        members: Array<{
            uid: string;
            fullName: string;
            metadata: string;
            joined: string;
            capabilities: Array<string>;
        }>;
    };
};

export type GetSettlementsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    filters: SettlementsFilter;
    after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetSettlementsQuery = {
    settlements: {
        edges: Array<{
            node: {
                uid: string;
                tenantId: string;
                amount: number;
                startDate: string;
                endDate: string;
                created: string;
            };
        }>;
        pageInfo: { endCursor?: string | null; hasNextPage?: boolean | null };
    };
};

export type GenerateSettlementDownloadLinkMutationVariables = Exact<{
    request: GenerateSettlementDownloadLinkRequest;
}>;

export type GenerateSettlementDownloadLinkMutation = {
    generateSettlementDownloadLink:
        | { __typename: "AccessError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | { __typename: "SettlementDownloadLinkGenerated"; link: string };
};

export type LightTriggersItemLightFragment = {
    uid: string;
    name: string;
    triggers: Array<{ uid: string; resourceId: string }>;
} & { " $fragmentName"?: "LightTriggersItemLightFragment" };

export type KonsolSinarConfigurationQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type KonsolSinarConfigurationQuery = {
    resources: Array<{ uid: string; name: string }>;
    lights: Array<
        {
            uid: string;
            tenantId: string;
            name: string;
            boardId: string;
            relayId: string;
            disableToggling: boolean;
            isUserActivated: boolean;
            cooldown: number;
            warmup: number;
            metadata: string;
        } & {
            " $fragmentRefs"?: {
                LightTriggersItemLightFragment: LightTriggersItemLightFragment;
            };
        }
    >;
};

export type CreateLightMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
    relayId: Scalars["ID"]["input"];
    boardId: Scalars["ID"]["input"];
    disableToggling: Scalars["Boolean"]["input"];
    isUserActivated: Scalars["Boolean"]["input"];
    metadata: Scalars["JSONString"]["input"];
}>;

export type CreateLightMutation = {
    createLight?:
        | { __typename: "AccessError"; message: string }
        | { __typename: "LightCreated" }
        | null;
};

export type UpdateLightMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    lightId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
    relayId: Scalars["ID"]["input"];
    boardId: Scalars["ID"]["input"];
    disableToggling: Scalars["Boolean"]["input"];
    isUserActivated: Scalars["Boolean"]["input"];
    metadata: Scalars["JSONString"]["input"];
}>;

export type UpdateLightMutation = {
    updateLight?:
        | { __typename: "AccessError"; message: string }
        | { __typename: "LightUpdated" }
        | { __typename: "NotFoundError"; message: string }
        | null;
};

export type ArchiveTriggerMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    triggerId: Scalars["ID"]["input"];
}>;

export type ArchiveTriggerMutation = {
    archiveTrigger?:
        | { __typename: "AccessError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | {
              __typename: "TriggerArchived";
              trigger: {
                  uid: string;
                  resourceId: string;
                  light: { name: string };
              };
          }
        | null;
};

export type KonsolSinarControlQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type KonsolSinarControlQuery = {
    lights: Array<{
        uid: string;
        name: string;
        warmup: number;
        cooldown: number;
        metadata: string;
        currentCommand?:
            | {
                  __typename: "Event";
                  groupId: string;
                  uid: string;
                  start: string;
                  end: string;
              }
            | {
                  __typename: "Override";
                  lightState: LightState;
                  uid: string;
                  start: string;
                  end: string;
              }
            | null;
    }>;
};

export type SetLightDurationMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    lightId: Scalars["ID"]["input"];
    cooldown: Scalars["Int"]["input"];
    warmup: Scalars["Int"]["input"];
}>;

export type SetLightDurationMutation = {
    setLightDuration?:
        | { __typename: "AccessError"; message: string }
        | { __typename: "LightDurationSet"; light: { uid: string } }
        | { __typename: "NotFoundError"; message: string }
        | null;
};

export type UpdateOverrideMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    overrideId: Scalars["ID"]["input"];
    start: Scalars["DateTime"]["input"];
    end: Scalars["DateTime"]["input"];
}>;

export type UpdateOverrideMutation = {
    updateOverride?:
        | { __typename: "AccessError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | { __typename: "OverrideUpdated" }
        | null;
};

export type OverrideBookingLightOffMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    lightId: Scalars["ID"]["input"];
    start: Scalars["DateTime"]["input"];
    end: Scalars["DateTime"]["input"];
}>;

export type OverrideBookingLightOffMutation = {
    createOverride?:
        | { __typename: "AccessError"; message: string }
        | { __typename: "InternalError"; message: string }
        | { __typename: "NotFoundError"; message: string }
        | { __typename: "OverrideCreated" }
        | null;
};

export type KonsolOrganisationQueryVariables = Exact<{ [key: string]: never }>;

export type KonsolOrganisationQuery = {
    myOrganisations: Array<{
        uid: string;
        name: string;
        isCourtsitePartner: boolean;
    }>;
};

export type KonsolMyProfileQueryVariables = Exact<{ [key: string]: never }>;

export type KonsolMyProfileQuery = {
    myProfile?: {
        firebaseId: string;
        name: string;
        phoneNumber?: string | null;
        email?: string | null;
        gender?: Gender | null;
        dateOfBirth?: string | null;
        postCode?: string | null;
        preferredSports?: Array<string> | null;
        otherPreferredSports?: string | null;
        sportsFrequency?: number | null;
    } | null;
};

export type KonsolMyProfileSetUserProfileMutationVariables = Exact<{
    request: SetUserProfileRequest;
}>;

export type KonsolMyProfileSetUserProfileMutation = {
    setUserProfile:
        | { __typename: "AccessError"; message: string }
        | { __typename: "InvalidValue"; message: string }
        | { __typename: "UserProfileSet" };
};

export const DailyHargaCardPriceRulesetFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "DailyHargaCardPriceRuleset" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "PriceRuleset" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "effectiveFrom" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "rules" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "deposit" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "depositType",
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "PriceRulePerDayRate",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "start",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hour",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "minute",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hourOffset",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "isoWeekday",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "perDayRate",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DailyHargaCardPriceRulesetFragment, unknown>;
export const HargaCardPriceRulesetFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "HargaCardPriceRuleset" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "PriceRuleset" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "effectiveFrom" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "rules" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "deposit" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "depositType",
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "PriceRuleDurationRate",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "start",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hour",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "minute",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hourOffset",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "isoWeekday",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "end",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hour",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "minute",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hourOffset",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "isoWeekday",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "rates",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "minimumDurationMinutes",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "perHourRate",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<HargaCardPriceRulesetFragment, unknown>;
export const KonsolResourceCardFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "KonsolResourceCard" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Resource" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    { kind: "Field", name: { kind: "Name", value: "created" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "links" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<KonsolResourceCardFragment, unknown>;
export const ImageTagsFormCategoryFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "ImageTagsFormCategory" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ImageTagsFormCategoryFragment, unknown>;
export const LightTriggersItemLightFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "LightTriggersItemLight" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Light" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "triggers" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resourceId" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LightTriggersItemLightFragment, unknown>;
export const ArchiveMemberDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "archiveMember" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "ArchiveMemberRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "archiveMember" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "MemberArchived",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "memberId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "MemberAlreadyArchivedError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CannotModifySelfError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ArchiveMemberMutation,
    ArchiveMemberMutationVariables
>;
export const ArchiveResourceTypeDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "archiveResourceType" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "ArchiveResourceTypeRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "archiveResourceType" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "ResourceTypeArchived",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceTypeId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "UnableToArchiveResourceType",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "reason",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ArchiveResourceTypeMutation,
    ArchiveResourceTypeMutationVariables
>;
export const ArchiveCustomerDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "archiveCustomer" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "customerId" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "ID" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "archiveCustomer" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "customerId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "customerId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ArchiveCustomerMutation,
    ArchiveCustomerMutationVariables
>;
export const CreateCustomerDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "createCustomer" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "phoneNumber" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "PhoneNumber" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "EmailAddress" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tags" },
                    },
                    type: {
                        kind: "ListType",
                        type: {
                            kind: "NonNullType",
                            type: {
                                kind: "NamedType",
                                name: {
                                    kind: "Name",
                                    value: "CustomerTagInput",
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "metadata" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "JSONString" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createCustomer" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "name",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "phoneNumber",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "phoneNumber",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "email",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "email",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tags",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tags",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "metadata",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "InternalError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
>;
export const EditCustomerInfoDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "editCustomerInfo" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "customerId" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "ID" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "phoneNumber" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "PhoneNumber" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "EmailAddress" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tags" },
                    },
                    type: {
                        kind: "ListType",
                        type: {
                            kind: "NonNullType",
                            type: {
                                kind: "NamedType",
                                name: {
                                    kind: "Name",
                                    value: "CustomerTagInput",
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "metadata" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "JSONString" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "editCustomerInfo" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "customerId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "customerId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "name",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "phoneNumber",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "phoneNumber",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "email",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "email",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tags",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tags",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "metadata",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EditCustomerInfoMutation,
    EditCustomerInfoMutationVariables
>;
export const SetMemberCapabilitiesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "setMemberCapabilities" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "SetMemberCapabilitiesRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "setMemberCapabilities" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "MemberCapabilitiesSet",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "member",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CannotModifySelfError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "MemberAlreadyArchivedError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetMemberCapabilitiesMutation,
    SetMemberCapabilitiesMutationVariables
>;
export const CreateTagDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "createTag" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createTag" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "name",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateTagMutation, CreateTagMutationVariables>;
export const ArchiveTagDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "archiveTag" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tagId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "archiveTag" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tagId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tagId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ArchiveTagMutation, ArchiveTagMutationVariables>;
export const EditTagDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "editTag" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tagId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "editTag" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tagId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tagId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "name",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EditTagMutation, EditTagMutationVariables>;
export const KonsolDeleteWebBookingInvoicesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolDeleteWebBookingInvoices" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byRelatedEntityId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "bookingId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolDeleteWebBookingInvoicesQuery,
    KonsolDeleteWebBookingInvoicesQueryVariables
>;
export const KonsolDeleteWebBookingPaymentCaptureDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {
                kind: "Name",
                value: "konsolDeleteWebBookingPaymentCapture",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "cancelCapturePaymentWorkflow",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "workflowId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "workflowId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "bookingIds",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "bookingIds",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotFound",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolDeleteWebBookingPaymentCaptureMutation,
    KonsolDeleteWebBookingPaymentCaptureMutationVariables
>;
export const ModifyAddOnDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "modifyAddOn" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ModifyAddOnRequest" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "modifyAddOn" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ModifyAddOnMutation, ModifyAddOnMutationVariables>;
export const QuotationDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "quotation" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "setFor" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "start" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "end" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "quotation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "setFor" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "setFor" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "start" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "start" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "end" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "end" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "price" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "timeFullyAccounted",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<QuotationQuery, QuotationQueryVariables>;
export const KonsolCreateDailyBookingContactsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolCreateDailyBookingContacts" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Int" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "phoneNumber" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "customers" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCustomerName",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCustomerPhoneNumber",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "phoneNumber",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCustomerEmail",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "email",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "limit" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "phoneNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolCreateDailyBookingContactsQuery,
    KonsolCreateDailyBookingContactsQueryVariables
>;
export const KonsolBookingCreateFormGuestFormItemDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "konsolBookingCreateFormGuestFormItem",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Int" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "phoneNumber" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "customers" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "limit" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCustomerName",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCustomerPhoneNumber",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "phoneNumber",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCustomerEmail",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "email",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "phoneNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolBookingCreateFormGuestFormItemQuery,
    KonsolBookingCreateFormGuestFormItemQueryVariables
>;
export const MakeBookingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "makeBookings" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "serviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "slots" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: {
                                        kind: "Name",
                                        value: "BookingSlotRequest",
                                    },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "guest" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "BookingGuestRequest",
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "isRecurring" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Boolean" },
                    },
                    defaultValue: { kind: "BooleanValue", value: false },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "makeBookings" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "serviceId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "serviceId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "slots",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "slots",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "guest",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "guest",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "isRecurring",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "isRecurring",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "bookingSource",
                                            },
                                            value: {
                                                kind: "EnumValue",
                                                value: "ADMIN",
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "ignoreServiceLimits",
                                            },
                                            value: {
                                                kind: "BooleanValue",
                                                value: true,
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "BookingsMade",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "bookingIds",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "BookingsUnavailable",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "reason",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    MakeBookingsMutation,
    MakeBookingsMutationVariables
>;
export const BookingByIdDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "bookingById" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "booking" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "bookingId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "bookingId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "confirmed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resource",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "service" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "startTime",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "endTime",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "serviceMode",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resources",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "archived",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "resource",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "uid",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "name",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "guest" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "email",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "phoneNumber",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<BookingByIdQuery, BookingByIdQueryVariables>;
export const EditBookingInvoiceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "editBookingInvoice" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "InvoiceFilter" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "filter" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "outstandingBalance",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EditBookingInvoiceQuery,
    EditBookingInvoiceQueryVariables
>;
export const RecurringEditBookingIdsQueryDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "recurringEditBookingIdsQuery" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "bookingsByIds" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "bookingIds" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "bookingIds" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "confirmed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "guest" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "email",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "phoneNumber",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resource",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "service" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resources",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "archived",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "resource",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "uid",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "name",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RecurringEditBookingIdsQueryQuery,
    RecurringEditBookingIdsQueryQueryVariables
>;
export const GetCustomersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getCustomers" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "after" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "ID" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Int" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "phoneNumber" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "customers" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCustomerName",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCustomerPhoneNumber",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "phoneNumber",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCustomerEmail",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "email",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "after" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "after" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "limit" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "phoneNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetCustomersQuery, GetCustomersQueryVariables>;
export const StartRescheduleRecurringBookingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "startRescheduleRecurringBookings" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: {
                            kind: "Name",
                            value: "bookingGroupModifications",
                        },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: {
                                        kind: "Name",
                                        value: "RescheduleRecurringBookingsModificationsInput",
                                    },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "startRescheduleRecurringBookings",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "bookingGroupModifications",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "bookingGroupModifications",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    StartRescheduleRecurringBookingsMutation,
    StartRescheduleRecurringBookingsMutationVariables
>;
export const StartKonsolPayInvoiceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "startKonsolPayInvoice" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "isOnlinePayment" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Boolean" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "redirectUrl" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "paymentMethodId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "startKonsolPayInvoice" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "invoiceId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "invoiceId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "isOnlinePayment",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "isOnlinePayment",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "redirectUrl",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "redirectUrl",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "paymentMethodId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentMethodId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowCancelled",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    StartKonsolPayInvoiceMutation,
    StartKonsolPayInvoiceMutationVariables
>;
export const CreateInvoiceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "createInvoice" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "items" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: {
                                        kind: "Name",
                                        value: "CreateInvoiceItemRequest",
                                    },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceeName" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceePhoneNumber" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "PhoneNumber" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceeEmail" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "EmailAddress" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "metadata" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "JSONString" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createInvoice" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "items",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "items",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "invoiceeName",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "invoiceeName",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "invoiceePhoneNumber",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "invoiceePhoneNumber",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "invoiceeEmail",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "invoiceeEmail",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "metadata",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "InternalError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateInvoiceMutation,
    CreateInvoiceMutationVariables
>;
export const KonsolInvoiceCreateFormUseCustomersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "konsolInvoiceCreateFormUseCustomers",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Int" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "phoneNumber" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "customers" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCustomerName",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCustomerPhoneNumber",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "phoneNumber",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCustomerEmail",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "email",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "limit" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "phoneNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolInvoiceCreateFormUseCustomersQuery,
    KonsolInvoiceCreateFormUseCustomersQueryVariables
>;
export const KonsolOfflinePaymentLinkPaymentDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolOfflinePaymentLinkPayment" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "paymentMethods" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "availability",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "archived" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolOfflinePaymentLinkPaymentQuery,
    KonsolOfflinePaymentLinkPaymentQueryVariables
>;
export const KonsolPayPaymentLinkOfflineDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "konsolPayPaymentLinkOffline" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "paymentMethodId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "provideCapturePaymentProviderDetails",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "workflowId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "workflowId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "paymentMethodId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentMethodId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CapturePaymentProviderDetailsProvided",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "state",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "completed",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotFound",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowCancelled",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolPayPaymentLinkOfflineMutation,
    KonsolPayPaymentLinkOfflineMutationVariables
>;
export const KonsolApproveOrgDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "konsolApproveOrg" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "ApproveOrganisationRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "approveOrganisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "OrganisationApproved",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "organisation",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "approved",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolApproveOrgMutation,
    KonsolApproveOrgMutationVariables
>;
export const KonsolUnapproveOrgDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "konsolUnapproveOrg" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "UnapproveOrganisationRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "unapproveOrganisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "OrganisationUnapproved",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "organisation",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "approved",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolUnapproveOrgMutation,
    KonsolUnapproveOrgMutationVariables
>;
export const KonsolSetOrgIsCourtsitePartnerDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "konsolSetOrgIsCourtsitePartner" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "SetOrganisationIsCourtsitePartnerRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "setOrganisationIsCourtsitePartner",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "OrganisationIsCourtsitePartnerSet",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "organisation",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "isCourtsitePartner",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolSetOrgIsCourtsitePartnerMutation,
    KonsolSetOrgIsCourtsitePartnerMutationVariables
>;
export const UpdateOrganisationDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "updateOrganisation" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "UpdateOrganisationRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "updateOrganisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "OrganisationUpdated",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "organisationId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateOrganisationMutation,
    UpdateOrganisationMutationVariables
>;
export const OrgImageUploaderCategoriesQueryDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "orgImageUploaderCategoriesQuery" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    OrgImageUploaderCategoriesQueryQuery,
    OrgImageUploaderCategoriesQueryQueryVariables
>;
export const EditOrganisationServiceCategoriesQueryDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "editOrganisationServiceCategoriesQuery",
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EditOrganisationServiceCategoriesQueryQuery,
    EditOrganisationServiceCategoriesQueryQueryVariables
>;
export const CreateOrganisationDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "createOrganisation" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organisationName" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "latitude" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Float" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "longitude" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Float" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "services" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "String" },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "ownerName" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "metadata" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "JSONString" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createOrganisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "organisationName",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "organisationName",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "latitude",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "latitude",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "longitude",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "longitude",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "services",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "services",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "ownerName",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "ownerName",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "metadata",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "OrganisationCreated",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "organisationId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateOrganisationMutation,
    CreateOrganisationMutationVariables
>;
export const KonsolNewOrgFormDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolNewOrgForm" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolNewOrgFormQuery,
    KonsolNewOrgFormQueryVariables
>;
export const AddMemberToOrganisationWithoutFirebaseDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {
                kind: "Name",
                value: "addMemberToOrganisationWithoutFirebase",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "AddMemberToOrganisationWithoutFirebaseRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "addMemberToOrganisationWithoutFirebase",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "OrganisationMemberAdded",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "memberId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "MemberAlreadyAddedError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AddMemberToOrganisationWithoutFirebaseMutation,
    AddMemberToOrganisationWithoutFirebaseMutationVariables
>;
export const UserProfileFromEmailPhoneNumberDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "UserProfileFromEmailPhoneNumber" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "query" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "userProfileFromEmailPhoneNumber",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "query" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "query" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firebaseId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "phoneNumber",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UserProfileFromEmailPhoneNumberQuery,
    UserProfileFromEmailPhoneNumberQueryVariables
>;
export const AddPaymentMethodDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "addPaymentMethod" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "metadata" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "JSONString" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "availability" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Boolean" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addPaymentMethod" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "name",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "metadata",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "availability",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "availability",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AddPaymentMethodMutation,
    AddPaymentMethodMutationVariables
>;
export const EditPaymentMethodMetadataDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "editPaymentMethodMetadata" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "paymentMethodId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "metadata" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "JSONString" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "editPaymentMethodMetadata",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "paymentMethodId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentMethodId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "metadata",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "PaymentMethodMetadataEdited",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentMethodId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EditPaymentMethodMetadataMutation,
    EditPaymentMethodMetadataMutationVariables
>;
export const EditPaymentMethodNameDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "editPaymentMethodName" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "paymentMethodId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "editPaymentMethodName" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "paymentMethodId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentMethodId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "name",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "PaymentMethodNameEdited",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentMethodId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EditPaymentMethodNameMutation,
    EditPaymentMethodNameMutationVariables
>;
export const CreatePriceRulesetsForServicesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "createPriceRulesetsForServices" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "CreatePriceRulesetsForServicesRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "createPriceRulesetsForServices",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "MissingPriceRules",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "MissingService",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreatePriceRulesetsForServicesMutation,
    CreatePriceRulesetsForServicesMutationVariables
>;
export const KonsolPriceRuleSetEditFormDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolPriceRuleSetEditForm" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "setFor" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "at" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "effectivePriceRuleset" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "setFor" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "setFor" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "at" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "at" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "rules" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "rulesetId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "deposit",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "depositType",
                                                },
                                            },
                                            {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                    kind: "NamedType",
                                                    name: {
                                                        kind: "Name",
                                                        value: "PriceRuleDurationRate",
                                                    },
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "rates",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "minimumDurationMinutes",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "perHourRate",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "start",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "hour",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "minute",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "hourOffset",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "isoWeekday",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "end",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "hour",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "minute",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "hourOffset",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "isoWeekday",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                    kind: "NamedType",
                                                    name: {
                                                        kind: "Name",
                                                        value: "PriceRulePerDayRate",
                                                    },
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "created",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "perDayRate",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "start",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "hour",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "minute",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "hourOffset",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "isoWeekday",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "effectiveFrom",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "appliesTo" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolPriceRuleSetEditFormQuery,
    KonsolPriceRuleSetEditFormQueryVariables
>;
export const ChangeResourceTypeDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "changeResourceType" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "resourceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "resourceTypeId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "changeResourceType" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "resourceId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "resourceTypeId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceTypeId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "ResourceTypeChanged",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceTypeId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "UnableToChangeResourceType",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "reason",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ChangeResourceTypeMutation,
    ChangeResourceTypeMutationVariables
>;
export const CreateResourceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "createResource" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "resourceTypeId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "metadata" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "JSONString" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createResource" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "name",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "resourceTypeId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceTypeId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "metadata",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateResourceMutation,
    CreateResourceMutationVariables
>;
export const RenameResourceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "renameResource" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "resourceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "renameResource" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "resourceId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "name",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RenameResourceMutation,
    RenameResourceMutationVariables
>;
export const CreateResourceTypeDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "createResourceType" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createResourceType" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "name",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "metadata",
                                            },
                                            value: {
                                                kind: "StringValue",
                                                value: "{}",
                                                block: false,
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "ResourceTypeCreated",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceTypeId",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateResourceTypeMutation,
    CreateResourceTypeMutationVariables
>;
export const UpdateResourceTypesMetadataDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "updateResourceTypesMetadata" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "requests" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: {
                                        kind: "Name",
                                        value: "ResourceTypesMetadataInput",
                                    },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "updateResourceTypesMetadata",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "requests",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "requests",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateResourceTypesMetadataMutation,
    UpdateResourceTypesMetadataMutationVariables
>;
export const CreateServiceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "createService" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categoryId" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "ID" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "resources" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: {
                                        kind: "Name",
                                        value: "ServiceResourceInput",
                                    },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "metadata" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "JSONString" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createService" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "categoryId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "categoryId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "name",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "resources",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "resources",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "metadata",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "ServiceCreated",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "serviceId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateServiceMutation,
    CreateServiceMutationVariables
>;
export const CreateDailyServiceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "createDailyService" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "CreateDailyServiceRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createDailyService" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "DailyServiceCreated",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "serviceId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateDailyServiceMutation,
    CreateDailyServiceMutationVariables
>;
export const CreateOverrideDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "createOverride" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "lightId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "state" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "LightState" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "start" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "end" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "metadata" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "JSONString" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createOverride" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "lightId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "lightId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "state",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "start",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "start",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "end",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "end",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "metadata",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "InternalError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateOverrideMutation,
    CreateOverrideMutationVariables
>;
export const CreateTriggerDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "createTrigger" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "lightId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "resourceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "metadata" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "JSONString" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createTrigger" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "lightId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "lightId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "resourceId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "metadata",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "InternalError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateTriggerMutation,
    CreateTriggerMutationVariables
>;
export const KonsolTriggerCreateFormDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolTriggerCreateForm" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "resourceTypes" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolTriggerCreateFormQuery,
    KonsolTriggerCreateFormQueryVariables
>;
export const StartKonsolCheckoutDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "startKonsolCheckout" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "isOnlinePayment" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Boolean" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "redirectUrl" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "paymentMethodId" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "ID" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceeName" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceePhoneNumber" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceeEmail" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "startKonsolCheckout" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "bookingIds",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "bookingIds",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "isOnlinePayment",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "isOnlinePayment",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "redirectUrl",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "redirectUrl",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "paymentMethodId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentMethodId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "invoiceeName",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "invoiceeName",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "invoiceePhoneNumber",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "invoiceePhoneNumber",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "invoiceeEmail",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "invoiceeEmail",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "KonsolCheckoutStarted",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "state",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "invoiceId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "bookingIds",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "KonsolCheckoutInvalidBooking",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowCancelled",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    StartKonsolCheckoutMutation,
    StartKonsolCheckoutMutationVariables
>;
export const StartKonsolCreateInvoiceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "startKonsolCreateInvoice" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceeName" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceePhoneNumber" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceeEmail" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "startKonsolCreateInvoice",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "bookingIds",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "bookingIds",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "invoiceeName",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "invoiceeName",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "invoiceePhoneNumber",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "invoiceePhoneNumber",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "invoiceeEmail",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "invoiceeEmail",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "KonsolCreateInvoiceCompleted",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "state",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "invoiceId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "bookingIds",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowCancelled",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    StartKonsolCreateInvoiceMutation,
    StartKonsolCreateInvoiceMutationVariables
>;
export const KonsolCartBookingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolCartBookings" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "bookingsByIds" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "bookingIds" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "bookingIds" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "serviceId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "confirmed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resource",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "service" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "serviceMode",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "guest" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "phoneNumber",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "email",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolCartBookingsQuery,
    KonsolCartBookingsQueryVariables
>;
export const GetInvoiceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getInvoice" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "uid" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoice" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "uid" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "printNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "invoiceeName",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "invoiceeEmail",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "invoiceePhoneNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "outstandingBalance",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "total" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "isCourtsiteOwned",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "settlementRate",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "description",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "quantity",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "subtotal",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "relatedEntity",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityType",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "payments" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "amount",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentType",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentMethod",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetInvoiceQuery, GetInvoiceQueryVariables>;
export const StartCancelPaymentWorkflowDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "startCancelPaymentWorkflow" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "paymentId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "startCancelPaymentWorkflow",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "invoiceId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "invoiceId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "paymentId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CancelPaymentWorkflowPaymentNotCancellable",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowCancelled",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    StartCancelPaymentWorkflowMutation,
    StartCancelPaymentWorkflowMutationVariables
>;
export const CancelKonsolCheckoutDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "cancelKonsolCheckout" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "cancelKonsolCheckout" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "checkoutId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "workflowId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "KonsolCheckoutNotCancellable",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowCancelled",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotFound",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CancelKonsolCheckoutMutation,
    CancelKonsolCheckoutMutationVariables
>;
export const CancelCapturePaymentWorkflowDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "CancelCapturePaymentWorkflow" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "CancelCapturePaymentWorkflowRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "cancelCapturePaymentWorkflow",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CapturePaymentCancelled",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "__typename",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotFound",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CancelCapturePaymentWorkflowMutation,
    CancelCapturePaymentWorkflowMutationVariables
>;
export const StartKonsolCancelInvoiceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "startKonsolCancelInvoice" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "startKonsolCancelInvoice",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "invoiceId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "invoiceId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowCancelled",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    StartKonsolCancelInvoiceMutation,
    StartKonsolCancelInvoiceMutationVariables
>;
export const CancelKonsolPayInvoiceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "cancelKonsolPayInvoice" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "cancelKonsolPayInvoice" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "workflowId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "workflowId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "KonsolPayInvoiceNotCancellable",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowCancelled",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotFound",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CancelKonsolPayInvoiceMutation,
    CancelKonsolPayInvoiceMutationVariables
>;
export const KonsolCapturePaymentInvoiceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolCapturePaymentInvoice" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: {
                            kind: "Name",
                            value: "capturePaymentWorkflowId",
                        },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "capturePaymentStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "workflowId" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "capturePaymentWorkflowId",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "completed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expired" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolCapturePaymentInvoiceQuery,
    KonsolCapturePaymentInvoiceQueryVariables
>;
export const KonsolUseBookingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolUseBookings" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "bookingsByIds" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "bookingIds" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "bookingIds" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "referenceId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "bookingSource",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "service" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "serviceMode",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resource",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolUseBookingsQuery,
    KonsolUseBookingsQueryVariables
>;
export const GetRecurringBookingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getRecurringBookings" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "recurringId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dateRange" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "DateRangeFilter" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "bookings" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byRecurringId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "recurringId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byStartDate",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "dateRange",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: { kind: "IntValue", value: "520" },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edges" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "node",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "startDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "endDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "confirmed",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "resources",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "archived",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "resource",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "uid",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "name",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "cancelled",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetRecurringBookingsQuery,
    GetRecurringBookingsQueryVariables
>;
export const KonsolSingleCheckoutModalStartCheckoutDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {
                kind: "Name",
                value: "konsolSingleCheckoutModalStartCheckout",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "isOnlinePayment" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Boolean" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "redirectUrl" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "paymentMethodId" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "ID" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceeName" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceePhoneNumber" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceeEmail" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "startKonsolCheckout" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "bookingIds",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "bookingIds",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "isOnlinePayment",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "isOnlinePayment",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "redirectUrl",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "redirectUrl",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "paymentMethodId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentMethodId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "invoiceeName",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "invoiceeName",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "invoiceePhoneNumber",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "invoiceePhoneNumber",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "invoiceeEmail",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "invoiceeEmail",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "KonsolCheckoutStarted",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "state",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "invoiceId",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "KonsolCheckoutInvalidBooking",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowCancelled",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolSingleCheckoutModalStartCheckoutMutation,
    KonsolSingleCheckoutModalStartCheckoutMutationVariables
>;
export const KonsolSingleCheckoutModalCreateInvoiceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {
                kind: "Name",
                value: "konsolSingleCheckoutModalCreateInvoice",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceeName" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceePhoneNumber" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceeEmail" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "startKonsolCreateInvoice",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "bookingIds",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "bookingIds",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "invoiceeName",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "invoiceeName",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "invoiceePhoneNumber",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "invoiceePhoneNumber",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "invoiceeEmail",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "invoiceeEmail",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "KonsolCreateInvoiceCompleted",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "state",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "invoiceId",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowCancelled",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolSingleCheckoutModalCreateInvoiceMutation,
    KonsolSingleCheckoutModalCreateInvoiceMutationVariables
>;
export const KonsolPreviewOrgTemplateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolPreviewOrgTemplate" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "onlineServices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceCategory",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "categoryId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolPreviewOrgTemplateQuery,
    KonsolPreviewOrgTemplateQueryVariables
>;
export const KonsolOrganisationLayoutDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolOrganisationLayout" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myOrganisations" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "services" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "location" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "latitude",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "longitude",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "isCourtsitePartner",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "approved" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "myCapabilities",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolOrganisationLayoutQuery,
    KonsolOrganisationLayoutQueryVariables
>;
export const GetCartItemsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getCartItems" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        directives: [
                            {
                                kind: "Directive",
                                name: { kind: "Name", value: "client" },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetCartItemsQuery, GetCartItemsQueryVariables>;
export const CreateUserProfileDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "createUserProfile" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "EmailAddress" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "phoneNumber" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "PhoneNumber" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createUserProfile" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "name",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "email",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "email",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "phoneNumber",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "phoneNumber",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "InvalidValue",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateUserProfileMutation,
    CreateUserProfileMutationVariables
>;
export const GetPaymentMethodsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getPaymentMethods" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "paymentMethods" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "availability",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "archived" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "updated" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetPaymentMethodsQuery,
    GetPaymentMethodsQueryVariables
>;
export const KonsolUseTagsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolUseTags" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "tags" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<KonsolUseTagsQuery, KonsolUseTagsQueryVariables>;
export const CheckinBookingsListDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "checkinBookingsList" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "BookingFilter" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "bookings" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "filter" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: { kind: "IntValue", value: "21" },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edges" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "node",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "startDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "endDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "referenceId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "resources",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "uid",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "archived",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "resource",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "name",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "uid",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CheckinBookingsListQuery,
    CheckinBookingsListQueryVariables
>;
export const KonsolDashboardDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolDashboard" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "services" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "category" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "resellerTenant" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isActive" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolDashboardQuery,
    KonsolDashboardQueryVariables
>;
export const KonsolDashboardAnalyticsPageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolDashboardAnalyticsPage" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "start" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "end" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dashboardType" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "MetabaseDashboardType",
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categoryId" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "ID" },
                    },
                    defaultValue: {
                        kind: "StringValue",
                        value: "",
                        block: false,
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "serviceIds" },
                    },
                    type: {
                        kind: "ListType",
                        type: {
                            kind: "NonNullType",
                            type: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ID" },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metabaseDashboardUrl" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "start" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "start" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "end" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "end" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "dashboardType" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "dashboardType",
                                    },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "categoryId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "categoryId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "serviceIds" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "serviceIds" },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolDashboardAnalyticsPageQuery,
    KonsolDashboardAnalyticsPageQueryVariables
>;
export const KonsolHargaServicesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolHargaServices" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "services" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolHargaServicesQuery,
    KonsolHargaServicesQueryVariables
>;
export const KonsolHargaPriceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolHargaPrice" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "setFor" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "at" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "effectivePriceRuleset" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "setFor" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "setFor" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "at" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "at" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "HargaCardPriceRuleset",
                                    },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "DailyHargaCardPriceRuleset",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "HargaCardPriceRuleset" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "PriceRuleset" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "effectiveFrom" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "rules" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "deposit" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "depositType",
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "PriceRuleDurationRate",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "start",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hour",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "minute",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hourOffset",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "isoWeekday",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "end",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hour",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "minute",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hourOffset",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "isoWeekday",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "rates",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "minimumDurationMinutes",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "perHourRate",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "DailyHargaCardPriceRuleset" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "PriceRuleset" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "effectiveFrom" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "rules" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "deposit" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "depositType",
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "PriceRulePerDayRate",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "start",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hour",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "minute",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hourOffset",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "isoWeekday",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "perDayRate",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolHargaPriceQuery,
    KonsolHargaPriceQueryVariables
>;
export const ServicesHargaDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "servicesHarga" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "services" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "optionKey",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "visibility" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "category" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ServicesHargaQuery, ServicesHargaQueryVariables>;
export const KonsolInvoisInvoicesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolInvoisInvoices" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "InvoiceFilter" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "after" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "ID" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Int" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "filter" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "after" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "after" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "limit" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "invoiceeName",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "invoiceePhoneNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "total" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amountPaid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "outstandingBalance",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "printNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolInvoisInvoicesQuery,
    KonsolInvoisInvoicesQueryVariables
>;
export const AddOnsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "addOns" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addOns" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "price" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isInStock" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "maxQuantity",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AddOnsQuery, AddOnsQueryVariables>;
export const StockAddOnDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "stockAddOn" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "uid" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "isInStock" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Boolean" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "setAddOnIsInStock" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "addOnId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "isInStock",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "isInStock",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<StockAddOnMutation, StockAddOnMutationVariables>;
export const ArchiveAddOnDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "archiveAddOn" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "uid" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "archiveAddOn" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "addOnId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ArchiveAddOnMutation,
    ArchiveAddOnMutationVariables
>;
export const CreateAddOnDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "createAddOn" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "CreateAddOnRequest" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createAddOn" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateAddOnMutation, CreateAddOnMutationVariables>;
export const ConfirmedBookingsWithAddonsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "ConfirmedBookingsWithAddons" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "startDateBefore" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "startDateAfter" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "confirmedBookingsWithAddons",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: {
                                    kind: "Name",
                                    value: "startDateBefore",
                                },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "startDateBefore",
                                    },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "startDateAfter" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "startDateAfter",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "referenceId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "addons" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "addonId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "perUnitPrice",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "quantity",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "service" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "category",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resource",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ConfirmedBookingsWithAddonsQuery,
    ConfirmedBookingsWithAddonsQueryVariables
>;
export const BookingAddOnsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "BookingAddOns" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addOns" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<BookingAddOnsQuery, BookingAddOnsQueryVariables>;
export const KonsolBookingsListDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolBookingsList" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "BookingFilter" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "after" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Int" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "bookings" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "filter" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "after" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "after" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "limit" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edges" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "node",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "startDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "endDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "created",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "referenceId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "bookingSource",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "cancelledReason",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "resources",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "uid",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "resourceId",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "created",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "archived",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "service",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "uid",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "serviceMode",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "activities",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "__typename",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "occuredAt",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "performedBy",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "InlineFragment",
                                                                        typeCondition:
                                                                            {
                                                                                kind: "NamedType",
                                                                                name: {
                                                                                    kind: "Name",
                                                                                    value: "BookingCancelledActivity",
                                                                                },
                                                                            },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "occuredAt",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "performedBy",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "cancelledReason",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "oldStartDt",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "newStartDt",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "oldEndDt",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "newEndDt",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            alias: {
                                                                                                kind: "Name",
                                                                                                value: "qOldResourceIds",
                                                                                            },
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "oldResourceIds",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            alias: {
                                                                                                kind: "Name",
                                                                                                value: "qNewResourceIds",
                                                                                            },
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "newResourceIds",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "InlineFragment",
                                                                        typeCondition:
                                                                            {
                                                                                kind: "NamedType",
                                                                                name: {
                                                                                    kind: "Name",
                                                                                    value: "BookingCreatedActivity",
                                                                                },
                                                                            },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "startDt",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "endDt",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "InlineFragment",
                                                                        typeCondition:
                                                                            {
                                                                                kind: "NamedType",
                                                                                name: {
                                                                                    kind: "Name",
                                                                                    value: "BookingMetadataChangedActivity",
                                                                                },
                                                                            },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "oldMetadata",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "changedMetadata",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "InlineFragment",
                                                                        typeCondition:
                                                                            {
                                                                                kind: "NamedType",
                                                                                name: {
                                                                                    kind: "Name",
                                                                                    value: "BookingGuestChangedActivity",
                                                                                },
                                                                            },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "oldName",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "oldEmail",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "oldPhoneNumber",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "name",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "email",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "phoneNumber",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "InlineFragment",
                                                                        typeCondition:
                                                                            {
                                                                                kind: "NamedType",
                                                                                name: {
                                                                                    kind: "Name",
                                                                                    value: "BookingReferenceIdChangedActivity",
                                                                                },
                                                                            },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "referenceId",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "InlineFragment",
                                                                        typeCondition:
                                                                            {
                                                                                kind: "NamedType",
                                                                                name: {
                                                                                    kind: "Name",
                                                                                    value: "BookingRescheduledActivity",
                                                                                },
                                                                            },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "startDt",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "endDt",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "oldStartDt",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "oldEndDt",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "oldResourceIds",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "newResourceIds",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "guest",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "name",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "phoneNumber",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "email",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "uid",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "metadata",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "confirmed",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "cancelled",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "pageInfo" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "endCursor",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "hasNextPage",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolBookingsListQuery,
    KonsolBookingsListQueryVariables
>;
export const KonsolMasaBookingsInvoicesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolMasaBookingsInvoices" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "InvoiceFilter" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "after" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "ID" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Int" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "filter" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "after" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "after" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "limit" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "printNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "outstandingBalance",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolMasaBookingsInvoicesQuery,
    KonsolMasaBookingsInvoicesQueryVariables
>;
export const SyncEventsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "syncEvents" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "events" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "EventInput" },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "syncEvents" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "events",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "events",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SyncEventsMutation, SyncEventsMutationVariables>;
export const StartCancelBookingWorkflowDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "startCancelBookingWorkflow" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "startCancelBookingWorkflow",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "bookingId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "bookingId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CancelBookingWorkflowBookingNotCancellable",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowCancelled",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    StartCancelBookingWorkflowMutation,
    StartCancelBookingWorkflowMutationVariables
>;
export const KonsolMasaBookingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolMasaBookings" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "resources" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "archived" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "services" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolMasaBookingsQuery,
    KonsolMasaBookingsQueryVariables
>;
export const SetResourceLinksDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "setResourceLinks" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "createRequest" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "CreateResourceLinksRequest",
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "archiveRequest" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "ArchiveResourceLinksRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createResourceLinks" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "createRequest",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "ResourceLinksAlreadyExist",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "archiveResourceLinks" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "archiveRequest",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "ResourceLinksAlreadyArchived",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetResourceLinksMutation,
    SetResourceLinksMutationVariables
>;
export const ResourcesConfigDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "resourcesConfig" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "resources" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "resourceTypeId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "archived" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "links" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resources",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "KonsolResourceCard",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "resourceTypes" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "KonsolResourceCard" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Resource" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    { kind: "Field", name: { kind: "Name", value: "created" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "links" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ResourcesConfigQuery,
    ResourcesConfigQueryVariables
>;
export const ArchiveResourceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "archiveResource" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "resourceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "archiveResource" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "resourceId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "ResourceArchived",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "UnableToArchiveResource",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "reason",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ArchiveResourceMutation,
    ArchiveResourceMutationVariables
>;
export const ResourcesWithBookingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "resourcesWithBookings" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "resourceTypeId" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "ID" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "startDt" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "endDt" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "resourcesBookings" },
                        name: { kind: "Name", value: "resources" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byResourceTypeId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceTypeId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "resourceTypeId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "bookings" },
                                    arguments: [
                                        {
                                            kind: "Argument",
                                            name: {
                                                kind: "Name",
                                                value: "startDt",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "startDt",
                                                },
                                            },
                                        },
                                        {
                                            kind: "Argument",
                                            name: {
                                                kind: "Name",
                                                value: "endDt",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "endDt",
                                                },
                                            },
                                        },
                                        {
                                            kind: "Argument",
                                            name: {
                                                kind: "Name",
                                                value: "includeBeforeStartDt",
                                            },
                                            value: {
                                                kind: "BooleanValue",
                                                value: true,
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "serviceId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "referenceId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "startDt",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "endDt",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "bookingSource",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resources",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "archived",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "resourceId",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "guest",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "phoneNumber",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "email",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "cancelled",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "recurringId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "linkedBookings",
                                    },
                                    arguments: [
                                        {
                                            kind: "Argument",
                                            name: {
                                                kind: "Name",
                                                value: "startDt",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "startDt",
                                                },
                                            },
                                        },
                                        {
                                            kind: "Argument",
                                            name: {
                                                kind: "Name",
                                                value: "endDt",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "endDt",
                                                },
                                            },
                                        },
                                        {
                                            kind: "Argument",
                                            name: {
                                                kind: "Name",
                                                value: "includeBeforeStartDt",
                                            },
                                            value: {
                                                kind: "BooleanValue",
                                                value: true,
                                            },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "serviceId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "referenceId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "startDt",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "endDt",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "bookingSource",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resources",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "archived",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "resourceId",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "guest",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "phoneNumber",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "email",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "confirmed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "cancelled",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "recurringId",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ResourcesWithBookingsQuery,
    ResourcesWithBookingsQueryVariables
>;
export const RecurringBookingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "recurringBookings" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "BookingFilter" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "bookings" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "filter" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: { kind: "IntValue", value: "260" },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edges" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "node",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "startDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "endDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "resources",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "uid",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "archived",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "resource",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "name",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "uid",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "guest",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "name",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "phoneNumber",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "email",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "metadata",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "confirmed",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "cancelled",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RecurringBookingsQuery,
    RecurringBookingsQueryVariables
>;
export const GetInvoicesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getInvoices" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "InvoiceFilter" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "after" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "ID" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Int" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "filter" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "after" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "after" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "limit" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "printNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "outstandingBalance",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetInvoicesQuery, GetInvoicesQueryVariables>;
export const ResourcesAndServicesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "resourcesAndServices" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "resources" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "resourceTypeId",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "resourceTypes" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "services" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "optionKey",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "visibility" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startTime" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endTime" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "archived" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ResourcesAndServicesQuery,
    ResourcesAndServicesQueryVariables
>;
export const CancelBookingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "cancelBookings" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "String" },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "startCancelBookings" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "bookingIds",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "bookingIds",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CancelBookingsWorkflowCompleted",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "bookingIds",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CancelBookingsWorkflowBookingsNotCancellable",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowCancelled",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CancelBookingsMutation,
    CancelBookingsMutationVariables
>;
export const KonsolEditScheduleQueryDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolEditScheduleQuery" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "scheduleId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "schedule" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "scheduleId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "scheduleId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "rules" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "__typename",
                                                },
                                            },
                                            {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                    kind: "NamedType",
                                                    name: {
                                                        kind: "Name",
                                                        value: "AllowDatesRule",
                                                    },
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "dates",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                    kind: "NamedType",
                                                    name: {
                                                        kind: "Name",
                                                        value: "AllowFromDatetimeRule",
                                                    },
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "effectiveFrom",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                    kind: "NamedType",
                                                    name: {
                                                        kind: "Name",
                                                        value: "AllowToDatetimeRule",
                                                    },
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "effectiveTo",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                    kind: "NamedType",
                                                    name: {
                                                        kind: "Name",
                                                        value: "AllowMaximumAdvancedBookingTimeRule",
                                                    },
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "maxAdvancedBookingDays",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                    kind: "NamedType",
                                                    name: {
                                                        kind: "Name",
                                                        value: "AllowMinimumAdvancedBookingTimeRule",
                                                    },
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "minAdvancedBookingMinutes",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "daytimeSlots",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "startMinuteOfWeek",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "durationMinutes",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolEditScheduleQueryQuery,
    KonsolEditScheduleQueryQueryVariables
>;
export const KonsolEditScheduleMutationDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "konsolEditScheduleMutation" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "UpdatedScheduleRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "updateSchedule" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "ScheduleUpdated",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "schedule",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolEditScheduleMutationMutation,
    KonsolEditScheduleMutationMutationVariables
>;
export const KonsolGetScheduleQueryDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolGetScheduleQuery" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "scheduleId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "schedule" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "scheduleId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "scheduleId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "rules" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "__typename",
                                                },
                                            },
                                            {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                    kind: "NamedType",
                                                    name: {
                                                        kind: "Name",
                                                        value: "AllowDatesRule",
                                                    },
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "dates",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                    kind: "NamedType",
                                                    name: {
                                                        kind: "Name",
                                                        value: "AllowFromDatetimeRule",
                                                    },
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "effectiveFrom",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                    kind: "NamedType",
                                                    name: {
                                                        kind: "Name",
                                                        value: "AllowToDatetimeRule",
                                                    },
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "effectiveTo",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                    kind: "NamedType",
                                                    name: {
                                                        kind: "Name",
                                                        value: "AllowMaximumAdvancedBookingTimeRule",
                                                    },
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "maxAdvancedBookingDays",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                    kind: "NamedType",
                                                    name: {
                                                        kind: "Name",
                                                        value: "AllowMinimumAdvancedBookingTimeRule",
                                                    },
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "minAdvancedBookingMinutes",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "daytimeSlots",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "startMinuteOfWeek",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "durationMinutes",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolGetScheduleQueryQuery,
    KonsolGetScheduleQueryQueryVariables
>;
export const KonsolScheduleArchiveScheduleDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "konsolScheduleArchiveSchedule" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "ArchiveScheduleRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "archiveSchedule" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "ScheduleArchived",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "scheduleId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolScheduleArchiveScheduleMutation,
    KonsolScheduleArchiveScheduleMutationVariables
>;
export const GetSchedulesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getSchedules" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "schedules" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetSchedulesQuery, GetSchedulesQueryVariables>;
export const KonsolCreateScheduleDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "konsolCreateSchedule" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "CreateScheduleRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createSchedule" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "ScheduleCreated",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "schedule",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "UnhandledRuleTypeError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolCreateScheduleMutation,
    KonsolCreateScheduleMutationVariables
>;
export const KonsolRenameServiceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "konsolRenameService" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "RenameServiceRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "renameService" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolRenameServiceMutation,
    KonsolRenameServiceMutationVariables
>;
export const KonsolSetServiceCategoryDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "konsolSetServiceCategory" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "AssignServiceToCategoryRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "assignServiceToCategory",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolSetServiceCategoryMutation,
    KonsolSetServiceCategoryMutationVariables
>;
export const KonsolSetServiceVisibilityDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "konsolSetServiceVisibility" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "UpdateServiceVisibilityRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "updateServiceVisibility",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolSetServiceVisibilityMutation,
    KonsolSetServiceVisibilityMutationVariables
>;
export const KonsolSetServiceBookingLimitsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "konsolSetServiceBookingLimits" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "SetServiceBookingLimitsRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "setServiceBookingLimits",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolSetServiceBookingLimitsMutation,
    KonsolSetServiceBookingLimitsMutationVariables
>;
export const KonsolSetServiceMetadataDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "konsolSetServiceMetadata" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "UpdateServiceMetadataRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "updateServiceMetadata" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolSetServiceMetadataMutation,
    KonsolSetServiceMetadataMutationVariables
>;
export const KonsolSetServiceFixedDurationsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "konsolSetServiceFixedDurations" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "UpdateFixedDurationOptionsRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "updateFixedDurationOptions",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolSetServiceFixedDurationsMutation,
    KonsolSetServiceFixedDurationsMutationVariables
>;
export const KonsolSetServiceCustomDurationsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "konsolSetServiceCustomDurations" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "SetServiceDurationOptionsRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "setServiceDurationOptions",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolSetServiceCustomDurationsMutation,
    KonsolSetServiceCustomDurationsMutationVariables
>;
export const KonsolSetDailyServiceTimesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "konsolSetDailyServiceTimes" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "SetDailyServiceTimesRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "setDailyServiceTimes" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolSetDailyServiceTimesMutation,
    KonsolSetDailyServiceTimesMutationVariables
>;
export const KonsolAddServiceResourceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "konsolAddServiceResource" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "AddServiceResourceRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addServiceResource" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolAddServiceResourceMutation,
    KonsolAddServiceResourceMutationVariables
>;
export const KonsolArchiveServiceResourceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "konsolArchiveServiceResource" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "ArchiveServiceResourceRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "archiveServiceResource" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolArchiveServiceResourceMutation,
    KonsolArchiveServiceResourceMutationVariables
>;
export const KonsolSetServiceTagsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "konsolSetServiceTags" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "SetServiceTagsRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "setServiceTags" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolSetServiceTagsMutation,
    KonsolSetServiceTagsMutationVariables
>;
export const KonsolServiceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolService" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "serviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceById" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "serviceId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "serviceId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "visibility" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startTime" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endTime" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "customDurationOptions",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "bookingWindow",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "disallowBookingGap",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "minBookingWindowMinutes",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "minRescheduleNotice",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "options" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "start",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hour",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hourOffset",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "isoWeekday",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "minute",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "end",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hour",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "hourOffset",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "isoWeekday",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "minute",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceCategory",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "categoryId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceTags" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "serviceId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tagIds" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "tags" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "archived" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "resources" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "archived" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "resourceType",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<KonsolServiceQuery, KonsolServiceQueryVariables>;
export const KonsolArchiveServiceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "konsolArchiveService" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "serviceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "archiveService" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "serviceId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "serviceId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolArchiveServiceMutation,
    KonsolArchiveServiceMutationVariables
>;
export const KonsolServicesPageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolServicesPage" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "services" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "includeArchived",
                                            },
                                            value: {
                                                kind: "BooleanValue",
                                                value: true,
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "archived" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "visibility" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "category" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolServicesPageQuery,
    KonsolServicesPageQueryVariables
>;
export const CreateServicePageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "createServicePage" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "resources" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "archived" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "resourceType",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateServicePageQuery,
    CreateServicePageQueryVariables
>;
export const KonsolMembershipListUseCustomersDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolMembershipListUseCustomers" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "after" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "ID" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Int" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "phoneNumber" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tagIds" },
                    },
                    type: {
                        kind: "ListType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "customers" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCustomerName",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCustomerPhoneNumber",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "phoneNumber",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCustomerEmail",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "email",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byTagIds",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tagIds",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "after" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "after" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "limit" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "phoneNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "customerTags",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "tag",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "expiresOn",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolMembershipListUseCustomersQuery,
    KonsolMembershipListUseCustomersQueryVariables
>;
export const KonsolMembershipTagDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolMembershipTag" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "tags" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolMembershipTagQuery,
    KonsolMembershipTagQueryVariables
>;
export const EditPaymentMethodAvailabilityDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "editPaymentMethodAvailability" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "paymentMethodId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "availability" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Boolean" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "editPaymentMethodAvailability",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "paymentMethodId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentMethodId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "availability",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "availability",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EditPaymentMethodAvailabilityMutation,
    EditPaymentMethodAvailabilityMutationVariables
>;
export const ArchivePaymentMethodDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "archivePaymentMethod" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "paymentMethodId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "archivePaymentMethod" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "paymentMethodId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentMethodId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "PaymentMethodAlreadyArchived",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ArchivePaymentMethodMutation,
    ArchivePaymentMethodMutationVariables
>;
export const KonsolPersekutuanImagesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolPersekutuanImages" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "ImageTagsFormCategory",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "ImageTagsFormCategory" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolPersekutuanImagesQuery,
    KonsolPersekutuanImagesQueryVariables
>;
export const PersekutanUsersOrganisationDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "persekutanUsersOrganisation" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "uid" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "organisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "uid" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "members" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "fullName",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "joined",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "capabilities",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PersekutanUsersOrganisationQuery,
    PersekutanUsersOrganisationQueryVariables
>;
export const GetSettlementsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getSettlements" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filters" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "SettlementsFilter" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "after" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "settlements" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filters" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "filters" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "after" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "after" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: { kind: "IntValue", value: "20" },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edges" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "node",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "tenantId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "amount",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "startDate",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "endDate",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "created",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "pageInfo" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "endCursor",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "hasNextPage",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetSettlementsQuery, GetSettlementsQueryVariables>;
export const GenerateSettlementDownloadLinkDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "generateSettlementDownloadLink" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "GenerateSettlementDownloadLinkRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "generateSettlementDownloadLink",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "SettlementDownloadLinkGenerated",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "link",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GenerateSettlementDownloadLinkMutation,
    GenerateSettlementDownloadLinkMutationVariables
>;
export const KonsolSinarConfigurationDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolSinarConfiguration" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "resources" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "lights" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "boardId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "relayId" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "disableToggling",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "isUserActivated",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cooldown" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "warmup" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "LightTriggersItemLight",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "LightTriggersItemLight" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Light" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "triggers" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resourceId" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolSinarConfigurationQuery,
    KonsolSinarConfigurationQueryVariables
>;
export const CreateLightDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "createLight" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "relayId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "boardId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "disableToggling" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Boolean" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "isUserActivated" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Boolean" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "metadata" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "JSONString" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createLight" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "name",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "relayId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "relayId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "boardId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "boardId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "disableToggling",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "disableToggling",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "isUserActivated",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "isUserActivated",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "metadata",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateLightMutation, CreateLightMutationVariables>;
export const UpdateLightDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "updateLight" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "lightId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "relayId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "boardId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "disableToggling" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Boolean" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "isUserActivated" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Boolean" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "metadata" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "JSONString" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "updateLight" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "lightId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "lightId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "name",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "relayId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "relayId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "boardId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "boardId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "disableToggling",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "disableToggling",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "isUserActivated",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "isUserActivated",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "metadata",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateLightMutation, UpdateLightMutationVariables>;
export const ArchiveTriggerDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "archiveTrigger" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "triggerId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "archiveTrigger" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "triggerId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "triggerId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "TriggerArchived",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "trigger",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "resourceId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "light",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "name",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ArchiveTriggerMutation,
    ArchiveTriggerMutationVariables
>;
export const KonsolSinarControlDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolSinarControl" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "lights" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "warmup" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cooldown" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "currentCommand",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "__typename",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "start",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "end",
                                                },
                                            },
                                            {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                    kind: "NamedType",
                                                    name: {
                                                        kind: "Name",
                                                        value: "Event",
                                                    },
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "groupId",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                    kind: "NamedType",
                                                    name: {
                                                        kind: "Name",
                                                        value: "Override",
                                                    },
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "lightState",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolSinarControlQuery,
    KonsolSinarControlQueryVariables
>;
export const SetLightDurationDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "setLightDuration" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "lightId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cooldown" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Int" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "warmup" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Int" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "setLightDuration" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "lightId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "lightId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "cooldown",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "cooldown",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "warmup",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "warmup",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "LightDurationSet",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "light",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetLightDurationMutation,
    SetLightDurationMutationVariables
>;
export const UpdateOverrideDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "updateOverride" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "overrideId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "start" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "end" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "updateOverride" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "overrideId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "overrideId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "start",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "start",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "end",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "end",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateOverrideMutation,
    UpdateOverrideMutationVariables
>;
export const OverrideBookingLightOffDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "overrideBookingLightOff" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "lightId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "start" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "end" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createOverride" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "lightId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "lightId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "state",
                                            },
                                            value: {
                                                kind: "EnumValue",
                                                value: "OFF",
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "start",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "start",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "end",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "end",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "metadata",
                                            },
                                            value: {
                                                kind: "StringValue",
                                                value: "{}",
                                                block: false,
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "InternalError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    OverrideBookingLightOffMutation,
    OverrideBookingLightOffMutationVariables
>;
export const KonsolOrganisationDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolOrganisation" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myOrganisations" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "isCourtsitePartner",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolOrganisationQuery,
    KonsolOrganisationQueryVariables
>;
export const KonsolMyProfileDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "konsolMyProfile" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myProfile" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firebaseId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "phoneNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "gender" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "dateOfBirth",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "postCode" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "preferredSports",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "otherPreferredSports",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "sportsFrequency",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolMyProfileQuery,
    KonsolMyProfileQueryVariables
>;
export const KonsolMyProfileSetUserProfileDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "konsolMyProfileSetUserProfile" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "SetUserProfileRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "setUserProfile" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "InvalidValue",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    KonsolMyProfileSetUserProfileMutation,
    KonsolMyProfileSetUserProfileMutationVariables
>;
